<template>
    <v-card class="rounded-md elevation-3 mt-0 pa-4" color="#ECEFF1">
        <v-card class="rounded-xl elevation-2 mt-1 pa-4 ">
            <v-row>
                <v-col cols="12" sm="6" md="3">
                    <v-select dense outlined rounded clearable v-model="tipoReporte" :items="listaReportes"
                        label="Reporte" item-text="nombre" item-value="id" @change="consultarDatos"></v-select>
                </v-col>
                <!-- <v-col v-if="tipoReporte != 16 && tipoReporte !=18" cols="12" sm="6" md="2">
                <v-select dense clearable v-model="estiloReporte" :items="listaEstilo" label="Estilo" item-text="nombre"
                    item-value="id"></v-select>
            </v-col> -->
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 17 && tipoReporte != 2 && tipoReporte && tipoReporte != 19 && tipoReporte != 21">
                    <v-select dense outlined rounded clearable v-model="gPReporte" :items="listaGP" label="Grupo pago"
                        item-text="nombre" item-value="id" @change="consultarDatos"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 7 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 10 && tipoReporte != 21">
                    <v-select dense outlined rounded clearable v-model="yearReporte" :items="yearsList" label="Año"
                        item-text="year" item-value="id" @change="clearSelectedMonths"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 9 && tipoReporte != 7 && tipoReporte != 19 && tipoReporte != 16 && tipoReporte != 11 && tipoReporte != 17 && tipoReporte != 10 && tipoReporte != 21">
                    <v-select dense outlined rounded clearable v-model="selectedMonths" :items="months" label="Meses"
                        multiple :menu-props="{ closeOnContentClick: false }" item-text="monthName" item-value="month"
                        @change="updateSelectedMonths"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte && tipoReporte != 10 && tipoReporte != 17 && tipoReporte != 19 && tipoReporte != 11 && tipoReporte != 9 && tipoReporte != 21">
                    <v-select dense outlined rounded clearable v-model="periodoReporte" :items="periodos"
                        label="Periodo" item-text="period" item-value="id" :disabled="periodosResponse"
                        :loading="periodosResponseloading"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2" v-show="tipoReporte === 11 || tipoReporte === 9 || tipoReporte == 10">
                    <v-select dense outlined rounded clearable v-model="periodoReporte" :items="periodos"
                        label="Periodo" item-text="period" item-value="id" multiple :disabled="periodosResponse"
                        :loading="periodosResponseloading"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="2"
                    v-show="tipoReporte != 1 && tipoReporte != 2 && tipoReporte != 11 && tipoReporte != 9 && tipoReporte != 19 && tipoReporte != 10 && tipoReporte && tipoReporte != 21 && tipoReporte != 16 && tipoReporte != 17 && tipoReporte != 7 && tipoReporte != 10">
                    <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field outlined rounded v-model="dateRange" label="Rango" prepend-icon="mdi-calendar"
                                readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker v-model="dateRange" @change="menu = false" range locale="mx-es" :max="maxDate"
                            min="2020-01" type="month" scrollable></v-date-picker>
                    </v-menu>
                </v-col>

                <v-col cols="12" sm="3" md="2">
                    <v-btn @click="Reportes()" :loading="loadingAsignacion" dark rounded outlined
                        :disabled="!tipoReporte" class="font-weight-bold" color="primary">
                        <v-icon left>mdi-magnify</v-icon>
                        {{ loadingAsignacion ? 'Cargando...' : 'Filtrar' }}
                    </v-btn>
                </v-col>
                <v-col cols="12" sm="3" md="2" v-if="habilitarexcel && itemsreportes.length > 0">
                    <vue-json-to-csv :json-data="itemsreportes" :labels="headers_report_to_export" :csv-title="title">
                        <v-btn color="green darken-2" dark class="font-weight-bold" rounded outlined
                            @click="exportToCsv()">
                            <v-icon left>mdi-microsoft-excel</v-icon>
                            Exportar
                        </v-btn>
                    </vue-json-to-csv>
                </v-col>
                <v-col cols="12">
                    <v-alert v-if="loadingAsignacion && tipoReporte !== 10" type="info">
                        El tiempo promedio estimado para la carga y procesamiento de la información es de
                        aproximadamente 4
                        minutos.
                    </v-alert>
                    <v-alert v-if="loadingAsignacion && tipoReporte === 10" type="info">
                        El tiempo promedio estimado para la carga y procesamiento de la información es de
                        aproximadamente 14
                        minutos.
                        Solo considera periodos cerrados de los meses seleccionados.
                    </v-alert>
                </v-col>

            </v-row>
        </v-card>
        <v-card v-show="itemsreportes.length > 0" class="rounded-xl mt-3 elevation-0 pa-4 ">
            <v-data-table v-show="itemsreportes.length > 0" dense :headers="headersreportes" :items="itemsreportes"
                no-data-text="Ningún elemento coincide con el criterio de búsqueda" class="ma-3" :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
                :loading="loadingAsignacion">
                <template v-slot:item.total="{ item }">
                    {{
                        parseFloat(item.total) == 0
                            ? ""
                            : formatMXN(parseFloat(item.total))
                    }}
                </template>
            </v-data-table>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "muestra-reporte",
    components: { VueJsonToCsv },

    data() {
        return {

            headers16: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers18: [
                {
                    text: "Tipo",
                    value: "type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "key",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descripción",
                    value: "descr",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Cantidad",
                    value: "counts",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers7: [
                {
                    text: "Folio",
                    value: "folio",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Periodo",
                    value: "period",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "total",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers10: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Departamento",
                    value: "depto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Periodo",
                    value: "periodo",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers9: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers1: [],
            headersGeneral: [
                { text: "Folio", value: "EMPLID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nombre", value: "NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "RFC", value: "NATIONAL_ID_FORMAT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "CURP", value: "NATIONAL_ID_MSK", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo Pago", value: "DESCRSHORT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Registro Patronal", value: "RegistroPatronal", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sucursal", value: "BRANCHID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Área", value: "DEPTID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Área", value: "DEPTIDName", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Departamento", value: "EMP_KEYDEP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Departamento", value: "DESCR1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Posición", value: "EMP_KEYPUE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Posición", value: "DESCR", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Distrito", value: "district", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "División", value: "division", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo", value: "GROUP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Región", value: "Region", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Código Postal", value: "POSTAL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado", value: "STATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Municipio", value: "ADDRESS4", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Colonia", value: "ADDRESS3", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Domicilio", value: "ADDRESS1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Correo", value: "EMAIL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Teléfono", value: "PHONE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Género", value: "SEX", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estudios", value: "ESTUDIOS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado Civil", value: "MAR_STATUS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Nacimiento", value: "BORN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "NoResponsable", value: "NoResponsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Responsable", value: "Responsable", class: "text-uppercase font-weight-bold blue primary white--text" },
            ],
            headersMoney: [
                { text: "Folio", value: "EMPLID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nombre", value: "NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "RFC", value: "NATIONAL_ID_FORMAT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "CURP", value: "NATIONAL_ID_MSK", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo Pago", value: "DESCRSHORT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Registro Patronal", value: "RegistroPatronal", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sucursal", value: "BRANCHID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Área", value: "DEPTID", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Área", value: "DEPTIDName", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Departamento", value: "EMP_KEYDEP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Departamento", value: "DESCR1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Id Posición", value: "EMP_KEYPUE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Posición", value: "DESCR", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Distrito", value: "district", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "División", value: "division", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Grupo", value: "GROUP", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Región", value: "Region", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Código Postal", value: "POSTAL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado", value: "STATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Municipio", value: "ADDRESS4", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Colonia", value: "ADDRESS3", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Domicilio", value: "ADDRESS1", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Correo", value: "EMAIL", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Teléfono", value: "PHONE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Género", value: "SEX", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estudios", value: "ESTUDIOS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estado Civil", value: "MAR_STATUS", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Nacimiento", value: "BORN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Contratación", value: "EMP_FECING", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Último día calculado", value: "LAST_PAYMENT_DATE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Contrato", value: "contract_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Jornada", value: "workday_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Régimen", value: "tax_regime_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Régimen Fiscal", value: "contractual_regime_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Trabajador", value: "employee_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Salario", value: "salary_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Semana", value: "week_type_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tabla Prestaciones", value: "benefits_table_name", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "IMSS", value: "SSN", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Mov Nomina", value: "ACTION_DT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Sal/Día", value: "DAILY_RT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "SD Integrado", value: "GPMX_SDI_TOT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Fecha Movimiento", value: "fecha_mov", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tipo Movimiento AF", value: "type_mov", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Fijo", value: "fixed_contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Variable", value: "variable_contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Salario Base Cotización", value: "contribution_base_salary", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Banco", value: "BANK_NAME", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Tarjeta", value: "CARD", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Nº Cuenta", value: "ACCOUNT", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Clabe", value: "CLABE", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "NoResponsable", value: "NoResponsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Responsable", value: "Responsable", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Estructura", value: "Estructura", class: "text-uppercase font-weight-bold blue primary white--text" },
            ],

            headers20: [
                {
                    text: "No Empleado",
                    value: "employee_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Neto Pagar",
                    value: "net",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },

            ],
            headers2: [
                {
                    text: "# Zentric",
                    value: "id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "# Dh",
                    value: "employee_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Colaborador",
                    value: "emp_full_name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nómina",
                    value: "payroll",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Tipo de crédito",
                    value: "credit_type",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nº Prest",
                    value: "credit_id",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Inicio",
                    value: "start_date",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },

                {
                    text: "Monto",
                    value: "amount",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Descuento",
                    value: "discount",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Saldo",
                    value: "balance",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }

            ],
            headers11: [
                {
                    text: "Folio",
                    value: "clave",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "name",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "gPago",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "No. IMSS",
                    value: "numIMSS",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Registro Patronal",
                    value: "employerRegistry",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Año",
                    value: "year",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Concepto",
                    value: "concepto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Monto",
                    value: "monto",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers19: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Grupo Pago",
                    value: "DESCRSHORT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Ingreso",
                    value: "HIRE_DT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Salario Diario",
                    value: "DAILY_RT",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Aguinaldo",
                    value: "christmas_days",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Prima Vacacional",
                    value: "vacation_allowances",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Vacaciones",
                    value: "vacation_days",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "FI",
                    value: "integrating_factor",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }
            ],
            headers21: [
                {
                    text: "Folio",
                    value: "EMPLID",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nombre",
                    value: "NAME",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Datos Nomimas",
                    value: "payroll_name_datos",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Nomina IMSS",
                    value: "payroll_name_imss",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                }, {
                    text: "Mov Nomina",
                    value: "payroll_name_salario",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Mov Afiliatrios",
                    value: "payroll_name_mov",
                    class: "text-uppercase font-weight-bold blue primary white--text",
                },
                {
                    text: "Estructura",
                    value: "Estructura",
                    class: "text-uppercase font-weight-bold blue primary white--text"
                },
                {
                    text: "Ingreso",
                    value: "EMP_FECING",
                    class: "text-uppercase font-weight-bold blue primary white--text"
                },

            ],

            //
            headers_report_to_export11: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                numIMSS: { title: "No. IMSS" },
                employerRegistry: { title: "Registro Patronal" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export2: {
                id: { title: "# Zentric" },
                employee_id: { title: "# Dh" },
                emp_full_name: { title: "Colaborador" },
                payroll: { title: "Grupo Pago" },
                credit_type: { title: "Tipo de crédito" },
                credit_id: { title: "Nº Prest" },
                start_date: { title: "Fecha inicio" },
                amount: { title: "Monto" },
                discount: { title: "Descuento" },
                balance: { title: "Saldo" },
            },
            headers_report_to_export1: {},
            headers_report_to_exportMoney: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                DESCRSHORT: { title: "Grupo Pago" },
                RegistroPatronal: { title: "Registro Patronal" },
                BRANCHID: { title: "Sucursal" },
                DEPTID: { title: "Id Area" },
                DEPTIDName: { title: "Area" },
                EMP_KEYDEP: { title: "id Departamento" },
                DESCR1: { title: "Departamento" },
                EMP_KEYPUE: { title: "id Posicion" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                GROUP: { title: "Grupo" },
                Region: { title: "Region" },
                POSTAL: { title: "Codigo Postal" },
                STATE: { title: "Estado" },
                ADDRESS4: { title: "Municipio" },
                ADDRESS3: { title: "Colonia" },
                ADDRESS1: { title: "Domicilio" },
                EMAIL: { title: "Correo" },
                PHONE: { title: "Telefono" },
                SEX: { title: "Genero" },
                ESTUDIOS: { title: "Estudios" },
                MAR_STATUS: { title: "Estado Civil" },
                BORN: { title: "Fecha Nacimiento" },
                EMP_FECING: { title: "Fecha Contratación" },
                LAST_PAYMENT_DATE: { title: "Ultimo dia calculado" },
                contract_type_name: { title: "Tipo contrato" },
                workday_type_name: { title: "Tipo jornada" },
                tax_regime_name: { title: "Tipo regimen" },
                contractual_regime_name: { title: "Regimen fiscal" },

                employee_type_name: { title: "Tipo trabajador" },
                salary_type_name: { title: "Tipo salario" },
                week_type_name: { title: "Tipo semana" },
                benefits_table_name: { title: "Tabla prestaciones" },
                SSN: { title: "IMSS" },
                HIRE_DT: { title: "Fecha Contratación" },

                ACTION_DT: { title: "Fecha mov Nomina" },
                DAILY_RT: { title: "Sal/Día" },
                GPMX_SDI_TOT: { title: "SD Integrado" },

                fecha_mov: { title: "Fecha movimiento" },
                type_mov: { title: "Tipo movimiento af" },
                fixed_contribution_base_salary: { title: "Salario base de cotización fijo" },
                variable_contribution_base_salary: { title: "Salario base de cotización variable" },
                contribution_base_salary: { title: "salario base de cotización" },
                BANK_NAME: { title: "Banco" },
                CARD: { title: "Tarjeta" },
                ACCOUNT: { title: "Nº Cuenta" },
                CLABE: { title: "Clabe" },

                NoResponsable: { title: "NoResponsable" },
                Responsable: { title: "Responsable" },
                Estructura: { title: "Estructura" },
            },
            headers_report_to_exportGeneral: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                NATIONAL_ID_FORMAT: { title: "RFC" },
                NATIONAL_ID_MSK: { title: "CURP" },
                DESCRSHORT: { title: "Grupo Pago" },
                RegistroPatronal: { title: "Registro Patronal" },
                BRANCHID: { title: "Sucursal" },
                DEPTID: { title: "Id Area" },
                DEPTIDName: { title: "Area" },
                EMP_KEYDEP: { title: "id Departamento" },
                DESCR1: { title: "Departamento" },
                EMP_KEYPUE: { title: "id Posicion" },
                DESCR: { title: "Posicion" },
                district: { title: "Distrito" },
                division: { title: "Division" },
                GROUP: { title: "Grupo" },
                Region: { title: "Region" },
                POSTAL: { title: "Codigo Postal" },
                STATE: { title: "Estado" },
                ADDRESS4: { title: "Municipio" },
                ADDRESS3: { title: "Colonia" },
                ADDRESS1: { title: "Domicilio" },
                EMAIL: { title: "Correo" },
                PHONE: { title: "Telefono" },
                SEX: { title: "Genero" },
                ESTUDIOS: { title: "Estudios" },
                MAR_STATUS: { title: "Estado Civil" },
                BORN: { title: "Fecha Nacimiento" },
                NoResponsable: { title: "NoResponsable" },
                Responsable: { title: "Responsable" },
            },
            headers_report_to_export10: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                depto: { title: "Departamento" },
                year: { title: "Año" },
                periodo: { title: "Periodo" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export9: {
                clave: { title: "Folio" },
                name: { title: "Nombre" },
                year: { title: "Año" },
                concepto: { title: "Concepto" },
                monto: { title: "Monto" },
            },
            headers_report_to_export16: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export18: {
                type: { title: "Tipo" },
                key: { title: "Concepto" },
                descr: { title: "Descripción" },
                counts: { title: "Cantidad" },
                total: { title: "Monto" },
            },
            headers_report_to_export7: {
                folio: { title: "Folio" },
                name: { title: "Nombre" },
                gPago: { title: "Grupo Pago" },
                period: { title: "Periodo" },
                total: { title: "Monto" },
            },
            headers_report_to_export20: {
                employee_id: { title: "# Colaborador" },
                net: { title: "Neto Pagar" },
            },
            headers_report_to_export19: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                DESCRSHORT: { title: "Grupo Pago" },
                HIRE_DT: { title: "Ingreso" },
                DAILY_RT: { title: "Salario Diario" },
                christmas_days: { title: "Aguinaldo" },
                vacation_allowances: { title: "Prima Vacacional" },
                vacation_days: { title: "Vacaciones" },
                integrating_factor: { title: "FI" },
            },
            headers_report_to_export21: {
                EMPLID: { title: "Folio" },
                NAME: { title: "Nombre" },
                payroll_name_datos: { title: "Datos Nomimas" },
                payroll_name_imss: { title: "Nomina IMSS" },
                payroll_name_salario: { title: "Mov Nomina" },
                payroll_name_mov: { title: "Mov Afiliatrios" },
                Estructura: { title: "Estructura" },
                EMP_FECING: { title: "Ingreso Nomina" },
                join_date_imss: { title: "Ingreso IMSS" },

            },
            headers_report_to_export: {},
            title: 'Reporte de Zentric',
            //
            headersreportes: [],
            itemsreportes: [],
            //
            listaReportes: [],
            listaReportesGeneral: [
                { id: 1, nombre: 'Resumen Empleados' },
                // { id: 3, nombre: 'Bajas empleados' },
                // { id: 4, nombre: 'Cifras quincenales' },
                // { id: 5, nombre: 'Cifras semanales' },
                //{ id: 6, nombre: 'Cifras totales' },
                // { id: 8, nombre: 'Registro de timbrados' },
                { id: 9, nombre: 'Acumulado ISR' },
                { id: 10, nombre: 'Pago bonos' },
                { id: 11, nombre: 'Auditores IMSS Infonavit' },
                // { id: 12, nombre: 'Caratura resumen polizas' },
                //  { id: 13, nombre: 'Intereses caja' },
                // { id: 14, nombre: 'Movimientos caja' },
                // { id: 15, nombre: 'Revision nominas' },
                //  { id: 17, nombre: 'Resumen Empleados' },
                //{ id: 18, nombre: 'Resumen conceptos' },
            ],
            listaReportesComReclu: [
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
            ],
            listaReportesNom: [
                { id: 16, nombre: 'Resumen conceptos' },
                { id: 1, nombre: 'Resumen Empleados' },
                { id: 2, nombre: 'Saldos prestamos' },
                { id: 7, nombre: 'Vales de despensa' },
                { id: 9, nombre: 'Acumulado ISR' },
                { id: 10, nombre: 'Pago bonos' },
                { id: 11, nombre: 'Auditores IMSS Infonavit' },
                { id: 19, nombre: 'Beneficios Colaboradores' },
                //{ id: 20, nombre: 'Pago Periodo 40' },
                { id: 21, nombre: 'Grupos de Pago' },
            ],
            tipoReporte: null,
            //
            listaEstilo: [{ id: 1, nombre: 'Acomulado', },
            { id: 2, nombre: 'Individual' }],
            estiloReporte: null,
            //
            listaGP: [{ id: 5704, nombre: 'Semanal' },
            { id: 5709, nombre: 'Quincenal' },
            { id: 8240, nombre: 'Comisionistas' },
            { id: 11602, nombre: 'Lentes Semanal' },
            { id: 11603, nombre: 'Lentes Quincenal' },
            { id: 11604, nombre: 'Finiquitos Semanal' },
            { id: 11605, nombre: 'Finiquitos Quincenal' },
            { id: 11606, nombre: 'Finiquitos Comisionistas' }],
            gPReporte: null,
            //
            periodosResponse: true,
            periodosResponseloading: false,
            periodos: [],
            periodoReporte: [],
            //
            yearReporte: new Date().getFullYear(), // Inicializa con el año actual
            //
            habilitarexcel: false,
            loadingAsignacion: false,
            //fecha
            menu: false,
            dateRange: [],
            maxDate: '',
            minDate: '',
            selectedMonths: [],
            months: [
                { month: 1, monthName: 'Enero' },
                { month: 2, monthName: 'Febrero' },
                { month: 3, monthName: 'Marzo' },
                { month: 4, monthName: 'Abril' },
                { month: 5, monthName: 'Mayo' },
                { month: 6, monthName: 'Junio' },
                { month: 7, monthName: 'Julio' },
                { month: 8, monthName: 'Agosto' },
                { month: 9, monthName: 'Septiembre' },
                { month: 10, monthName: 'Octubre' },
                { month: 11, monthName: 'Noviembre' },
                { month: 12, monthName: 'Diciembre' },
            ],
            selectedMonthsFormatted: [],
            // extras
            peticionesConceptos: 0,
            tokenZentric: null,
            resultArrays: [],

        };
    },
    computed: {
        yearsList() {
            const currentYear = new Date().getFullYear();
            const startYear = 2023;
            const years = [];
            for (let year = currentYear; year >= startYear; year--) {
                years.push({ id: year, year: year });
            }
            return years;
        },
    },
    async mounted() {
        const today = new Date();
        this.maxDate = today.toISOString().substr(0, 7) // Formato YYYY-MM
        this.getPuesto();
    },
    methods: {
        splitArray(array, chunkSize) {
            const chunks = [];
            for (let i = 0; i < array.length; i += chunkSize) {
                chunks.push(array.slice(i, i + chunkSize));
            }
            return chunks;
        },
        async getReportes7() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });


                if (response.data.status === "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let fetchPromises = [];

                    if (this.gPReporte.length !== 5) {
                        // Si gPReporte tiene 5 elementos, realizar una única solicitud
                        const params = {
                            timeout: 0,
                            payrolls: [this.gPReporte],
                            years: [this.yearReporte],
                            periods: [this.periodoReporte],
                            is_calculation: this.typePeriodo
                        };

                        const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error en la solicitud:", error);
                            });

                        fetchPromises.push(promise);
                    } else {
                        // Si no, realizar solicitudes individuales para cada elemento
                        for (let report of this.gPReporte) {
                            const params = {
                                timeout: 0,
                                payrolls: [report],
                                years: [this.yearReporte],
                                periods: [this.periodoReporte],
                                is_calculation: this.typePeriodo
                            };

                            const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                                headers: {
                                    Authorization: this.tokenZentric
                                },
                                timeout: 0
                            })
                                .then((response) => {
                                    if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error en la solicitud:", error);
                                });

                            fetchPromises.push(promise);
                        }
                    }

                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);

                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 7,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;
                        this.headersreportes = this.headers7;
                        this.headers_report_to_export = this.headers_report_to_export7;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes11() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let fetchPromises = [];

                    if (this.gPReporte.length !== 5) {
                        // Si gPReporte tiene 5 elementos, realizar una única solicitud
                        const params = {
                            timeout: 0,
                            payrolls: [this.gPReporte],
                            years: [this.yearReporte],
                            periods: this.periodoReporte,
                            is_calculation: this.typePeriodo
                        };

                        const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error en la solicitud:", error);
                            });

                        fetchPromises.push(promise);
                    } else {
                        // Si no, realizar solicitudes individuales para cada elemento
                        for (let report of this.gPReporte) {
                            const params = {
                                timeout: 0,
                                payrolls: [report],
                                years: [this.yearReporte],
                                periods: this.periodoReporte,
                                is_calculation: this.typePeriodo
                            };

                            const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                                headers: {
                                    Authorization: this.tokenZentric
                                },
                                timeout: 0
                            })
                                .then((response) => {
                                    if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error en la solicitud:", error);
                                });

                            fetchPromises.push(promise);
                        }
                    }


                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Ajusta el tamaño del fragmento según sea necesario
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear un objeto params con múltiples propiedades para los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 11,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;

                        this.headersreportes = this.headers11;
                        this.headers_report_to_export = this.headers_report_to_export11;

                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes10() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let fetchPromises = [];

                    if (this.gPReporte.length !== 5) {
                        // Si gPReporte tiene 5 elementos, realizar una única solicitud
                        const params = {
                            timeout: 0,
                            payrolls: [this.gPReporte],
                            years: [this.yearReporte],
                            periods: this.periodoReporte,
                            is_calculation: this.typePeriodo
                        };

                        const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error en la solicitud:", error);
                            });

                        fetchPromises.push(promise);
                    } else {
                        // Si no, realizar solicitudes individuales para cada elemento
                        for (let report of this.gPReporte) {
                            const params = {
                                timeout: 0,
                                payrolls: [report],
                                years: [this.yearReporte],
                                periods: this.periodoReporte,
                                is_calculation: this.typePeriodo
                            };

                            const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                                headers: {
                                    Authorization: this.tokenZentric
                                },
                                timeout: 0
                            })
                                .then((response) => {
                                    if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error en la solicitud:", error);
                                });

                            fetchPromises.push(promise);
                        }
                    }

                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 10,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;

                        this.headersreportes = this.headers10;
                        this.headers_report_to_export = this.headers_report_to_export10;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },//
        async getReportes9() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let fetchPromises = [];

                    if (this.gPReporte.length !== 5) {
                        // Si gPReporte tiene 5 elementos, realizar una única solicitud
                        const params = {
                            timeout: 0,
                            payrolls: [this.gPReporte],
                            years: [this.yearReporte],
                            periods: this.periodoReporte,
                            is_calculation: this.typePeriodo
                        };

                        const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error en la solicitud:", error);
                            });

                        fetchPromises.push(promise);
                    } else {
                        // Si no, realizar solicitudes individuales para cada elemento
                        for (let report of this.gPReporte) {
                            const params = {
                                timeout: 0,
                                payrolls: [report],
                                years: [this.yearReporte],
                                periods: this.periodoReporte,
                                is_calculation: this.typePeriodo
                            };

                            const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                                headers: {
                                    Authorization: this.tokenZentric
                                },
                                timeout: 0
                            })
                                .then((response) => {
                                    if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error en la solicitud:", error);
                                });

                            fetchPromises.push(promise);
                        }
                    }

                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 9,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;


                        this.headersreportes = this.headers9;
                        this.headers_report_to_export = this.headers_report_to_export9;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes16() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
                periodo: [this.periodoReporte],
                years: [this.yearReporte],
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    if (this.gPReporte === 5709) {
                        this.gPReporte = [5709, 5710, 5711, 5712, 5713];
                    } else if (this.gPReporte === 5704) {
                        this.gPReporte = [5704, 5705, 5706, 5707, 5708];
                    } else if (this.gPReporte === 8240) {
                        this.gPReporte = [8240, 8241, 8242, 8243, 8244];
                    }

                    let fetchPromises = [];

                    if (this.gPReporte.length !== 5) {
                        // Si gPReporte tiene 5 elementos, realizar una única solicitud
                        const params = {
                            timeout: 0,
                            payrolls: [this.gPReporte],
                            years: [this.yearReporte],
                            periods: [this.periodoReporte],
                            is_calculation: this.typePeriodo
                        };

                        const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                            headers: {
                                Authorization: this.tokenZentric
                            },
                            timeout: 0
                        })
                            .then((response) => {
                                if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                    this.resultArrays.push(response.data.data[0]);
                                }
                            })
                            .catch((error) => {
                                console.error("Error en la solicitud:", error);
                            });

                        fetchPromises.push(promise);
                    } else {
                        // Si no, realizar solicitudes individuales para cada elemento
                        for (let report of this.gPReporte) {
                            const params = {
                                timeout: 0,
                                payrolls: [report],
                                years: [this.yearReporte],
                                periods: [this.periodoReporte],
                                is_calculation: this.typePeriodo
                            };

                            const promise = axios.post(`https://api.zentric.mx/reports/scenario-report/data/`, params, {
                                headers: {
                                    Authorization: this.tokenZentric
                                },
                                timeout: 0
                            })
                                .then((response) => {
                                    if (response.data.status === "success" && response.data.data[0].data?.length > 0) {
                                        this.resultArrays.push(response.data.data[0]);
                                    }
                                })
                                .catch((error) => {
                                    console.error("Error en la solicitud:", error);
                                });

                            fetchPromises.push(promise);
                        }
                    }

                    // Esperar a que todas las promesas iniciales se resuelvan
                    await Promise.all(fetchPromises);
                    // Validar si hay datos antes de continuar
                    if (this.resultArrays.length > 0) {
                        const chunkSize = 20000; // Tamaño del fragmento
                        const chunks = this.splitArray(this.resultArrays[0].data, chunkSize);

                        // Crear las promesas para enviar los fragmentos
                        const chunkPromises = chunks.map((chunk, index) => {
                            const params = {
                                Opcion: 16,
                                chunk: chunk,
                                title: this.resultArrays[0].headers,
                                index: index
                            };

                            return axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                                headers: {
                                    Authorization: localStorage.getItem("token")
                                },
                                timeout: 0
                            });
                        });

                        // Esperar a que se completen las promesas de fragmentos
                        const responses = await Promise.all(chunkPromises);

                        // Combinar todos los datos
                        const allData = responses.flatMap(response => response.data.dataReporte);

                        // Actualizar los datos para la tabla
                        this.itemsreportes = allData;
                        this.headersreportes = this.headers16;
                        this.headers_report_to_export = this.headers_report_to_export16;
                    } else {
                        alert('No hay datos desde Zentric.');
                    }
                }
            } catch (error) {
                console.error("Error general:", error);
            } finally {
                this.habilitarexcel = true;
                this.loadingAsignacion = false;
            }

        },
        async getReportes2() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;


            params = {
                Opcion: 0,
                grupoPago: this.gPReporte,
            };

            try {
                const response = await axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    },
                    timeout: 0
                });

                if (response.data.status == "OK") {
                    this.tokenZentric = response.data.token;
                    this.typePeriodo = response.data.typePeriodo;

                    this.gPReporte = [5704, 5705, 5706, 5707, 5708, 5709, 5710, 5711, 5712, 5713, 8240, 8241, 8242, 8243, 8244, 11602, 11603, 11604, 11605, 11606];

                    let promises = [];

                    let params = {
                        timeout: 0,
                        payrolls: this.gPReporte,
                    };

                    let promise = axios.post(`https://api.zentric.mx/reports/credits/filter/`, params, {
                        headers: {
                            Authorization: this.tokenZentric
                        },
                        timeout: 0
                    })
                        .then((response) => {
                            if (response.data.status === "success") {
                                if (response.data.data.data && response.data.data.data.length > 0) {
                                    this.itemsreportes = response.data.data.data;
                                    this.headersreportes = this.headers2;
                                    this.headers_report_to_export = this.headers_report_to_export2;
                                }

                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.habilitarexcel = true;
                            this.loadingAsignacion = false;
                        });

                    promises.push(promise);

                    await Promise.all(promises);
                    // console.log(this.resultArrays);

                }
            } catch (error) {
                console.error(error);
            }


        },
        async getReportes() {
            this.loadingAsignacion = true;
            this.habilitarexcel = false;
            let params;

            if (this.tipoReporte === 10) {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            } else if (this.tipoReporte === 1) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 17) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 20) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 19) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else if (this.tipoReporte === 21) {
                params = {
                    Opcion: this.tipoReporte,
                };
            } else {
                params = {
                    Opcion: this.tipoReporte,
                    fechainicial: this.dateRange[0],
                    fechafinal: this.dateRange[1],
                    grupoPago: this.gPReporte,
                    periodo: [this.periodoReporte],
                    months: this.selectedMonthsFormatted,
                    years: [this.yearReporte],
                };
            }
            axios.post(`${this.$store.getters.getHostNode}/api/getReporte`, params, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        this.itemsreportes = response.data.dataReporte;
                        const numero = response.data.reporte;
                        if (numero === 2) {
                            this.headersreportes = this.headers2;
                            this.headers_report_to_export = this.headers_report_to_export2;
                        } else if (numero === 16) {
                            this.headersreportes = this.headers16;
                            this.headers_report_to_export = this.headers_report_to_export16;
                        } else if (numero === 7) {
                            this.headersreportes = this.headers7;
                            this.headers_report_to_export = this.headers_report_to_export7;
                        } else if (numero === 10) {
                            this.headersreportes = this.headers10;
                            this.headers_report_to_export = this.headers_report_to_export10;
                        } else if (numero === 1) {
                            this.headersreportes = this.headers1;
                            this.headers_report_to_export = this.headers_report_to_export1;
                        } else if (numero === 11) {
                            this.headersreportes = this.headers11;
                            this.headers_report_to_export = this.headers_report_to_export11;
                        } else if (numero === 18) {
                            this.headersreportes = this.headers18;
                            this.headers_report_to_export = this.headers_report_to_export18;
                        } else if (numero === 17) {
                            this.headersreportes = this.headers17;
                            this.headers_report_to_export = this.headers_report_to_export17;
                        } else if (numero === 19) {
                            this.headersreportes = this.headers19;
                            this.headers_report_to_export = this.headers_report_to_export19;
                        } else if (numero === 20) {
                            this.headersreportes = this.headers20;
                            this.headers_report_to_export = this.headers_report_to_export20;
                        } else if (numero === 21) {
                            this.headersreportes = this.headers21;
                            this.headers_report_to_export = this.headers_report_to_export21;
                        } else {
                            // Manejar el caso cuando el número no coincide con ninguna opción
                            console.error("Número de reporte no válido:", numero);
                        }
                        if (response.data.extra.length > 0) {
                            this.extras = response.data.extra;
                            //console.log(this.extras);
                        }
                    } else {
                        this.itemsreportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },
        Reportes() {
            this.itemsreportes = [];

            if (this.tipoReporte === 16) {
                this.getReportes16();
            } else if (this.tipoReporte === 2) {
                this.getReportes2();
            } else if (this.tipoReporte === 7) {
                this.getReportes7();
            } else if (this.tipoReporte === 10) {
                this.getReportes10();
            } else if (this.tipoReporte === 11) {
                this.getReportes11();
            } else if (this.tipoReporte === 9) {
                this.getReportes9();
            } else {
                this.getReportes();
            }
        },

        consultarDatos() {
            if (this.gPReporte !== null) {
                // Realizar la consulta con this.gPReporte como parámetro
                this.periodosResponse = true;
                this.periodosResponseloading = true;
                this.periodoReporte = null;

                let params = {
                    grupoPago: this.gPReporte,
                    Opcion: this.tipoReporte,

                };
                axios.post(`${this.$store.getters.getHostNode}/api/getPeriodosGp`, params)
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.periodos = response.data.Periodos;

                        } else {
                            this.periodos = ['Periodos no disponibles'];
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.periodosResponse = false;
                        this.periodosResponseloading = false;
                    });
            }
        },
        exportToCsv() {
            // Exportar datos a CSV
            if (this.itemsreportes.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },
        shouldFormat(item) {
            return item.type !== "Total conceptos";
        },
        clearSelectedMonths() {
            this.selectedMonths = [];
            this.selectedMonthsFormatted = [];
        },
        updateSelectedMonths() {
            this.selectedMonthsFormatted = this.selectedMonths.map(month => {
                return { year: this.yearReporte, month: month };
            });
        },
        async getPuesto() {

            axios.get(`${this.$store.getters.getHostNode}/api/get-puesto`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status == "OK") {
                        let JOBCODE = parseInt(response.data.data.JOBCODE, 10);
                        const jobcodesNominas = [9394, 9698, 9700, 9702, 9703, 12713, 9721, 12717]; //
                        const jobcodeComReclu = [9268, 9273, 9686, 9687, 9688];
                        if (jobcodesNominas.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesNom;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else if (jobcodeComReclu.includes(JOBCODE)) {
                            this.listaReportes = this.listaReportesComReclu;
                            this.headers_report_to_export1 = this.headers_report_to_exportMoney;
                            this.headers1 = this.headersMoney;
                        } else {
                            this.listaReportes = this.listaReportesGeneral;
                            this.headers_report_to_export1 = this.headers_report_to_exportGeneral;
                            this.headers1 = this.headersGeneral;
                        }
                    } else {
                        this.listaReportes = [];
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.habilitarexcel = true;
                    this.loadingAsignacion = false;
                });
        },

    },
};
</script>
