import axios from 'axios'
import PersonaPerfil from '../../../../../components/PersonaPerfil.vue'
export default {
    name: 'empleados-activos',
    components: {
        PersonaPerfil
    },
    props: [],
    data() {
        return {
            colaboradores: [],
            loadingColaboradores: true,
            colaboradoresNombre: [],
            nombre: '',
            numeroEmpleado: '',
            perfil: '',
            esAdminTI: '',
            esAdminRL: '',
            muestra: false,
            colaborador: [],

        }
    },
    computed: {

    },
    mounted() {
        this.getColaboradores();
        this.getPerfil();
    },
    methods: {

        getColaboradores() {
            let params = {
                Opcion: 13,
                token: localStorage.getItem("token"),
            };
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    var data = response.data.colaborador;
                    this.colaboradores = data;
                    this.colaboradores.map((elemento) => {
                        this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
                    });
                    this.loadingColaboradores = false;
                });
        },

        buscarNumeroEmpleado() {
            if (this.nombre) {
                let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nombre);
                this.numeroEmpleado = resultdo[0]['EMPLID'];
                this.muestra = true;
                this.getDataEmpleado(this.numeroEmpleado);
            } else {
                this.muestra = false;
                this.numeroEmpleado = "";
            }

        },

        getDataEmpleado(EMPLID) {
            this.colaborador.length = 0;
            let paramsData = {
                Opcion: 12,
                token: localStorage.getItem('token'),
                folio: EMPLID
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsData).then((responseData) => {
                this.colaborador = responseData.data.colaborador;
                if (this.colaborador['conocimiento']) {
                    this.colaborador['conocimiento'] = this.colaborador['conocimiento'].split('*');
                }
                if (this.colaborador['objetivos']) {
                    this.colaborador['objetivos'] = this.colaborador['objetivos'].split('*');
                }
                if (this.colaborador['foto']) {
                    this.colaborador['link'] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/";
                    // this.colaborador['linkCompleto'] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['foto'];
                    this.colaborador['linkCompleto'] = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['foto'];
                } else {
                    this.colaborador['linkCompleto'] = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                }
                if (this.$refs.PersonaPerfil) {
                    this.$refs.PersonaPerfil.getPerfilColaborador(EMPLID);
                    this.$refs.PersonaPerfil.getColaboradores();
                }
            })

        },

        getPerfil() {
            let params = {
                Opcion: 33,
                token: localStorage.getItem("token")
            }
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                if (response.data.status == "OK") {
                    this.perfil = response.data.perfil["esVigilante"];
                    this.esAdminTI = response.data.perfil["esAdminTI"];
                    this.esAdminRL = response.data.perfil["esAdminRL"];
                }
            })
        },
    }
}