<template>
    <v-dialog v-model="dialogPreguntas" max-width="1000px">
        <v-card>
            
            <v-card-title>
                <span class="headline">Preguntas de {{ this.nombre }}</span>
            </v-card-title>
            <v-card-text>
                <v-alert type="success"></v-alert>
                <!-- Tabla de preguntas con encabezado personalizado -->
                <v-data-table :headers="headersPreguntas" :items="preguntas" dense no-data-text="No hay preguntas"
                    :search="search" :loading="loading"
                    :footer-props="{ 'items-per-page-text': 'Elementos por página', 'pageText': '{0}-{1} de {2}' }">
                    <template v-slot:top>
                        <div style="display: flex; justify-content: space-between; align-items: center;"
                            class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
                            <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                                append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
                                class="mx-4 rounded-lg"></v-text-field>
                            <v-btn color="success" small @click="abrirDialogAgregarPregunta" class="mr-4">
                                <v-icon left>mdi-plus</v-icon>Agregar Pregunta
                            </v-btn>
                        </div>
                    </template>

                    <template v-slot:item.opciones="{ item }">
                        <span v-if="item.opciones">{{ item.opciones.join(', ') }}</span>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-chip-group column>
                            <v-btn x-small rounded elevation="1" color="red" dark @click="eliminarPregunta(item)">
                                <v-icon>mdi-delete</v-icon>
                            </v-btn>
                            <v-btn x-small rounded elevation="1" color="teal darken-1" dark
                                @click="editarPregunta(item)">
                                <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                        </v-chip-group>
                    </template>
                </v-data-table>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="$emit('cierraDialogEdicion')">Cerrar</v-btn>
            </v-card-actions>
        </v-card>

        <!-- Dialogo para Agregar Pregunta -->
        <v-dialog v-model="dialogAgregarPregunta" max-width="600px">
            <v-card>
                <v-toolbar color="primary" dark>
                    Agregar Pregunta
                </v-toolbar>
                <v-card-text>
                    <v-select v-model="preguntaSeleccionada" :items="tiposDePregunta" label="Tipo de pregunta" outlined
                        dense class="mt-4"></v-select>

                    <v-text-field v-model="preguntaTexto" label="Escribe tu pregunta" outlined dense></v-text-field>

                    <v-row v-if="preguntaSeleccionada === 'Cerrada' || preguntaSeleccionada === 'Opción Múltiple'">
                        <v-col v-for="(opcion, index) in opciones" :key="index" cols="12" sm="6" md="4">
                            <v-text-field v-model="opciones[index]" :label="'Opción ' + (index + 1)" outlined
                                dense></v-text-field>
                        </v-col>
                    </v-row>
                    <v-btn small @click="agregarOpcion" class="mt-4" color="primary">Agregar Opción</v-btn>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="teal" dark elevation="2" @click="confirmarAgregarPregunta">
                        {{ editMode ? 'Actualizar' : 'Agregar' }} Pregunta
                    </v-btn>
                    <v-btn color="error" elevation="2" @click="dialogAgregarPregunta = false">
                        Cerrar
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-dialog>
</template>

<script>
import axios from 'axios';
export default {
    props: {
        dialogPreguntas: Boolean,
        idEncuesta: Number,
        nombre: String,
    },
    data() {
        return {
            search: '',
            loading: false,
            headersPreguntas: [
                { text: 'Número de Pregunta', value: 'numPregunta' },
                { text: 'Pregunta', value: 'pregunta' },
                { text: 'Tipo de Pregunta', value: 'tipoPregunta' },
                { text: 'Opciones', value: 'opciones' },
                { text: 'Acciones', value: 'actions', sortable: false },
            ],
            preguntas: [],
            dialogAgregarPregunta: false,
            tiposDePregunta: ["Abierta", "Cerrada", "Opción Múltiple"],
            preguntaSeleccionada: '',
            preguntaTexto: '',
            opciones: [],
            editMode: false,
            preguntaEditando: null
        };
    },
    watch: {
        dialogPreguntas(newVal) {
            if (newVal) this.cargarDetallesEncuesta();
        }
    },
    methods: {
        async cargarDetallesEncuesta() {
            this.preguntas = [];
            this.loading = true;
            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEncuestaDetallada/${this.idEncuesta}`, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    },
                });
                const preguntasRaw = response.data.data;
                const preguntas = [];

                preguntasRaw.forEach((pregunta) => {
                    let existingQuestion = preguntas.find(q => q.numPregunta === pregunta.NUM_PREGUNTA);
                    if (existingQuestion) {
                        if (pregunta.OPCION) {
                            existingQuestion.opciones.push(pregunta.OPCION);
                        }
                    } else {
                        preguntas.push({
                            id: pregunta.ID_PREGUNTA,
                            numPregunta: pregunta.NUM_PREGUNTA,
                            pregunta: pregunta.PREGUNTA,
                            tipoPregunta: pregunta.TIPO_PREGUNTA,
                            opciones: pregunta.OPCION ? [pregunta.OPCION] : []
                        });
                    }
                });

                this.preguntas = preguntas;
            } catch (error) {
                console.error('Error al obtener preguntas:', error);
            } finally {
                this.loading = false;
            }
        },
        abrirDialogAgregarPregunta() {
            this.dialogAgregarPregunta = true;
            this.preguntaSeleccionada = '';
            this.preguntaTexto = '';
            this.opciones = [];
            this.editMode = false;
            this.preguntaEditando = null;
        },
        agregarOpcion() {
            this.opciones.push('');
        },
        async confirmarAgregarPregunta() {
            // Obtener el número de la siguiente pregunta
            if (!this.validarCampos()) return;
            const maxNumPregunta = this.preguntas.length > 0
                ? Math.max(...this.preguntas.map(pregunta => pregunta.numPregunta))
                : 0;

            const nuevaPregunta = {
                idEncuesta: this.idEncuesta,
                idPregunta: this.editMode ? this.preguntaEditando.id : undefined,
                numPregunta: this.editMode ? this.preguntaEditando.numPregunta : maxNumPregunta + 1,
                pregunta: this.preguntaTexto,
                tipoPregunta: this.preguntaSeleccionada,
                opciones: this.preguntaSeleccionada !== 'Abierta' ? this.opciones : null
            };

            if (this.editMode) {
                // Editar pregunta existente
                const index = this.preguntas.findIndex((preg) => preg.id === this.preguntaEditando.id);
                if (index !== -1) {
                    this.preguntas.splice(index, 1, { ...nuevaPregunta, id: this.preguntaEditando.id });
                }
            } else {

                try {
                    const response = await axios.post(`${this.$store.getters.getHostNode}/api/agregarPregunta`, nuevaPregunta, {
                        headers: {
                            Authorization: localStorage.getItem("token")
                        }
                    });
                    nuevaPregunta.id = response.data.data.idPregunta;
                    this.preguntas.push(nuevaPregunta);
                } catch (error) {
                    console.log('Error al agregar la pregunta:', error);
                }
            }

            this.dialogAgregarPregunta = false;
            this.editMode = false;
            this.preguntaEditando = null;
            await this.cargarDetallesEncuesta();
        },
        validarCampos() {
            if (!this.preguntaSeleccionada) {
                this.mostrarMensajeSnackBar('Seleccione un tipo de pregunta', 'red');
                return false;
            }
            if (!this.preguntaTexto.trim()) {
                this.mostrarMensajeSnackBar('El texto de la pregunta no puede estar vacío', 'red');
                return false;
            }
            if ((this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opción Múltiple') && this.opciones.length < 2) {
                this.mostrarMensajeSnackBar('Debe agregar al menos dos opciones', 'red');
                return false;
            }
            for (const opcion of this.opciones) {
                if (!opcion.trim()) {
                    this.mostrarMensajeSnackBar('Las opciones no pueden estar vacías', 'red');
                    return false;
                }
            }
            return true;
        },

        editarPregunta(item) {
            this.dialogAgregarPregunta = true;
            this.editMode = true;
            this.preguntaEditando = { ...item };
            this.preguntaTexto = item.pregunta;
            this.preguntaSeleccionada = item.tipoPregunta;
            this.opciones = item.opciones ? [...item.opciones] : [];
        },
        async eliminarPregunta(item) {
            console.log(JSON.stringify(item, null, 2));
            const data = {
                idPregunta: item.id,
                idEncuesta: this.idEncuesta,
                numPregunta: item.numPregunta
            };
            console.log('data', data)

            try {

                const response = await axios.post(`${this.$store.getters.getHostNode}/api/eliminarPregunta`, data, {
                    headers: {
                        Authorization: localStorage.getItem("token")
                    }
                });
                if (response.data.status) {

                    this.preguntas = this.preguntas.filter(pregunta => pregunta.numPregunta !== item.numPregunta);
                    console.log(response.data.status);
                    await this.cargarDetallesEncuesta();
                } else {
                    console.log('Error al eliminar la pregunta:', response.data.message);
                }
            } catch (error) {
                console.error('Error al eliminar la pregunta:', error);
            }
        },





    }
};
</script>
