import ReporteTarjetasMFA from "../../../../components/componentsNomina/ReporteTarjetasMFA.vue";

export default {
  name: 'admon-tarjetas-mfa',
  components: { 'reporte-tarjetas': ReporteTarjetasMFA },
  props: [],
  data() {
    return {
      consultarReporte: true,
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {
    cerrarReporte(response) {
      console.log(response);
      this.consultarReporte = false;
    },
  }
}


