<template>
  <v-card-text>
    <div v-if="estatus != 'EMPTY'">
      <organigrama-escaleras-crecimiento
        :niveles="niveles"
        :presupuesto="presupuesto"
        :presupuestoReal="presupuestoReal"
        :depto="depto"
        :modulo="modulo"
        @crearNivel="nuevoNivel"
        @cierraOrganigrama="recargar"
      >
      </organigrama-escaleras-crecimiento>
    </div>
    <v-row align="center" justify="center" v-else>
      <div class="font-italic text-center">
        <v-icon large>mdi-stairs</v-icon> <br />
        No se encontraron niveles creadas en esta area <br />Clic en nuevo nivel
        para comenzar a cargar puestos <br />
        <v-btn
          color="cyan"
          text
          block
          :loading="loading"
          @click="nuevoNivel(0)"
        >
          Nuevo Nivel
        </v-btn>
      </div>
    </v-row>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <LoadingBar :dialog="verLoadingBar" :text="textoLoadingBar" />
  </v-card-text>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import OrganigramaEscalerasCrecimiento from "./OrganigramaEscalerasCrecimiento.vue";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import LoadingBar from "../../../../DesarrolloOrganizacional/components/loadingBar.vue";
export default {
  name: "familias-escaleras-crecimiento",
  props: ["item", "depto", "modulo"],
  components: {
    OrganigramaEscalerasCrecimiento,
    SnackBarErrorIncidencias,
    LoadingBar,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      familias: [],
      absolute: true,
      // nuevaFamilia: false,
      nombreFamilia: "",
      colorFamilia: "",
      nameRules: [
        (v) => !!v || "Nombre es requerido",
        (v) =>
          (v && v.length <= 50) || "Nombre debe tener no más de 50 caracteres",
      ],
      colorRules: [
        (v) => !!v || "Color es requerido",
        (v) =>
          (v && v.length <= 50) || "Nombre debe tener no más de 50 caracteres",
      ],
      valid: true,
      loading: false,
      estatus: "",
      familia: "ALL",
      niveles: [],
      verLoadingBar: true,
      textoLoadingBar: "Cargando puestos...",
      presupuesto: 0,
      presupuestoReal: 0,
    };
  },
  created() {},
  mounted() {
    this.getNiveles();
    console.log(this.modulo);
  },
  methods: {
    getNiveles() {
      this.clean();
      this.verLoadingBar = true;
      this.familia = this.depto;
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-niveles/${this.item}/${this.familia}/${this.modulo}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.niveles = response.data.data;
              this.estatus = response.data.status;
              this.presupuesto = response.data.presupuesto;
              this.presupuestoReal = response.data.presupuestoReal;
            } else if (response.data.status == "EMPTY") {
              this.estatus = response.data.status;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
            this.verLoadingBar = false;
          });
      });
    },

    getAllPuestos(familia) {
      this.familia = familia;
      this.cleanNiveles();
      this.getNiveles();
    },

    validate() {
      const validar = this.$refs.form.validate();
      if (validar) {
        this.loading = true;
        this.guardar();
      } else {
        this.textoBarra = "Complete el formulario para continuar";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        nombreFamilia: this.nombreFamilia,
        color: this.colorFamilia,
        idArea: this.item,
        correo: localStorage.getItem("correo"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-familia-escaleras-crecimiento`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              // this.getFamilias();
              // this.getDepartamentos();
              this.getNiveles();
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },

    nuevoNivel(nivel) {
      this.loading = true;
      this.verLoadingBar = true;
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        idArea: this.item,
        nivel: nivel,
        correo: localStorage.getItem("correo"),
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-escalera-crecimiento-nivel`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              this.getNiveles();
              // this.getFamilias();
              // this.getDepartamentos();
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
            this.verLoadingBar = false;
          });
      });
    },

    clean() {
      this.nombreFamilia = "";
      this.colorFamilia = "";
      this.familias = [];
      this.niveles = [];
      this.puesto = [];
      this.loading = false;
      // this.nuevaFamilia = false;
    },

    cleanNiveles() {
      this.nombreFamilia = "";
      this.colorFamilia = "";
      this.niveles = [];
    },

    recargar(response) {
      console.log(response);
      this.clean();
      // this.getFamilias();
      // this.getDepartamentos();
      this.getNiveles();
      this.verLoadingBar = true;
    },
  },
};
</script>