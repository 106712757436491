<template>
  <v-container>
    <v-card class="rounded-xl" elevation="0" color="blue-grey lighten-5">
      <v-data-table
        :headers="headers"
        :items="solicitudes"
        :search="search"
        :single-expand="true"
        :expanded.sync="expanded"
        show-expand
        item-key="id"
        dense
        class="elevation-0 rounded-lg"
        style="background-color:transparent;"
        no-data-text="Sin solicitudes registrados"
        loading-text="Cargando información..."
        :loading="loadingData"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
      >
        <template v-slot:top>
          <v-toolbar color="primary" flat class="rounded-xl rounded-b-0 pt-3">
            <div
              style="display: flex; justify-content: space-between; align-items: start; width: 100%;"
            >
              <v-text-field
                v-model="search"
                placeholder="Buscar..."
                prepend-inner-icon="mdi-magnify"
                class="rounded-lg"
                solo-inverted
                dark
                flat
                dense
                style="max-width: 650px;"
              ></v-text-field>
              <div>
                <!-- <AgregaPadrino @cierraRegistro="cierraRegistroAhijado" /> -->
                <v-btn
                  small
                  class="rounded-pill overline"
                  dark
                  outlined
                  fab
                  @click="updateTable"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </div>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip
            small
            :color="getColorChipStatus(item.estatus)"
            :dark="[0, 2].includes(item.estatus)"
          >
            {{
              item.estatus === 0
                ? "No iniciado"
                : item.estatus === 1
                ? "En Investigación"
                : "Cerrado"
            }}
          </v-chip>
        </template>
        <template v-slot:item.tipo="{ item }">
          <v-btn
            v-if="item.estatus === 0"
            x-small
            outlined
            color="primary"
            class="rounded-lg overline font-weight-bold"
            @click="
              muestraLoading = true;
              updateTipo(item.id, 12, 1);
            "
          >
            iniciar
          </v-btn>
          <v-menu
            v-else-if="item.estatus === 1"
            rounded="xl"
            offset-y
          >
            <template v-slot:activator="{ attrs, on }">
              <v-btn x-small outlined class="rounded-lg overline font-weight-bold" color="teal" v-bind="attrs" v-on="on">
                cerrar como...
              </v-btn>
            </template>

            <v-list dense>
              <v-list-item v-for="(opcion, index) in tiposActas" :key="index" link @click="muestraLoading = true;updateTipo(item.id, opcion.id, 2)">
                <v-list-item-title v-text="opcion.Descripcion"></v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <label v-else class="caption text-uppercase font-italic">
            cerrado con: {{ item.nombreTipo }}
          </label>
        </template>
        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length">
            <v-card class="ma-2 pa-3 elevation-0 rounded-xl">
              <v-row dense>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Puesto</label
                    >
                    <p>{{ item.puesto }}</p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Turno</label
                    >
                    <p>{{ item.turno }}</p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Líder</label
                    >
                    <p>{{ item.nombreResponsable }}</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Descripción del evento</label
                    >
                    <p class="text-justify">{{ item.comentarios }}</p>
                  </v-card>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Solicitante</label
                    >
                    <p>{{ item.nombreRequisitor }}</p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Fecha del evento</label
                    >
                    <p>{{ setFormatDate(item.fecha_incidencia) }}</p>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card
                    class="px-3 py-1 elevation-0 rounded-lg"
                    color="blue-grey lighten-5"
                  >
                    <label
                      class="overline font-weight-bold blue-grey--text text--darken2"
                      >Fecha de solicitud</label
                    >
                    <p>{{ setFormatDateTime(item.fecha_solicitud) }}</p>
                  </v-card>
                </v-col>
              </v-row>
              <div
                style="width: 100%;"
                class="pt-2"
              >
                <VerEvidenciasActas :solicitud="item" />
              </div>
            </v-card>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
    <loadingBar text="Cargando..." :dialog="muestraLoading"/>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import loadingBar from "../../../../DesarrolloOrganizacional/components/loadingBar.vue";
import VerEvidenciasActas from "./VerEvidenciasActas"

export default {
  components: { SnackBarErrorIncidencias, loadingBar, VerEvidenciasActas },
  data() {
    return {
      headers: [
        {
          value: "id",
          text: "folio",
          class: "primary overline font-weight-bold white--text",
        },
        {
          value: "empleado",
          text: "empleado",
          class: "primary overline font-weight-bold white--text",
        },
        {
          value: "nombreEmpleado",
          text: "nombre",
          class: "primary overline font-weight-bold white--text",
        },
        {
          value: "estatus",
          text: "estatus",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          value: "tipo",
          text: "tipo",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "",
          value: "data-table-expand",
          class: "primary overline font-weight-bold white--text",
        },
      ],
      solicitudes: [],
      expanded: [],
      tiposActas: [],
      search: "",
      colorBarra: "",
      textoBarra: "",
      loadingData: true,
      muestraBarra: false,
      muestraLoading: false,
    };
  },
  computed: {},
  async mounted() {
    this.tiposActas = await this.getTiposActas();
    this.solicitudes = await this.getSolicitudes();
  },
  methods: {
    showSnackBar(texto, color = "pink darken-2") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async updateTable() {
      this.loadingData = true;
      this.solicitudes = await this.getSolicitudes();
    },
    getSolicitudes() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/actas-administrativas/solicitudes`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.datos);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    getTiposActas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/actas-administrativas/tipos-actas`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.datos);
            } else {
              res([]);
            }
          });
      });
    },
    getColorChipStatus(estatus) {
      return estatus === 0
        ? "pink darken-2"
        : estatus === 1
        ? "yellow lighten-1"
        : "teal darken-2";
    },
    async updateTipo(id, tipoAsinado, estatus) {
      const response = await this.updateTipoSolicitud(id, tipoAsinado, estatus);
      if (response.status) {
        this.showSnackBar("Registro modificado con éxito.", "success");
        this.updateTable();
      } else {
        this.showSnackBar(
          "Orurrió un error. No se pudo modificar el registro."
        );
      }
    },
    updateTipoSolicitud(id, tipoAsinado, estatus) {
      const params = {
        estatus: estatus,
        id: id,
        tipo: tipoAsinado,
      };

      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve, reject) => {
        axios
          .put(
            `${this.$store.getters.getHostNode}/api/actas-administrativas/set-tipo`,
            params,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              resolve({ status: true, datos: response.data });
            } else {
              resolve({ status: false, datos: 0 });
            }
          })
          .catch((e) => {
            reject(e);
          })
          .finally(() => {
            this.muestraLoading = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    setFormatDateTime(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      let hora = fecha.getUTCHours();
      let minutos = fecha.getUTCMinutes();
      let segundos = fecha.getUTCSeconds();

      let horaFormateada = (hora < 10 ? "0" : "") + hora;
      let minutosFormateados = (minutos < 10 ? "0" : "") + minutos;
      let segundosFormateados = (segundos < 10 ? "0" : "") + segundos;

      return (
        diaUTC +
        "/" +
        mesUTC +
        "/" +
        anioUTC +
        " " +
        horaFormateada +
        ":" +
        minutosFormateados +
        ":" +
        segundosFormateados
      );
    },
  },
};
</script>
