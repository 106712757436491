var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600","content-class":"fix-border-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":"","color":"pink darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"small":"","outlined":"","color":"pink darken-2"}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("mdi-alert-box")])],1)]}}],null,true)},[_c('span',[_vm._v("Reportar a "+_vm._s(_vm.empleado.id))])])]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{staticClass:"rounded-xl rounded-b-0 overline font-weight-bold",attrs:{"color":"primary","flat":"","dark":""}},[_vm._v(" Reportar falta administrativa de "+_vm._s(_vm.empleado.title)+" ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('label',{staticClass:"overline"},[_vm._v("Descripción del evento a reportar")]),_c('v-textarea',{staticClass:"rounded-lg",attrs:{"placeholder":"Describe de manera breve el evento que vas a reportar...","outlined":"","dense":"","rows":4,"rules":_vm.descRules,"counter":500,"required":""},model:{value:(_vm.comentario),callback:function ($$v) {_vm.comentario=$$v},expression:"comentario"}}),_c('label',{staticClass:"overline"},[_vm._v("Fecha del evento a reportar")]),_c('v-dialog',{ref:"dialogFecha",attrs:{"return-value":_vm.fechaEvento,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fechaEvento=$event},"update:return-value":function($event){_vm.fechaEvento=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"placeholder":"Selecciona la fecha del evento...","prepend-icon":"mdi-calendar","dense":"","outlined":"","required":"","rules":_vm.rules,"readonly":""},model:{value:(_vm.fechaEvento),callback:function ($$v) {_vm.fechaEvento=$$v},expression:"fechaEvento"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.modalDate),callback:function ($$v) {_vm.modalDate=$$v},expression:"modalDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){_vm.$refs.dialogFecha.save(_vm.fechaEvento);
                _vm.modalDate = false;}},model:{value:(_vm.fechaEvento),callback:function ($$v) {_vm.fechaEvento=$$v},expression:"fechaEvento"}})],1),_c('label',{staticClass:"overline"},[_vm._v("Evidencia del evento a reportar")]),_c('v-file-input',{staticClass:"rounded-lg",attrs:{"dense":"","placeholder":"Si tienes evidencia adjúntala...","accept":"image/*","outlined":"","multiple":"","small-chips":"","show-size":""},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}}),(_vm.empleadoSeleccionado != null)?_c('div',[(
                _vm.empleadoSeleccionado.departamento !== _vm.padrino.departamento
              )?_c('v-alert',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","border":"left","type":"warning"}},[_c('strong',[_vm._v("¡ADVERTENCIA!")]),_vm._v(" El departamento del ahijado(a) es "),_c('strong',[_vm._v("diferente")]),_vm._v(" al del padrino o madrina seleccionada "),_c('strong',{staticClass:"overline font-weight-bold"},[_vm._v("esto puede presentar un riesgo para la seguridad del ahijado(a)")]),_vm._v(". ")]):_vm._e()],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"text":"","block":"","x-large":"","color":"teal darken-2 rounded-xl rounded-t-0 font-weight-bold"},on:{"click":function($event){dialog.value = false;
            _vm.validaFormulario();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Guardar ")],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }