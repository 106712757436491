<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom color="blue accent-4">
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-btn
            small
            outlined
            class="rounded-pill"
            color="blue accent-4"
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }"
            :disabled="padrino.ahijados >= 3"
            @click="seleccionado = padrino.id"
          >
            <v-icon>mdi-account-multiple-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Agregar ahijado</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card class="rounded-xl">
        <v-toolbar
          color="primary"
          flat
          class="rounded-xl rounded-b-0 overline font-weight-bold"
          dark
        >
          Agrega un ahijado a {{ padrino.EMP_NOMEMP }}
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="form">
            <label class="overline">Colaborador(a)</label>
            <v-autocomplete
              v-model="empleadoSeleccionado"
              dense
              outlined
              class="rounded-lg"
              placeholder="Busca por nombre o número de empleado..."
              :items="empleados"
              :loading="loadingEmpleados"
              item-text="nombreBusqueda"
              :item-value="(empleado) => empleado"
              @change="departamento = empleadoSeleccionado.departamento"
              :rules="rules"
              required
            ></v-autocomplete>
            <label class="overline">Departamento</label>
            <v-text-field
              v-model="departamento"
              dense
              disabled
              outlined
              class="rounded-lg"
            ></v-text-field>
            <div v-if="empleadoSeleccionado != null">
              <v-alert
                v-if="
                  empleadoSeleccionado.departamento !== padrino.departamento
                "
                dense
                outlined
                border="left"
                type="warning"
                class="rounded-lg"
              >
              <strong>¡ADVERTENCIA!</strong> El departamento del ahijado(a) es <strong>diferente</strong> al
                del padrino o madrina seleccionada
                <strong class="overline font-weight-bold"
                  >esto puede presentar un riesgo para la seguridad del
                  ahijado(a)</strong
                >.
              </v-alert>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            text
            block
            x-large
            color="teal darken-2 rounded-xl rounded-t-0"
            @click="
              if ($refs.form.validate()) {
                loadingSave = true;
                validaInformacion();
                dialog.value = false;
              }
            "
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  props: ["padrino"],
  data() {
    return {
      dialogAgregaAhijado: false,
      empleados: [],
      rules: [(v) => !!v || "Este campo es obligatorio."],
      loadingEmpleados: true,
      loadingSave: false,
      seleccionado: null,
      empleadoSeleccionado: null,
      departamento: null,
    };
  },

  watch: {
    async seleccionado(valor) {
      if (valor === this.padrino.id) {
        this.empleados = await this.getEmpleados();
      }
    },
  },
  methods: {
    getEmpleados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/empleados`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
    async validaInformacion() {
      if (this.$refs.form.validate()) {
        const respuesta = await this.setAhijado();
        if (respuesta.status === "OK") {
          this.$emit("cierraRegistro", {
            status: true,
            message: "Solicitud generada correctamente. ID: " + respuesta.data,
          });
        } else {
          this.$emit("cierraRegistro", {
            status: false,
            message: "ERROR. No se generó la solicitud. Intenta más tarde.",
          });
        }
      }
    },
    setAhijado() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const params = {
        emplid: this.empleadoSeleccionado.EMP_KEYEMP,
        idPadrino: this.padrino.id,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/set-ahijado`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      });
    },
  },
};
</script>
