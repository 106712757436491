<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="900"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom color="blue accent-4">
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-btn
            small
            outlined
            class="rounded-pill mr-1"
            color="blue accent-4"
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }"
            :disabled="padrino.ahijados <= 0"
            @click="seleccionado = padrino.id"
          >
            <v-icon>mdi-account-eye-outline</v-icon>
          </v-btn>
        </template>
        <span>Ver ahijados</span>
      </v-tooltip>
    </template>
    <template>
      <v-card class="rounded-xl">
        <v-toolbar
          color="primary"
          flat
          class="rounded-xl rounded-b-0 overline font-weight-bold"
          dark
        >
          Ahijados de {{ padrino.EMP_NOMEMP }}
        </v-toolbar>
        <div class="primary px-4" v-if="showAlert">
          <v-alert
            v-model="showAlert"
            dense
            border="left"
            :type="alertType"
            dismissible
            class="mb-0"
          >
            {{ alertMessage }}
          </v-alert>
        </div>
        <v-card-text class="pa-0">
          <v-data-table
            :items="ahijados"
            :headers="headers"
            :search="search"
            :loading="loadingEmpleados"
            dense
            no-data-text="Sin ahijados registrados"
            loading-text="Cargando información..."
            :footer-props="{
              'items-per-page-text': 'Elementos por página',
              pageText: '{0}-{1} de {2}',
            }"
          >
            <template v-slot:top>
              <v-toolbar color="primary" flat class="rounded-0 pt-3">
                <div
                  style="display: flex; justify-content: space-between; align-items: start; width: 100%;"
                >
                  <v-text-field
                    v-model="search"
                    placeholder="Buscar..."
                    prepend-inner-icon="mdi-magnify"
                    class="rounded-lg"
                    solo-inverted
                    dark
                    flat
                    dense
                    style="max-width: 650px;"
                  ></v-text-field>
                  <div>
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          class="rounded-pill overline"
                          dark
                          outlined
                          fab
                          v-bind="attrs"
                          v-on="on"
                          @click="updateTable"
                        >
                          <v-icon>mdi-refresh</v-icon>
                        </v-btn>
                      </template>
                      <span>Actualizar tabla</span>
                    </v-tooltip>
                  </div>
                </div>
              </v-toolbar>
            </template>
            <template v-slot:item.fecha_asignacion="{ item }">
              {{ setFormatDate(item.fecha_asignacion) }}
            </template>
            <template v-slot:item.dias_restante="{ item }">
              <label class="font-italic font-weight-bold">{{
                item.dias_restante
              }}</label>
            </template>
            <template v-slot:item.acciones="{ item }">
              <v-tooltip top color="pink darken-2">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    x-small
                    class="rounded-pill"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                    color="pink darken-2"
                    @click="
                      nombreSeleccion = item.EMP_NOMEMP;
                      ahijadoAux = item;
                      showConfirm = true;
                    "
                  >
                    <v-icon small>mdi-minus-thick</v-icon>
                  </v-btn>
                </template>
                <span>Quitar a {{ item.emplid }}</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </template>

    <ConfirmDialog
      :showConfirm="showConfirm"
      :question="'¿Realmente deseas quitar a ' + nombreSeleccion + '?'"
      @cierraConfirm="cierraConfirm"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import ConfirmDialog from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";

export default {
  props: ["padrino"],
  components: { ConfirmDialog },
  data() {
    return {
      ahijados: [],
      headers: [
        {
          text: "id",
          value: "id",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "emplid",
          value: "emplid",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "nombre",
          value: "EMP_NOMEMP",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "asignación",
          value: "fecha_asignacion",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "días restantes",
          value: "dias_restante",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          class: "primary overline font-weight-bold white--text",
        },
      ],
      loadingEmpleados: true,
      loadingSave: false,
      showConfirm: false,
      showAlert: false,
      seleccionado: null,
      ahijadoAux: null,
      nombreSeleccion: "",
      search: "",
      alertType: "",
      alertMessage: "",
    };
  },

  watch: {
    async seleccionado(valor) {
      if (valor === this.padrino.id) {
        this.ahijados = await this.getAhijados();
      }
    },
  },
  methods: {
    async updateTable() {
      this.loadingEmpleados = true;
      this.ahijados = await this.getAhijados();
    },
    getAhijados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/ahijados/${this.padrino.id}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    async cierraConfirm(respuesta) {
      this.showConfirm = false;
      if (respuesta) {
        const respuesta = await this.setEstatusAhijado();
        if (respuesta.status == "OK") {
          this.updateTable();
        }
        this.alertMessage = respuesta.message;
        this.alertType = respuesta.status === "OK" ? "success" : "error";
        this.showAlert = true;
      }
    },
    setEstatusAhijado() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .patch(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/update-ahijados`,
            {
              id: this.ahijadoAux.id,
            },
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res({
                status: "ERROR",
                message: "Solicitud no procesada. Intente más tarde.",
              });
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
  },
};
</script>
