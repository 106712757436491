var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[(_vm.loading)?_c('v-card',[_c('h2',{staticClass:"text-center blue-grey--text text--lighten-2 mb-5"},[_vm._v(" Cargando contenido... ")]),_c('div',{staticClass:"mx-auto",attrs:{"align":"center"}},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":70,"width":7}})],1)]):_c('v-card',[_c('v-card-text',[_c('v-autocomplete',{attrs:{"items":_vm.areas,"label":"Selecciona un area","hide-selected":"","outlined":"","dense":"","item-value":"id","item-text":"Area","color":"cyan","clearable":""},on:{"change":function($event){return _vm.getDepartamentos(2)}},model:{value:(_vm.area),callback:function ($$v) {_vm.area=$$v},expression:"area"}})],1),_c('div',{staticClass:"px-5 d-flex"},[_c('v-card',{staticClass:"mr-3 mb-3 px-3 cursor card-deptos-escalera",attrs:{"width":"150"},on:{"click":function($event){return _vm.handleConsultar('ALL')}}},[_c('p',{staticClass:"ma-0 d-flex justify-end"},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-palette")])],1),_c('v-divider'),_c('p',{staticClass:"d-flex align-center ma-0 text-uppercase"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"truncate-text ma-0"},'p',attrs,false),on),[_vm._v(" Todos los departamentos ")])]}}])},[_c('span',[_vm._v("Todos los departamentos")])])],1)],1),_c('div',{staticClass:"scroll-container-escalera"},_vm._l((_vm.departamentos),function(item,index){return _c('p',{key:index},[_c('v-card',{staticClass:"mr-3 mb-3 px-3 cursor card-deptos-escalera",attrs:{"width":"150","color":item.color,"dark":item.dark == 1 ? true : false}},[_c('p',{staticClass:"ma-0 d-flex justify-end"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":""},on:{"click":function($event){return _vm.openDialogColor(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-palette")])]}}],null,true)},[_c('span',[_vm._v("Agregar color")])])],1),_c('v-divider'),_c('p',{staticClass:"d-flex align-center all-screen",attrs:{"height":"100%"},on:{"click":function($event){return _vm.handleConsultar(item)}}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('p',_vm._g(_vm._b({staticClass:"truncate-text ma-0"},'p',attrs,false),on),[_vm._v(" ["+_vm._s(item.id)+"] "+_vm._s(item.depto)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.depto)+" "+_vm._s(item.color))])])],1)],1)],1)}),0)],1),_c('v-card-actions',[(_vm.area && _vm.depto)?_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"color":"teal darken-1","loading":_vm.loading,"dark":"","block":"","small":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-stairs")]),_vm._v(" Consultar ")],1):_c('v-btn',{staticClass:"rounded-lg elevation-0",attrs:{"disabled":"","block":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-stairs")]),_vm._v(" Consultar ")],1)],1)],1),(_vm.validar && _vm.area && _vm.depto)?_c('v-card',{staticClass:"mt-5",attrs:{"color":"blue-grey lighten-4"}},[_c('familias-escaleras-crecimiento',{ref:"familiasComponent",attrs:{"item":_vm.area,"depto":_vm.depto,"modulo":'EscaleraCrecimiento'}})],1):_vm._e(),(_vm.dialogColor)?_c('form-admon-deptos',{attrs:{"dialog":_vm.dialogColor,"item":_vm.item},on:{"cierraDialog":_vm.close}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }