<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="650"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip bottom color="blue accent-4">
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-btn
            small
            class="rounded-pill overline mr-1"
            dark
            outlined
            fab
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }"
          >
            <v-icon>mdi-account-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Agregar padrino</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card class="rounded-xl">
        <v-toolbar
          color="primary"
          flat
          class="rounded-xl rounded-b-0 overline font-weight-bold"
          dark
        >
        crear padrino o madrina
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="form">
            <label class="overline">Colaborador(a)</label>
            <v-autocomplete
              v-model="empleadoSeleccionado"
              dense
              outlined
              class="rounded-lg"
              placeholder="Busca por nombre o número de empleado..."
              :items="empleados"
              :loading="loadingEmpleados"
              item-text="nombreBusqueda"
              :item-value="(empleado) => empleado"
              @change="departamento = empleadoSeleccionado.departamento; antiguedad = empleadoSeleccionado.antiguedad"
              :rules="rules"
              required
            ></v-autocomplete>

            <v-row dense>
              <v-col cols="8">
                <label class="overline">Departamento</label>
                <v-text-field
                  v-model="departamento"
                  dense
                  disabled
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <label class="overline">antigüedad (años)</label>
                <v-text-field
                  v-model="antiguedad"
                  dense
                  disabled
                  outlined
                  class="rounded-lg"
                ></v-text-field>
              </v-col>
            </v-row>

            <div v-if="empleadoSeleccionado != null">
              <v-alert
                v-if="
                  empleadoSeleccionado.antiguedad < 1
                "
                dense
                outlined
                border="left"
                type="warning"
                class="rounded-lg"
              >
                <strong>¡ADVERTENCIA!</strong> La antigüedad del colaborador seleccionado 
                <strong>es menor</strong> a un año 
                <strong class="overline font-weight-bold"
                  >esto puede presentar un riesgo para la seguridad del
                  ahijado(a)</strong
                >.
              </v-alert>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            text
            block
            x-large
            color="teal darken-2 rounded-xl rounded-t-0"
            @click="
              if ($refs.form.validate()) {
                loadingSave = true;
                validaInformacion();
                dialog.value = false;
              }
            "
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
export default {
  props: [],
  data() {
    return {
      dialogAgregaAhijado: false,
      empleados: [],
      rules: [(v) => !!v || "Este campo es obligatorio."],
      loadingEmpleados: true,
      loadingSave: false,
      seleccionado: null,
      empleadoSeleccionado: null,
      departamento: null,
      antiguedad: null
    };
  },
  async mounted() {
    this.empleados = await this.getEmpleados();
  },
  methods: {
    getEmpleados() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/empleados`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingEmpleados = false;
          });
      });
    },
    async validaInformacion() {
      if (this.$refs.form.validate()) {
        const respuesta = await this.setPadrino();
        this.empleadoSeleccionado = null;
        if (respuesta.status === "OK") {
          this.$emit("cierraRegistro", {
            status: true,
            message: "Solicitud generada correctamente. ID: " + respuesta.data,
          });
        } else {
          this.$emit("cierraRegistro", {
            status: false,
            message: respuesta.data.messageTransaction,
          });
        }
      }
    },
    setPadrino() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const params = {
        emplid: this.empleadoSeleccionado.EMP_KEYEMP,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/set-padrino`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      });
    },
  },
};
</script>
