import axios from 'axios'
import {
    deleteToken,
    formatDate
} from '../../../../../store/Funciones/funciones'
// import FiltroReporte from '../../../RelacionesLaborales/Reportes/ReporteIncidenciasVigilancia/Components/FiltroReporte.vue';
import VueJsonToCsv from "vue-json-to-csv";
import DetallesChecadasPorEmpleado from "./components/DetallesChecadasPorEmpleado.vue"
import ExportarReportesChecadas from "./components/ExportarReportesChecadas.vue"
import FiltrosParaGenerarReporte from "./components/FiltrosParaGenerarReporte.vue"

export default {
    name: 'reporte-de-asistencia',
    components: {
        VueJsonToCsv,
        DetallesChecadasPorEmpleado,
        ExportarReportesChecadas,
        FiltrosParaGenerarReporte
    },
    props: [],
    data() {
        return {
            checadas: [],
            areas: [],
            dates: [],
            departamentos: [],
            gruposDePago: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            headers: [{
                    text: 'Emplid',
                    value: 'EMPLID',
                    align: 'center'
                },
                {
                    text: 'Nombre',
                    value: 'NAME',
                    align: 'center'
                },
                {
                    text: 'Proceso',
                    value: 'UR_GP_PAYGROUP',
                    align: 'center',
                    filter: this.groupFilter
                },
                {
                    text: 'Área',
                    value: 'Area',
                    align: 'center',
                    filter: this.areaFilter
                },
                {
                    text: 'DEPTID',
                    value: 'DEPTID',
                    align: 'center',
                    filter: this.deptoFilter
                },
                {
                    text: 'Fecha',
                    value: 'FECHA',
                    align: 'center'
                },
                {
                    text: 'IdTurno ',
                    value: 'IdTurno',
                    align: 'center'
                },
                {
                    text: 'ingreso',
                    value: 'Hora_Entrada',
                    align: 'center'
                },
                {
                    text: 'salida',
                    value: 'Hora_Salida',
                    align: 'center'
                },
            ],
            headers_report_to_export: {
                id: {
                    title: "id"
                },
                EMPLID: {
                    title: "EMPLID"
                },
                NAME: {
                    title: "NAME"
                },
                DEPTID: {
                    title: "DEPTID"
                },
                Area: {
                    title: "Area"
                },
                FECHA: {
                    title: "FECHA"
                },
                fecha_checador_hikcentral: {
                    title: "fecha_checador_hikcentral"
                },
                IdTurno: {
                    title: "IdTurno"
                },
                Descripcion: {
                    title: "Descripcion"
                },
                EntradaReal: {
                    title: "EntradaReal"
                },
                SalidaReal: {
                    title: "SalidaReal"
                },
                Hora_Entrada: {
                    title: "Hora_Entrada"
                },
                Hora_Salida: {
                    title: "Hora_Salida"
                },
                Ingreso_Americas: {
                    title: "Ingreso_Americas"
                },
                Salida_Americas: {
                    title: "Salida_Americas"
                },
                Ingreso_Planta: {
                    title: "Ingreso_Planta"
                },
                Salida_Planta: {
                    title: "Salida_Planta"
                },
                Vacaciones: {
                    title: "Vacaciones"
                },
                SalidaAnticipada: {
                    title: "SalidaAnticipada"
                },
                TxT: {
                    title: "TxT"
                },
                Permiso: {
                    title: "Permiso"
                },
                LlegadaTarde: {
                    title: "LlegadaTarde"
                },
                FlexTime: {
                    title: "FlexTime"
                },
                HomeOffice: {
                    title: "HomeOffice"
                },
                Teletrabajo: {
                    title: "Teletrabajo"
                },
                UR_GP_PAYGROUP: {
                    title: "UR_GP_PAYGROUP"
                },
            },
            loading: true,
            loadingAreas: true,
            loadingDepartamentos: false,
            buscar: '',
            fechaFinal: '',
            fechaInicial: '',
            areaFilterValue: '',
            deptoFilterValue: '',
            groupFilterValue: '',
            dialogPeriodo: false,
            dialogFechas: false,

            verDetalleEmpleado: false,
            diaDetalles: null,
            emplidDetalles: null,
        }
    },
    computed: {
        dateRangeText() {
            let inicio = "";
            let fin = "";
            if (this.dates[0] != null) {
                inicio = formatDate(this.dates[0]);
            }
            if (this.dates[1] != null) {
                fin = formatDate(this.dates[1]);
            }

            if (this.dates.length == 0) {
                this.lblDate = "";
            }
            return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
        }
    },
    // async mounted() {
    //     this.getFechas();
    //     this.checadas = await this.getChecadas().catch(e => {
    //         alert(e);
    //     });
    //     this.areas = await this.getAreas().catch(e => {
    //         alert(e);
    //     });
    //     this.areas[0].Area = 'Todas las áreas'
    // },
    methods: {
        getChecadas() {
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            return new Promise((res, rej) => {
                axios.get(`${this.$store.getters.getHostNode}/api/get-asistencia/${this.dates[0]}/${this.dates[1]}`, {
                        headers
                    })
                    .then((response) => {
                        if (response.data.status == "OK") {
                            res(response.data.data);
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else {
                            alert(response.data.message)
                            res([]);
                        }
                    })
                    .catch((e) => {
                        rej(e)
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            })

        },
        getAreas() {
            let params = {
                Opcion: 25,
                token: localStorage.getItem('token'),
            }

            return new Promise((res, rej) => {
                axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                    .then((response) => {

                        if (response.data.status == "OK") {
                            res(response.data.areas);
                        } else if (response.data.status == "VACIO") {
                            rej([]);
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        }
                    })
                    .finally(() => {
                        this.loadingAreas = false;
                    })
            })

        },
        getFechas() {
            var fechaActual = new Date();

            // Obtener los componentes de la fecha
            var year = fechaActual.getFullYear();
            var month = String(fechaActual.getMonth() + 1).padStart(2, '0');
            var day = String(fechaActual.getDate()).padStart(2, '0');



            // Restar 7 días a la fecha actual
            var fechaHaceUnaSemana = new Date(fechaActual);
            fechaHaceUnaSemana.setDate(fechaActual.getDate() - 7);

            // Obtener componentes de fecha
            var año = fechaHaceUnaSemana.getFullYear();
            var mes = ('0' + (fechaHaceUnaSemana.getMonth() + 1)).slice(-2); // Sumar 1 ya que los meses comienzan desde 0
            var dia = ('0' + fechaHaceUnaSemana.getDate()).slice(-2);

            // Formatear la fecha en 'yyyy-mm-dd'
            this.dates.push(año + '-' + mes + '-' + dia);
            this.dates.push(`${year}-${month}-${day}`);
        },

        cambiarPeriodo() {
            this.dialogPeriodo = true;
        },

        clean() {
            this.loading = true;
            this.checadas = [];
        },

        fixFormatDate(date) {
            return formatDate(date)
        },

        actualizaFechas(nuevasFechas) {
            // Maneja las nuevas fechas aquí
            if (this.fechaInicial != nuevasFechas.fechaInicial || this.fechaFinal != nuevasFechas.fechaFinal) {
                this.fechaInicial = nuevasFechas.fechaInicial;
                this.fechaFinal = nuevasFechas.fechaFinal;
                this.clean();
                this.getChecadas();
            }
            this.dialogPeriodo = false;
        },
        async cierraDialog() {
            if (this.dates.length == 2) {
                if (this.dates[0] > this.dates[1]) {
                    this.dates.pop();
                    this.colorBarra = "error";
                    this.textoBarra =
                        "La fecha final no puede ser menor a la inicial. :c";
                    this.muestraBarra = true;
                } else {
                    this.dialogFechas = false;
                    // this.$emit('cierraFiltro', {
                    //     fechaInicial: this.dates[0],
                    //     fechaFinal: this.dates[1]
                    // });
                    this.loading = true;
                    this.checadas = await this.getChecadas().catch(e => {
                        alert(e);
                    });
                }
            }
        },

        areaFilter(value) {
            if (["", null, undefined, "Todas las áreas"].includes(this.areaFilterValue)) {
                return true;
            }

            let id = this.areas.filter((a) => {
                    // console.log(a.Area + "===" + this.areaFilterValue, a.Area === this.areaFilterValue)
                    return a.Area === this.areaFilterValue
                })[0]
                // console.log(value + "===" + id.id, value === id.id)
            return value === id.Area;
        },

        deptoFilter(value) {
            if (["", null, undefined, "Todas las áreas"].includes(this.deptoFilterValue)) {
                return true;
            }

            let id = this.departamentos.filter((a) => {
                return a.DESCR === this.deptoFilterValue
            })[0]
            return value === id.DEPTID;
        },

        groupFilter(value) {
            if (["", null, undefined, "Todos"].includes(this.groupFilterValue) || this.groupFilterValue.length === 0) {
                return true;
            }
            // console.log(this.groupFilterValue, value, this.groupFilterValue.includes(parseInt(value)))
            return this.groupFilterValue.includes(parseInt(value));
        },

        findDepartaments() {
            if ([null, undefined, ""].includes(this.areaFilterValue)) {
                let area = this.areas.filter((a) => {
                    return a.Area === this.areaFilterValue
                })[0]

                let params = {
                    Opcion: 26,
                    token: localStorage.getItem('token'),
                    idArea: area.id
                }
                axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                    .then((response) => {

                        if (response.data.status == "OK") {
                            this.departamentos = response.data.deptos;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        }
                    })
                    .finally(() => {
                        this.loadingDepartamentos = false;
                    })

            } else {
                this.deptoFilterValue = null
            }

        },
    }
}