<template>
  <div>
    <div style="display: flex; justify-content: right">
      <v-btn small elevation="0" color="cyan darken-1" dark class="rounded-pill mr-1" @click="showConfirm = true">
        validar
        <v-icon right>mdi-check</v-icon>
      </v-btn>
      <vue-json-to-csv :json-data="report_to_export" :labels="headers_report_to_export"
        csv-title="reporteDeAsistencias">
        <v-btn small elevation="0" color="success" class="rounded-pill">
          Exportar
          <v-icon right small> mdi-microsoft-excel </v-icon>
        </v-btn>
      </vue-json-to-csv>
    </div>
    <div v-for="semana in data" :key="semana.week">
      <v-row>
        <v-col cols="4" style="display: flex; align-items: center">
          <div class="subtitle-1 font-weight-bold">
            Semana: {{ semana.week }}.
          </div>
        </v-col>
        <v-col cols="8">
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Buscar..."></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" style="display: flex; align-items: center">
          <h3 class="text-left" style="margin-top: -51px;">
            Del {{ semana.begin }} al {{ semana.end }}
          </h3>
        </v-col>
      </v-row>
      <v-data-table :headers="headers" :items="semana.data" :search="search" :items-per-page="-1" item-key="emplid"
        :footer-props="{
          'items-per-page-options': [-1, 5, 10],
          'items-per-page-text': 'Elementos por página',
        }" no-data-text="No hay registros" :header-props="{
          'short-by-text': 'Ordenar por',
        }" class="elevation-1 rounded-lg" dense>
        <template v-slot:[`item.monday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.monday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.tuesday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.tuesday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.wednesday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.wednesday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.thursday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.thursday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.friday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.friday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.saturday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.saturday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.sunday`]="{ item }">
          <reporte-asistencias-informacion-de-dia-comite :InfoDay="item.asistencias.sunday" :empleado="item.emplid"
            @reloadData="reloadData" />
        </template>
        <template v-slot:[`item.hoursWorked`]="{ item }">
          {{ item.hoursWorked.toFixed(2) }} de {{ item.hoursToWork }}
        </template>
      </v-data-table>
    </div>

    <ConfirmDialogReclutamiento :showConfirm="showConfirm" :question="question" @cierraConfirm="closeValidation" />
    <SnackBarErrorIncidencias :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false" />
  </div>
</template>

<script>
import ReporteAsistenciasInformacionDeDiaComite from "./ReporteAsistenciasInformacionDeDiaComite.vue";
import VueJsonToCsv from "vue-json-to-csv";
import ConfirmDialogReclutamiento from "../../views/generated/AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
import axios from 'axios'
import SnackBarErrorIncidencias from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../store/Funciones/funciones";

export default {
  name: "reporte-asistensias-comite",
  components: {
    ReporteAsistenciasInformacionDeDiaComite,
    VueJsonToCsv,
    ConfirmDialogReclutamiento,
    SnackBarErrorIncidencias
  },
  data: () => ({
    search: "",
    textoBarra: "",
    colorBarra: "",
    question: "¿Realmente deseas validar esta información?",
    report_to_export: [],
    checadas: [],
    page: 1,
    pageCount: 0,
    itemsPerPage: 5,
    expanded: [],
    items: [
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me" },
      { title: "Click Me 2" },
    ],
    headers: [
      {
        text: "# empleado",
        align: "start",
        value: "emplid",
      },
      {
        text: "Nombre",
        value: "name",
      },
      {
        text: "Lunes",
        value: "monday",
      },
      {
        text: "Martes",
        value: "tuesday",
      },
      {
        text: "Miércoles",
        value: "wednesday",
      },
      {
        text: "Jueves",
        value: "thursday",
      },
      {
        text: "Viernes",
        value: "friday",
      },
      {
        text: "Sábado",
        value: "saturday",
      },
      {
        text: "Domingo",
        value: "sunday",
      },
      {
        text: "Faltas",
        value: "faltas",
      },
      {
        text: "Retardos",
        value: "retardos",
      },
      {
        text: "Horas trabajadas",
        value: "hoursWorked",
      },
      // {
      //   text: "Horas por trabajar",
      //   value: "hoursToWork",
      // },
    ],
    singleExpand: false,
    offset: true,
    loadingChecadas: false,
    showConfirm: false,
    muestraBarra: false,
    headers_report_to_export: {
      semana: { title: "Semana" },
      NumEmplid: { title: "Empleado" },
      Nombre: { title: "Nombre completo" },
      Responsable: { title: "Responsable" },
      Turno: { title: "Turno" },
      Lunes: { title: "Lunes" },
      Martes: { title: "Martes" },
      Miercoles: { title: "Miércoles" },
      Jueves: { title: "Jueves" },
      Viernes: { title: "Viernes" },
      Sabado: { title: "Sábado" },
      Faltas: { title: "Faltas" },
      Retardos: { title: "Retardos" },
      horasPorTrabajar: { title: "Hrs. Plan" },
      horasTrabajadas: { title: "Hrs. Real" },
    },
  }),
  props: ["data"],
  mounted() {
    this.createJSONtoExport();
  },
  methods: {
    async createJSONtoExport() {

      const promises = this.data.map((semana) => {
        semana.data.map((asistencia) => {
          let json_to_insert = {
            semana: semana.week,
            NumEmplid: asistencia.emplid,
            Nombre: asistencia.name,
            Responsable: asistencia.lead,
            Turno: asistencia.workShift,
            Lunes: this.getStatusExport(asistencia.asistencias.monday),
            Martes: this.getStatusExport(asistencia.asistencias.tuesday),
            Miercoles: this.getStatusExport(asistencia.asistencias.wednesday),
            Jueves: this.getStatusExport(asistencia.asistencias.thursday),
            Viernes: this.getStatusExport(asistencia.asistencias.friday),
            Sabado: this.getStatusExport(asistencia.asistencias.saturday),
            Domingo: this.getStatusExport(asistencia.asistencias.sunday),
            Faltas: asistencia.faltas,
            Retardos: asistencia.retardos,
            horasPorTrabajar: asistencia.hoursToWork,
            horasTrabajadas: asistencia.hoursWorked.toFixed(2),
            // Incapacidades: asistencia.incapacidades,
          };
          this.report_to_export.push(json_to_insert);
        });
      });

      await Promise.all(promises);
      this.loadingReport = false;
    },
    getStatusExport(item) {
      if (item !== undefined) {
        if (item.falta === 1 && !item.festivo) {
          return "falta";
        } else if (item.falta === 1 && item.festivo) {
          return "festivo";
        } else {
          if (Object.keys(item.incidencia).length > 0) {
            // return item.incidencia.details != ""
            //   ? item.incidencia.desc + ": " + item.incidencia.details
            //   : item.incidencia.desc;

            return item.incidencia.details != "" && ["Salida anticipada", "Llegada tarde"].includes(item.incidencia.desc)
              ? item.incidencia.desc + ": " + item.incidencia.details + " (" + item.ingreso + " - " + item.salida + ")"
              : item.incidencia.details && !["Salida anticipada", "Llegada tarde"].includes(item.incidencia.desc)
                ? item.incidencia.desc + ": " + item.incidencia.details
                : item.incidencia.desc;
          } else if (!item.labora) {
            return "descansa";
          } else {
            return item.ingreso + " - " + item.salida;
          }
        }
      } else {
        return "Vacio";
      }
    },
    reloadData() {
      this.$emit('reloadData')
    },
    closeValidation(value) {
      if (!value) {
        this.showConfirm = false;
      } else {
        this.showConfirm = false;
        this.sendListaAsistensias()
      }
    },
    sendListaAsistensias() {
      const params = {
        Opcion: 7,
        token: localStorage.getItem('token'),
        dataBody: this.report_to_export,
        dataHeader: this.headers_report_to_export
      }
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then((response) => {
          if (response.data.status != "EXPSESSION") {
            if (response.data.status == "OK" && response.data.respuesta == 1) {
              this.textoBarra = "Correo enviado con éxito";
              this.colorBarra = "teal darken-1"
              this.muestraBarra = true
            } else {
              this.textoBarra = "No se pudo enviar el correo";
              this.colorBarra = "pink darken-3"
              this.muestraBarra = true
            }
          } else {
            deleteToken()
          }
        }).catch((e) => {
          this.textoBarra = e;
          this.colorBarra = "pink darken-3"
          this.muestraBarra = true
        });
    }
  },
};
</script>
