import VerDatosEquipo from "./components/VerDatosEquipo.vue"
export default {
    name: 'informacion-de-mi-equipo',
    components: { VerDatosEquipo },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}