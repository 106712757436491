import axios from 'axios'
import PersonaPerfil from '../../../../components/PersonaPerfil.vue'
import SnackBarErrorIncidencias from "../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
export default {
    name: 'mi-persona',
    components: {
        PersonaPerfil,
        SnackBarErrorIncidencias
    },
    props: [],
    data() {
        return {
            colaborador: [],
            folio: '',
            compania: '',
            muestra: false,
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            loadingInfo: true,
        }
    },
    created() {
        this.MiInfoPersonal();
    },
    mounted() {
        //  window.document.title = "DH:URREA - Mi persona.";
    },
    methods: {
        MiInfoPersonal() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-MiPersona.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.folio = response.data.folio;
                    this.compania = response.data.compania;
                    let paramsData = {
                        Opcion: 12,
                        token: localStorage.getItem('token'),
                        folio: this.folio,
                        compania: this.compania
                    };
                    axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, paramsData).then((responseData) => {
                        if (responseData.data.status == 'OK') {
                            this.muestra = true;
                            this.colaborador = responseData.data.colaborador;
                            if (this.colaborador['conocimiento']) {
                                this.colaborador['conocimiento'] = this.colaborador['conocimiento'].split('*');
                            }
                            if (this.colaborador['objetivos']) {
                                this.colaborador['objetivos'] = this.colaborador['objetivos'].split('*');
                            }
                            if (this.colaborador['foto']) {
                                this.colaborador['link'] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/";
                                this.colaborador['linkCompleto'] = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['foto'];
                                // this.colaborador['linkCompleto'] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['foto'];
                            } else {
                                this.colaborador['link'] = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                                this.colaborador['linkCompleto'] = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                            }
                            if (this.colaborador['fotoGeneralista']) {
                                this.colaborador['linkCompletoGeneralista'] = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['fotoGeneralista'];
                                // this.colaborador['linkCompletoGeneralista'] = this.$store.getters.getHost + "/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + this.colaborador['fotoGeneralista'];
                            } else {
                                this.colaborador['linkCompletoGeneralista'] = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                            }

                        } else {
                            this.textoBarra = 'Datos no encontrados, es posible que no se encuentre su usuario registrado ';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;

                        }
                        this.loadingInfo = false;

                    })
                }
                this.cargando = false;
            })
        }
    }
}