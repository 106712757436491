var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',{staticClass:"rounded-xl",attrs:{"elevation":"0","color":"blue-grey lighten-5"}},[_c('v-data-table',{staticClass:"elevation-0 rounded-lg",staticStyle:{"background-color":"transparent"},attrs:{"headers":_vm.headers,"items":_vm.padrinos,"dense":"","no-data-text":"Sin padrinos registrados","loading-text":"Cargando información...","loading":_vm.loadingData,"footer-props":{
        'items-per-page-text': 'Elementos por página',
        pageText: '{0}-{1} de {2}',
      }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-xl rounded-b-0 pt-3",attrs:{"color":"primary","flat":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"start","width":"100%"}},[_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"650px"},attrs:{"placeholder":"Buscar...","prepend-inner-icon":"mdi-magnify","solo-inverted":"","dark":"","flat":"","dense":""}}),_c('div',[_c('AgregaPadrino',{on:{"cierraRegistro":_vm.cierraRegistroAhijado}}),_c('v-btn',{staticClass:"rounded-pill overline",attrs:{"small":"","dark":"","outlined":"","fab":""},on:{"click":_vm.updateTable}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1)],1)])]},proxy:true},{key:"item.fecha_asignacion",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.fecha_asignacion))+" ")]}},{key:"item.estatus",fn:function(ref){
      var item = ref.item;
return [_c('v-chip',{attrs:{"small":"","depressed":"","color":item.estatus ? 'teal' : 'pink',"dark":""}},[_vm._v(" "+_vm._s(item.estatus ? "Habilitado" : "Inhabilitado")+" ")])]}},{key:"item.ahijados",fn:function(ref){
      var item = ref.item;
return [_c('label',{staticClass:"font-italic font-weight-bold"},[_vm._v(_vm._s(item.ahijados))])]}},{key:"item.acciones",fn:function(ref){
      var item = ref.item;
return [_c('VerAhijados',{attrs:{"padrino":item}}),_c('AgregaAhijado',{attrs:{"padrino":item,"seleccionado":_vm.seleccionado},on:{"click":function($event){_vm.seleccionado = item.id},"cierraRegistro":_vm.cierraRegistroAhijado}})]}}])})],1),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"color":_vm.colorBarra,"texto":_vm.textoBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }