<template>
  <v-dialog v-model="muestraReporte" fullscreen scrollable>
    <v-card class="rounded-0 elevation-0">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Reporte de ausentismos</v-toolbar-title>
        <v-spacer></v-spacer>
        <div>
          <vue-json-to-csv
            :json-data="reporte"
            :labels="headers_report_to_export"
            csv-title="ReporteChecadas"
          >
            <v-btn dark outlined small>
              <v-icon left> mdi-microsoft-excel </v-icon>
              exportar
            </v-btn>
          </vue-json-to-csv>
          <v-btn icon dark @click="$emit('cierraReporte')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-toolbar>
      <v-card-text>
        <v-data-table
          dense
          :items="reporte"
          :headers="headers"
          :items-per-page="25"
          :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }"
          no-data-text="No hay registros"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import VueJsonToCsv from "vue-json-to-csv";

export default {
  props: ["reporte", "muestraReporte"],
  components: { VueJsonToCsv },
  data() {
    return {
      headers: [
        {
          value: "EMPLID",
          text: "EMPLID",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "NAME",
          text: "NAME",
          class:
            "overline font-weight-bold blue-grey--text min-width-per-column",
        },
        {
          value: "DEPTID",
          text: "DEPTID",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "EMP_KEYDEP",
          text: "EMP_KEYDEP",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "grupo_Trabajo",
          text: "grupo_Trabajo",
          class:
            "overline font-weight-bold blue-grey--text min-width-per-column",
        },
        {
          value: "COMP_FREQUENCY",
          text: "COMP_FREQUENCY",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Area",
          text: "Area",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "FECHA",
          text: "FECHA",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "fecha_checador_hikcentral",
          text: "fecha_checador_hikcentral",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "IdTurno",
          text: "IdTurno",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Descripcion",
          text: "Turno",
          class:
            "overline font-weight-bold blue-grey--text  min-width-per-column",
        },
        {
          value: "EntradaReal",
          text: "EntradaReal",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "SalidaReal",
          text: "SalidaReal",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Hora_Entrada",
          text: "Hora_Entrada",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Hora_Salida",
          text: "Hora_Salida",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Ingreso_Americas",
          text: "Ingreso_Americas",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Salida_Americas",
          text: "Salida_Americas",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Ingreso_Planta",
          text: "Ingreso_Planta",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Salida_Planta",
          text: "Salida_Planta",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Vacaciones",
          text: "Vacaciones",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "SalidaAnticipada",
          text: "SalidaAnticipada",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "TxT",
          text: "TxT",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Permiso",
          text: "Permiso",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "LlegadaTarde",
          text: "LlegadaTarde",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "FlexTime",
          text: "FlexTime",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "HomeOffice",
          text: "HomeOffice",
          class: "overline font-weight-bold blue-grey--text",
        },
        {
          value: "Teletrabajo",
          text: "Teletrabajo",
          class: "overline font-weight-bold blue-grey--text",
        },
      ],
      headers_report_to_export: {
        EMPLID: {title: "EMPLID"},
        NAME: {title: "NOMBRE"},
        DEPTID: {title: "AREAID"},
        EMP_KEYDEP: {title: "DEPTID"},
        grupo_Trabajo: {title: "GRUPO DE TRABAJO"},
        COMP_FREQUENCY: {title: "TIPO DE PAGO"},
        Area: {title: "AREA"},
        FECHA: {title: "FECHA"},
        fecha_checador_hikcentral: {title: "fecha_checador_hikcentral"},
        IdTurno: {title: "IDTURNO"},
        Descripcion: {title: "TURNO"},
        EntradaReal: {title: "EntradaReal"},
        SalidaReal: {title: "SalidaReal"},
        Hora_Entrada: {title: "Hora_Entrada"},
        Hora_Salida: {title: "Hora_Salida"},
        Ingreso_Americas: {title: "Ingreso_Americas"},
        Salida_Americas: {title: "Salida_Americas"},
        Ingreso_Planta: {title: "Ingreso_Planta"},
        Salida_Planta: {title: "Salida_Planta"},
        Vacaciones: {title: "Vacaciones"},
        SalidaAnticipada: {title: "SalidaAnticipada"},
        TxT: {title: "TxT"},
        Permiso: {title: "Permiso"},
        LlegadaTarde: {title: "LlegadaTarde"},
        FlexTime: {title: "FlexTime"},
        HomeOffice: {title: "HomeOffice"},
        Teletrabajo: {title: "Teletrabajo"},
      },
    };
  },
};
</script>

<style>
.min-width-per-column {
  min-width: 250px !important;
}
</style>
