<template>
  <div>
    <div v-if="[{}, null, undefined].includes(InfoDay)">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon center v-bind="attrs" v-on="on">
            mdi-information-off-outline
          </v-icon>
        </template>
        <span>Sin información</span>
      </v-tooltip>
    </div>
    <div v-else-if="InfoDay.festivo">
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon center v-bind="attrs" v-on="on">
            mdi-party-popper
          </v-icon>
        </template>
        <span>Día festivo</span>
      </v-tooltip>
    </div>
    <div v-else-if="InfoDay.falta === 1 && !InfoDay.festivo">
      <!-- falta -->
      <v-menu top :offset-x="true" :close-on-content-click="false" dense rounded="lg" transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed text class="font-weight-bold blue-grey--text text--darken-3" v-bind="attrs" v-on="on"
            @click="
              loadingChecadas = true;
            fechaEnTurno = setFormatDate(InfoDay.fecha)
            getChecadas(empleado, InfoDay.fecha);
            ">
            falta
          </v-btn>
        </template>
        <v-card max-width="100%" min-height="50" class="center-item-in-div" v-if="loadingChecadas">
          <v-progress-circular indeterminate :size="30" :width="3" color="primary">
          </v-progress-circular>
        </v-card>
        <v-card v-else max-width="100%">
          <v-card-title class="primary white--text subtitle-2">
            Registro de checadas: {{ setFormatDate(InfoDay.fecha) }}
          </v-card-title>
          <v-card-text v-if="typeof checadas === 'object' && checadas.checadasBiometricos != undefined">
            <!-- {{ InfoDay }} -->
            <div v-if="checadas.checadasBiometricos.datos.length > 0">
              <label class="overline caption">Biométricos</label>
              <div v-for="(checada, index) in checadas.checadasBiometricos.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.fechaHora) }}</strong>
                  ({{ checada.nombreDispositivo }})
                </div>
              </div>
            </div>
            <div v-if="checadas.checadasLectores.datos.length > 0">
              <label class="overline caption">Tarjetas</label>
              <div v-for="(checada, index) in checadas.checadasLectores.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.dtEventReal) }}</strong>
                  ({{ checada.tDesc }})
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn small depressed class="rounded-pill mr-1" color="teal darken-3" outlined @click="
              tipoChecada = 'in';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-up </v-icon>
              agregar entrada
            </v-btn>
            <v-btn small depressed class="rounded-pill ml-1" color="cyan darken-3" outlined @click="
              tipoChecada = 'out';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-down </v-icon>
              agregar salida
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>

    <div v-else-if="Object.keys(InfoDay.incidencia).length === 0 && InfoDay.labora">
      <v-menu top :offset-x="true" :close-on-content-click="false" dense rounded="lg" transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed :text="!validaChecadaUnica(InfoDay.ingreso, InfoDay.salida)
            ? true
            : false
            " :color="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida)
              ? 'yellow lighten-3'
              : ''
              " :dark="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida) ? true : false
                " class="font-weight-bold blue-grey--text text--darken-3" v-bind="attrs" v-on="on" @click="
                  loadingChecadas = true;
                fechaEnTurno = setFormatDate(InfoDay.fecha)
                getChecadas(empleado, InfoDay.fecha);
                ">
            {{ InfoDay.ingreso }} - {{ InfoDay.salida }}
          </v-btn>
        </template>
        <v-card max-width="100%" min-height="50" class="center-item-in-div" v-if="loadingChecadas">
          <v-progress-circular indeterminate :size="30" :width="3" color="primary">
          </v-progress-circular>
        </v-card>
        <v-card v-else max-width="100%">
          <v-card-title class="primary white--text subtitle-2">
            Registro de checadas: {{ setFormatDate(InfoDay.fecha) }}
          </v-card-title>
          <v-card-text v-if="typeof checadas === 'object' && checadas.checadasBiometricos != undefined">
            <!-- {{ InfoDay }} -->
            <div v-if="checadas.checadasBiometricos.datos.length > 0">
              <label class="overline caption">Biométricos</label>
              <div v-for="(checada, index) in checadas.checadasBiometricos.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.fechaHora) }}</strong>
                  ({{ checada.nombreDispositivo }})
                </div>
              </div>
            </div>
            <div v-if="checadas.checadasLectores.datos.length > 0">
              <label class="overline caption">Tarjetas</label>
              <div v-for="(checada, index) in checadas.checadasLectores.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.dtEventReal) }}</strong>
                  ({{ checada.tDesc }})
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn small depressed class="rounded-pill mr-1" color="teal darken-3" outlined @click="
              tipoChecada = 'in';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-up </v-icon>
              agregar entrada
            </v-btn>
            <v-btn small depressed class="rounded-pill ml-1" color="cyan darken-3" outlined @click="
              tipoChecada = 'out';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-down </v-icon>
              agregar salida
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>

    <div v-else-if="Object.keys(InfoDay.incidencia).length > 0 && InfoDay.labora">
      <v-menu top :offset-x="true" :close-on-content-click="false" dense rounded="lg" transition="slide-x-transition">
        <!-- Activador del v-menu -->
        <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
          <!-- Tooltip envolviendo el botón -->
          <v-tooltip top>
            <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
              <v-btn small depressed v-bind="{ ...menuAttrs, ...tooltipAttrs }" v-on="{ ...menuOn, ...tooltipOn }"
                :text="!validaChecadaUnica(InfoDay.ingreso, InfoDay.salida)" :color="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida) && InfoDay.incidencia.desc === 'Llegada tarde'
                  ? 'yellow lighten-3'
                  : ''"
                :dark="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida) && InfoDay.incidencia.desc === 'Llegada tarde'"
                class="font-weight-bold blue-grey--text text--darken-3" @click="
                  loadingChecadas = true;
                fechaEnTurno = setFormatDate(InfoDay.fecha);
                getChecadas(empleado, InfoDay.fecha);
                ">
                <!-- Íconos dinámicos -->
                <v-icon v-if="InfoDay.incidencia.desc === 'Incapacidad'">mdi-hospital-box</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Tiempo por tiempo'">mdi-history</v-icon>
                <div v-else-if="InfoDay.incidencia.desc === 'Salida anticipada'">
                  {{ InfoDay.ingreso }}
                  <v-icon>mdi-door-open</v-icon>
                  {{ InfoDay.salida }}
                </div>
                <div v-else-if="InfoDay.incidencia.desc === 'Llegada tarde'">
                  {{ InfoDay.ingreso }}
                  <v-icon>mdi-clock</v-icon>
                  {{ InfoDay.salida }}
                </div>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Permiso especial'">mdi-account-child-circle</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Vacaciones'">mdi-beach</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Home office'">mdi-home</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Flex time'">mdi-timer-sync-outline</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Permuta'">mdi-briefcase-arrow-up-down</v-icon>
                <v-icon v-else-if="InfoDay.incidencia.desc === 'Teletrabajo'">mdi-home-account</v-icon>
              </v-btn>
            </template>
            <span>{{ InfoDay.incidencia.desc }}</span>
          </v-tooltip>
        </template>

        <!-- Contenido del menú -->
        <v-card max-width="100%" min-height="50" class="center-item-in-div" v-if="loadingChecadas">
          <v-progress-circular indeterminate :size="30" :width="3" color="primary"></v-progress-circular>
        </v-card>
        <v-card v-else max-width="100%">
          <v-card-title class="primary white--text subtitle-2">
            Registro de checadas: {{ setFormatDate(InfoDay.fecha) }}
          </v-card-title>
          <v-card-text v-if="typeof checadas === 'object' && checadas.checadasBiometricos">
            <div v-if="checadas.checadasBiometricos.datos.length > 0">
              <label class="overline caption">Biométricos</label>
              <div v-for="(checada, index) in checadas.checadasBiometricos.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.fechaHora) }}</strong> ({{ checada.nombreDispositivo }})
                </div>
              </div>
            </div>
            <div v-if="checadas.checadasLectores.datos.length > 0">
              <label class="overline caption">Tarjetas</label>
              <div v-for="(checada, index) in checadas.checadasLectores.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.dtEventReal) }}</strong> ({{ checada.tDesc }})
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn small depressed class="rounded-pill mr-1" color="teal darken-3" outlined @click="
              tipoChecada = 'in';
            openDatePicker = true;
            ">
              <v-icon left>mdi-arrow-collapse-up</v-icon>
              agregar entrada
            </v-btn>
            <v-btn small depressed class="rounded-pill ml-1" color="cyan darken-3" outlined @click="
              tipoChecada = 'out';
            openDatePicker = true;
            ">
              <v-icon left>mdi-arrow-collapse-down</v-icon>
              agregar salida
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>

    </div>

    <div v-else-if="!InfoDay.labora && ![null, undefined, '', '00:00:00'].includes(InfoDay.ingreso)">
      <v-menu top :offset-x="true" :close-on-content-click="false" dense rounded="lg" transition="slide-x-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn small depressed :text="!validaChecadaUnica(InfoDay.ingreso, InfoDay.salida)
            ? true
            : false
            " :color="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida)
              ? 'yellow lighten-3'
              : ''
              " :dark="validaChecadaUnica(InfoDay.ingreso, InfoDay.salida) ? true : false
                " class="font-weight-bold blue-grey--text text--darken-3" v-bind="attrs" v-on="on" @click="
                  loadingChecadas = true;
                fechaEnTurno = setFormatDate(InfoDay.fecha)
                getChecadas(empleado, InfoDay.fecha);
                ">
            {{ InfoDay.ingreso }} - {{ InfoDay.salida }}
          </v-btn>
        </template>
        <v-card max-width="100%" min-height="50" class="center-item-in-div" v-if="loadingChecadas">
          <v-progress-circular indeterminate :size="30" :width="3" color="primary">
          </v-progress-circular>
        </v-card>
        <v-card v-else max-width="100%">
          <v-card-title class="primary white--text subtitle-2">
            Registro de checadas: {{ setFormatDate(InfoDay.fecha) }}
          </v-card-title>
          <v-card-text v-if="typeof checadas === 'object' && checadas.checadasBiometricos != undefined">
            <!-- {{ InfoDay }} -->
            <div v-if="checadas.checadasBiometricos.datos.length > 0">
              <label class="overline caption">Biométricos</label>
              <div v-for="(checada, index) in checadas.checadasBiometricos.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.fechaHora) }}</strong>
                  ({{ checada.nombreDispositivo }})
                </div>
              </div>
            </div>
            <div v-if="checadas.checadasLectores.datos.length > 0">
              <label class="overline caption">Tarjetas</label>
              <div v-for="(checada, index) in checadas.checadasLectores.datos" :key="index">
                <div class="caption">
                  <strong>{{ formatDateHour(checada.dtEventReal) }}</strong>
                  ({{ checada.tDesc }})
                </div>
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-btn small depressed class="rounded-pill mr-1" color="teal darken-3" outlined @click="
              tipoChecada = 'in';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-up </v-icon>
              agregar entrada
            </v-btn>
            <v-btn small depressed class="rounded-pill ml-1" color="cyan darken-3" outlined @click="
              tipoChecada = 'out';
            openDatePicker = true;
            ">
              <v-icon left> mdi-arrow-collapse-down </v-icon>
              agregar salida
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
    </div>


    <div v-else-if="!InfoDay.labora">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon center v-bind="attrs" v-on="on">
            mdi-coffee
          </v-icon>
        </template>
        <span>Descansa</span>
      </v-tooltip>
    </div>
    <div v-else>
      <v-tooltip right>
        <template v-slot:activator="{ on, attrs }">
          <v-icon center v-bind="attrs" v-on="on">
            mdi-information-off-outline
          </v-icon>
        </template>
        <span>Sin información</span>
      </v-tooltip>
    </div>

    <DatePickerDialog v-if="openDatePicker" :openDatePicker="openDatePicker" @closeDatePicker="closeDatePicker" />
    <MuestraDetalleIncidencia v-if="muestraDetalleIncidencia" muestraDetalleIncidencia="muestraDetalleIncidencia"
      :idIncidencia="idIncidencia" @cierraDetalle="muestraDetalleIncidencia = false" />
  </div>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../store/Funciones/funciones";
import DatePickerDialog from "./DatePickerDialog.vue";
import MuestraDetalleIncidencia from "../../views/generated/RelacionesLaborales/Reportes/ReporteIncidenciasFechas/components/MuestraDetalleIncidencia.vue";

export default {
  name: "reporte-asistencias-informacion-de-dia-comite",
  components: { DatePickerDialog, MuestraDetalleIncidencia },
  data: () => ({
    loadingChecadas: false,
    openDatePicker: false,
    empleadoEnTurno: false,
    loadingDetails: false,
    muestraDetalleIncidencia: false,
    fechaEnTurno: "",
    tipoChecada: 0,
    idIncidencia: 0,
    checadas: [],
  }),
  props: ["InfoDay", "empleado"],
  mounted() { },
  methods: {
    getStatus(item) {
      if (
        item.inc == 0 &&
        item.entrada == null &&
        item.entrada == null &&
        item.labora
      ) {
        return "falta";
      } else {
        if (item.length == 0) {
          return "n-i";
        } else if (item.inc != 0) {
          return item.inc;
        } else if (!item.labora && item.entrada == null) {
          return "n-l";
        } else {
          return "no inc";
        }
      }
    },
    getChecadas(empleado, fecha) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      axios
        .get(
          `${this.$store.getters.getHostNode
          }/api/get-detalles-checadas-empleado/${this.setFormatDate(
            fecha
          )}/${empleado}`,
          { headers }
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.loadingChecadas = false;
            this.checadas = response.data.data;
          }
        });
    },
    formatDateHour(fechaHora) {
      var fechaObjeto = new Date(fechaHora);
      var hora = fechaObjeto.getUTCHours();
      var minutos = fechaObjeto.getUTCMinutes();
      var segundos = fechaObjeto.getUTCSeconds();

      var horaFormateada = (hora < 10 ? "0" : "") + hora;
      var minutosFormateados = (minutos < 10 ? "0" : "") + minutos;
      var segundosFormateados = (segundos < 10 ? "0" : "") + segundos;

      var horaCompleta =
        horaFormateada + ":" + minutosFormateados + ":" + segundosFormateados;

      return horaCompleta;
    },
    closeDatePicker(time) {
      if (time === null) {
        this.openDatePicker = false;
      } else {
        if (this.tipoChecada == "in") {
          this.saveNuevaEntrada(time);
        } else if (this.tipoChecada == "out") {
          this.saveNuevaSalida(time);
        }

        this.openDatePicker = false;
      }
    },
    saveNuevaSalida(time) {
      const params = {
        Opcion: 6,
        fecha: this.setFormatDate(this.fechaEnTurno),
        nuevaHora: time,
        empleado: this.empleado,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            alert("Expiró la sesión.");
            deleteToken()
          } else {
            this.$emit("reloadData");
          }
        });
    },
    saveNuevaEntrada(time) {
      const params = {
        Opcion: 9,
        fecha: this.setFormatDate(this.fechaEnTurno),
        nuevaHora: time,
        empleado: this.empleado,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            alert("Expiró la sesión.");
            deleteToken()
          } else {
            this.$emit("reloadData");
          }
        });
    },
    validaChecadaUnica(entrada, salida) {
      if (entrada == salida) {
        return true;
      }
      return false;
    },
    getDetails(day) {
      const params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        fecha: this.setFormatDate(day.fecha),
        empleado: this.empleado,
        tipoIncidencia: this.getTipoIncidencia(day.incidencia.desc),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/RelacionEmpleadoResponsable/ws-empleado-responsable.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPSESSION") {
            deleteToken();
          } else {
            this.loadingDetails = false;
            if (
              response.data.respuesta > 0 &&
              response.data.respuesta != null
            ) {
              this.idIncidencia = response.data.respuesta;
              this.muestraDetalleIncidencia = true;
            } else {
              alert(
                "Error. No se pudo cargar detalle de incidencia. Id:" +
                response.data.respuesta
              );
            }
          }
        });
    },
    getTipoIncidencia(incidenciaDesc) {
      // let opcion = incidenciaDesc.toLowerCase();
      if (incidenciaDesc === "Permiso especial") {
        return 4;
      } else if (incidenciaDesc === "Salida anticipada") {
        return 2;
      } else if (incidenciaDesc === "Tiempo por tiempo") {
        return 3;
      } else if (incidenciaDesc === "Vacaciones") {
        return 1;
      } else if (incidenciaDesc === "Llegada tarde") {
        return 5;
      } else if (incidenciaDesc === "Flex time") {
        return 6;
      } else if (incidenciaDesc === "Home office") {
        return 7;
      } else if (incidenciaDesc === "Teletrabajo") {
        return 11;
      } else {
        return 0;
      }
    },

    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return anioUTC + "-" + mesUTC + "-" + diaUTC;
    },
  },
};
</script>
