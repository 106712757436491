<template>
    <div>
        <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
            :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>
 
        <v-card>
            <v-row>
                <v-col cols="12" sm="3" md="3">
                    <!-- Sección para crear encuesta -->
                    <v-card class="text-center">
                        <v-toolbar color="primary" dark dense elevation="19" flat outlined rounded shaped>
                            <v-spacer></v-spacer>
                            <v-toolbar-title>¡Crea tu encuesta!</v-toolbar-title>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card color="grey lighten-5" class="pa-2">
                            <v-col cols="12">
                                <v-btn color="primary" small class="font-weight-bold rounded-lg" @click="saveEncuesta">
                                    <v-icon left small>mdi-content-save</v-icon> Guardar Encuesta
                                </v-btn>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="primary" small class="font-weight-bold rounded-lg" @click="Clean">
                                    <v-icon left small>mdi-monitor-shimmer</v-icon> Limpiar encuesta
                                </v-btn>
                            </v-col>
                        </v-card>

                        <v-divider></v-divider>

                        <v-card color="grey lighten-5">
                            <v-toolbar color="primary" dark dense outlined rounded>
                                <v-spacer></v-spacer>
                                <v-toolbar-title>Preguntas</v-toolbar-title>
                                <v-spacer></v-spacer>
                            </v-toolbar>
                            <v-col cols="12">
                                <v-btn color="primary" class="font-weight-bold rounded-lg" small @click="dialog = true">
                                    Agregar Preguntas
                                </v-btn>
                            </v-col>
                        </v-card>

                        <v-dialog v-model="dialog" max-width="600px">
                            <v-card>
                                <v-toolbar color="primary" dark>
                                    Agregar Pregunta
                                </v-toolbar>
                                <v-card-text>
                                    <v-select v-model="preguntaSeleccionada" :items="tiposDePregunta"
                                        label="Tipo de pregunta" outlined dense class="mt-4"></v-select>

                                    <v-text-field v-model="preguntaTexto" label="Escribe tu pregunta" outlined
                                        dense></v-text-field>

                                    <v-row
                                        v-if="preguntaSeleccionada === 'Cerrada' || preguntaSeleccionada === 'Opción Múltiple'">
                                        <v-col v-for="(opcion, index) in opciones" :key="index" cols="12" sm="6" md="4">
                                            <v-text-field v-model="opciones[index]" :label="'Opción ' + (index + 1)"
                                                outlined dense></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-btn small @click="agregarOpcion" class="mt-4" color="primary">Agregar
                                        Opción</v-btn>
                                </v-card-text>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="teal" dark elevation="2" @click="agregarPregunta">
                                        Agregar Pregunta
                                    </v-btn>
                                    <v-btn color="error" elevation="2" @click="dialog = false">
                                        Cerrar
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-card>
                </v-col>

                <v-col cols="12" sm="9" md="9">
                    <v-row justify="center">
                        <v-col cols="12">
                            <v-card color="grey lighten-5" class="ma-2">
                                <v-toolbar color="primary" dark dense flat outlined rounded shaped>
                                    <v-spacer></v-spacer>
                                    <v-toolbar-title>Encuesta</v-toolbar-title>
                                    <v-spacer></v-spacer>
                                </v-toolbar>

                                <v-row justify="center">
                                    <v-col cols="12" sm="8" md="18" class="d-flex align-center mt-4">
                                        <v-text-field v-model="tituloencuesta" label="Nombre de la encuesta" outlined
                                            dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row justify="center">
                                    <v-col cols="12" sm="8" md="8" class="d-flex align-center">
                                        <v-text-field v-model="descencuesta" label="Descripción de la encuesta" outlined
                                            dense></v-text-field>
                                    </v-col>
                                </v-row>

                                <div v-for="pregunta in preguntas" :key="pregunta.id">
                                    <v-col cols="12" sm="9" md="9" class="d-flex align-center">
                                        <h3>{{ pregunta.id }}. {{ pregunta.texto }} ({{ pregunta.tipo }})</h3>
                                    </v-col>

                                    <v-col v-if="pregunta.opciones.length > 0" cols="12">
                                        <v-row justify="center">
                                            <v-col v-for="(opcion, index) in pregunta.opciones" :key="index"
                                                cols="auto">
                                                <v-radio v-if="pregunta.tipo === 'Cerrada'" :label="opcion"
                                                    disabled></v-radio>
                                                <v-checkbox v-else :label="opcion" disabled></v-checkbox>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </div>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-dialog v-model="mostrarCargarUsuarios" max-width="700" scrollable persistent
                content-class="fix-border-dialog">
                <v-card class="rounded-xl">
                    <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                        Cargar Usuarios
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="mostrarCargarUsuarios = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>

                            <v-col cols="12">
                                <v-radio-group v-model="tipoCarga" row>
                                    <v-radio label="Cargar por Excel" value="excel"></v-radio>
                                    <v-radio label="Cargar por Criterio" value="criterio"
                                        @click="loadCriterios()"></v-radio>
                                </v-radio-group>
                            </v-col>

                            <v-col v-if="tipoCarga === 'excel'" cols="12" sm="8" md="8">
                                <v-file-input accept=".xlsx, .xls, .csv" placeholder="Selecciona el archivo"
                                    prepend-icon="mdi-microsoft-excel" v-model="file"></v-file-input>

                                <v-btn color="primary" class="mt-2" @click="iniciarCargaArchivo" :disabled="!file">
                                    Cargar Archivo
                                </v-btn>
                            </v-col>


                            <v-col v-if="tipoCarga === 'criterio'" cols="12">
                                <!-- <v-select v-model="criterioSeleccionado" :items="criterios" label="Seleccionar Criterio"
                                    outlined dense></v-select> -->
                                <v-autocomplete v-model="opcionesCriterio[0].valorSeleccionado"
                                    :items="opcionesCriterio[0].datos" label="Seleccionar Área" item-text="text"
                                    item-value="value" outlined dense @change="loadDepartamentosPorArea" />

                                <v-autocomplete v-model="opcionesCriterio[1].valorSeleccionado"
                                    :items="opcionesCriterio[1].datos" label="Seleccionar Departamento" item-text="text"
                                    item-value="value" outlined dense @change="loadGruposTrabajo" />

                                <!-- Selección de Grupos de Trabajo -->
                                <v-autocomplete v-model="opcionesCriterio[2].valorSeleccionado"
                                    :items="opcionesCriterio[2].datos" label="Seleccionar Grupo de Trabajo"
                                    item-text="text" item-value="value" outlined dense />


                                <v-btn color="primary" class="mt-2 mr-2" @click="cargarPorCriterio"
                                   >
                                    Cargar Usuarios
                                </v-btn>

                                <v-btn color="success" class="mt-2" @click="verUsuarios">
                                    Ver Usuarios
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>

            <!-- Nuevo diálogo para mostrar usuarios -->
            <v-dialog v-model="mostrarUsuariosDialog" max-width="800px" scrollable>
                <v-card>
                    <v-card-title>
                        <span class="headline">Lista de Usuarios</span>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="mostrarUsuariosDialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>

                    <v-card-text>
                        <v-data-table :headers="userHeaders" :items="listaUsuarios" class="elevation-1" dense>
                            <template v-slot:item.index="{ index }">
                                <span>{{ index + 1 }}</span>
                            </template>
                        </v-data-table>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="error" @click="mostrarUsuariosDialog = false">Cerrar</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

        </v-card>
    </div>
</template>

<script>
import axios from 'axios';
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';

export default {
    components: {
        SnackBarErrorIncidencias,
    },
    data() {
        return {
            dialog: false,
            mostrarUsuariosDialog: false,
            listaUsuarios: [],
            idEncuesta: '',
            mostrarCargarUsuarios: false,
            tituloencuesta: '',
            descencuesta: '',
            preguntaSeleccionada: null,
            preguntaTexto: '',
            opciones: [],
            preguntas: [],
            file: null,
            tiposDePregunta: ['Abierta', 'Cerrada', 'Opción Múltiple'],
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            tipoCarga: 'excel',
            criterioSeleccionado: null,
            userHeaders: [
                { text: '#', value: 'index' },
                { text: 'Folio', value: 'folio' },
                { text: 'Nombre', value: 'nombre' },
            ],
            criterios: ['Área', 'Departamento', 'Grupo de Trabajo'],
            opcionesCriterio: [
                {
                    valorSeleccionado: null,
                    descripcion: 'Area',
                    datos: [],
                },
                {
                    valorSeleccionado: null,
                    descripcion: 'Departamento',
                    datos: [],
                },
                {
                    valorSeleccionado: null,
                    descripcion: 'Grupo de Trabajo',
                    datos: [],
                },

            ],
            opcionSeleccionada: null,
        };
    },
    methods: {
        async verUsuarios() {
            try {
                const area = this.opcionesCriterio[0].valorSeleccionado;
                const departamento = this.opcionesCriterio[1].valorSeleccionado;
                const grupoTrabajo = this.opcionesCriterio[2].valorSeleccionado;

                let tipo = '';
                if (area && !departamento && !grupoTrabajo) {
                    tipo = 'area';
                } else if (area && departamento && !grupoTrabajo) {
                    tipo = 'departamento';
                } else if (area && departamento && grupoTrabajo) {
                    tipo = 'grupotrabajo';
                }

                const response = await axios.get(
                    `${this.$store.getters.getHostNode}/api/getUsuariosPorCriterio/${area}/${departamento}/${grupoTrabajo}/${tipo}`,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );
                if (response.data && Array.isArray(response.data.data)) {
                    this.listaUsuarios = response.data.data.map((usuario, index) => ({
                        index: index + 1,
                        folio: usuario.EMPLID,
                        nombre: usuario.EMP_NOMEMP,
                    }));
                } else {
                    throw new Error('La respuesta de la API no es válida o no contiene datos');
                }

                this.mostrarUsuariosDialog = true;
            } catch (error) {
                console.error('Error al obtener usuarios:', error);
                this.mostrarSnackBar('Error al obtener usuarios', 'red accent-2');
            }
        },
        mostrarSnackBar(mensaje, color) {
            this.textoBarra = mensaje;
            this.colorBarra = color;
            this.muestraBarra = true;
        },
        async loadCriterios() {
            try {

                const responseArea = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosArea`, {
                    headers: { Authorization: localStorage.getItem("token") }
                });
                this.opcionesCriterio[0].datos = responseArea.data.data.map(area => ({
                    value: area.UR_AREA_DEPT,
                    text: area.DESCR
                }));

            } catch (error) {
                console.error('Error al cargar criterios:', error);
                this.mostrarSnackBar('Error al cargar criterios', 'red accent-2');
            }
        },
        async loadDepartamentosPorArea() {
            const areaSeleccionada = this.opcionesCriterio[0].valorSeleccionado;
            if (!areaSeleccionada) return;

            try {
                const responseDept = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosDepartamento/${areaSeleccionada}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                this.opcionesCriterio[1].datos = responseDept.data.data.map(dept => ({
                    value: dept.DEPTID,
                    text: dept.DESCR
                }));


            } catch (error) {
                console.error('Error al cargar departamentos:', error);
                this.mostrarSnackBar('Error al cargar departamentos', 'red accent-2');
            }
        },

       
        async loadGruposTrabajo() {
            const areaSeleccionada = this.opcionesCriterio[0].valorSeleccionado;
            const deptSeleccionado = this.opcionesCriterio[1].valorSeleccionado;

            if (!areaSeleccionada || !deptSeleccionado) return;

            try {
                const responseGrupo = await axios.get(`${this.$store.getters.getHostNode}/api/getEmpleadosGruposTrabajo/${areaSeleccionada}/${deptSeleccionado}`, {
                    headers: { Authorization: localStorage.getItem("token") },
                });

                this.opcionesCriterio[2].datos = responseGrupo.data.data.map(grupo => ({
                    value: grupo.grupo_Trabajo,
                    text: grupo.grupo_Trabajo
                }));


            } catch (error) {
                console.error('Error al cargar grupos de trabajo:', error);
                this.mostrarSnackBar('Error al cargar grupos de trabajo', 'red accent-2');
            }
        },


        iniciarCargaArchivo() {
            if (!this.file) {
                this.mostrarSnackBar('Por favor, selecciona un archivo', 'red accent-2');
                return;
            }
            this.handleFileUpload(this.file);
            this.mostrarSnackBar('Archivo cargado correctamente', 'green');

        },
        async cargarPorCriterio() {
           
            try {
                const area = this.opcionesCriterio[0].valorSeleccionado;
                const departamento = this.opcionesCriterio[1].valorSeleccionado;
                const grupoTrabajo = this.opcionesCriterio[2].valorSeleccionado;

                let tipo = '';
                if (area && !departamento && !grupoTrabajo) {
                    tipo = 'area';
                } else if (area && departamento && !grupoTrabajo) {
                    tipo = 'departamento';
                } else if (area && departamento && grupoTrabajo) {
                    tipo = 'grupotrabajo';
                }

             
                const data = {
                    area: area || '',
                    departamento: departamento || '',
                    grupoTrabajo: grupoTrabajo || '',
                    tipo: tipo,
                    idEncuesta: this.idEncuesta
                };

           
                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/cargarUsuariosCriterio`,
                    data,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

              
                if (response.data && response.data.status) {
                    this.mostrarSnackBar('Usuarios cargados correctamente', 'green');
                } else {
                    throw new Error('La carga de usuarios falló');
                }
            } catch (error) {
                console.error('Error al cargar usuarios:', error);
                this.mostrarSnackBar('Error al cargar usuarios', 'red accent-2');
            }
           
        },
        agregarOpcion() {
            this.opciones.push('');
        },
        agregarPregunta() {
            if (this.validarPregunta()) {
                const nuevaPregunta = {
                    id: this.preguntas.length + 1,
                    tipo: this.preguntaSeleccionada,
                    texto: this.preguntaTexto,
                    opciones: this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opción Múltiple' ? [...this.opciones] : [],
                };
                this.preguntas.push(nuevaPregunta);
                this.limpiarFormulario();
                this.mostrarSnackBar('Pregunta agregada correctamente', 'green');
            } else {
                this.mostrarSnackBar('Campos incompletos para agregar la pregunta', 'red accent-2');
            }
        },
        limpiarFormulario() {
            this.preguntaSeleccionada = null;
            this.preguntaTexto = '';
            this.opciones = [];
        },
        validarPregunta() {
            if (!this.preguntaTexto.trim()) {
                return false;
            }
            if (this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opción Múltiple') {
                if (this.opciones.length < 2) {
                    return false;
                }
                for (const opcion of this.opciones) {
                    if (!opcion.trim()) {
                        return false;
                    }
                }
            }
            return true;
        },
        async saveEncuesta() {
            if (!this.tituloencuesta.trim()) {
                this.mostrarSnackBar('El título no puede estar vacío', 'red accent-2');
                return;
            }
            if (!this.descencuesta.trim()) {
                this.mostrarSnackBar('La descripción no puede estar vacía', 'red accent-2');
                return;
            }
            if (!this.preguntas.length) {
                this.mostrarSnackBar('Debe agregar al menos una pregunta', 'red accent-2');
                return;
            }
            try {
                const data = {
                    titulo: this.tituloencuesta,
                    descripcion: this.descencuesta,
                    preguntas: this.preguntas,
                    usuario: this.$store.getters.getUser.Folio,
                };

                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/guardarEncuesta`,
                    data,
                    {
                        headers: {
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data.status) {
                    this.mostrarSnackBar('Encuesta guardada correctamente', 'green');
                    this.idEncuesta = response.data.data;
                    this.mostrarCargarUsuarios = true;
                    this.Clean();
                } else {
                    this.mostrarSnackBar('Error al guardar la encuesta', 'red accent-2');
                }
            } catch (error) {
                console.error('Error al guardar la encuesta:', error);
                this.mostrarSnackBar('Error de servidor al guardar la encuesta', 'red accent-2');
            }
        },
        Clean() {
            this.tituloencuesta = '';
            this.descencuesta = '';
            this.preguntas = [];
        },
        async handleFileUpload(event) {
            this.file = event;
            if (!this.file) return;

            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('idEncuesta', this.idEncuesta);

            try {
                const response = await axios.post(
                    `${this.$store.getters.getHostNode}/api/cargarUsuariosCsv`,
                    formData,
                    {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                            Authorization: localStorage.getItem("token"),
                        },
                    }
                );

                if (response.data.status === 'OK') {
                    this.mostrarSnackBar('Archivo procesado correctamente', 'green');
                    this.mostrarCargarUsuarios = false;
                } else {
                    this.mostrarSnackBar('Error al procesar el archivo', 'red accent-2');
                }
            } catch (error) {
                console.error('Error de servidor al cargar el archivo:', error);
                this.mostrarSnackBar('Error de servidor: ' + error.message, 'red accent-2');
            } finally {
                this.file = null; 
            }
        },
    },
};
</script>