import MuestraPadrinos from "./components/MuestraPadrinos.vue"
export default {
    name: 'padrinos-urrea-admon',
    components: { MuestraPadrinos },
    props: [],
    data() {
        return {

        }
    },
    computed: {

    },
    mounted() {

    },
    methods: {

    }
}