<template>
  <div>
    <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
    :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>

    <v-data-table :headers="headerReporteEncuestas" :items="encuestas" dense no-data-text="No hay encuestas"
      :search="search" :loading="loading"
      :footer-props="{ 'items-per-page-text': 'Elementos por página', 'pageText': '{0}-{1} de {2}' }">
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between; align-items: center;"
          class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
          <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
            append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
            class="mx-4 rounded-lg"></v-text-field>
        </div>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-chip-group column>
          <v-btn x-small rounded elevation="1" color="teal darken-1" dark @click="EstadoEncuesta(item)">
            <v-icon>mdi-swap-horizontal</v-icon>
          </v-btn>
          <v-btn x-small rounded elevation="1" color="teal darken-1" dark @click="abrirEditorEncuesta(item)">
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-chip-group>
      </template>
    </v-data-table>

    <!-- Componente de editorEncuesta -->
    <editorEncuesta :dialogPreguntas.sync="muestraDialogEdicion"
                    @cierraDialogEdicion="muestraDialogEdicion = false"
                    :idEncuesta="selectedEncuestaId"
                    :nombre="nombreEncuesta" />

    <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
      :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>
  </div>
</template>

<script>
import axios from 'axios';
import editorEncuesta from './editorEncuesta.vue';
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';

export default {
  components: { editorEncuesta, SnackBarErrorIncidencias },
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      encuestas: [],
      muestraDialogEdicion: false,
      search: '',
      headerReporteEncuestas: [
        { text: 'ID', value: 'id' },
        { text: 'ENCUESTA', value: 'nombreEncuesta' },
        { text: 'USUARIO REGISTRO', value: 'Nombre' },
        { text: 'DESCRIPCION', value: 'Descripcion' },
        { text: 'ESTADO', value: 'Estado' },
        { text: 'FECHA DE REGISTRO', value: 'Fecha' },
        { text: 'Acciones', value: 'actions', sortable: false }
      ],
      loading: false,
      selectedEncuestaId: null,
      nombreEncuesta: ''
    };
  },
  mounted() {
    this.getEncuestas();
  },
  methods: {
    mostrarSnackBar(mensaje, color) {
            this.textoBarra = mensaje;
            this.colorBarra = color;
            this.muestraBarra = true;
        },
    abrirEditorEncuesta(item) {
      this.muestraDialogEdicion = true;
      this.selectedEncuestaId = item.id;
      this.nombreEncuesta = item.nombreEncuesta;
    },
    async EstadoEncuesta(item) {
  try {
    const response = await axios.put(
      `${this.$store.getters.getHostNode}/api/updateEstadoEncuesta/${item.id}/${item.Estado}`,
      {}, // El cuerpo está vacío
      {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      }
    );
    if (response.data.status) {
      this.getEncuestas();
    }
  } catch (error) {
    console.error('Error al actualizar el estado de la encuesta:', error);
  }
},

    async getEncuestas() {
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEncuestas`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        this.encuestas = response.data.data.map((encuesta) => ({
          id: encuesta.ID,
          nombreEncuesta: encuesta.NOMBREENCUESTA,
          Nombre: encuesta.Nombre,
          Descripcion: encuesta.DESCRIPCION,
          Estado: encuesta.ESTADO === 1 ? 'Activa' : 'Innactiva',
          Fecha: encuesta.FECHA_ACTUALIZA.split('T')[0]
        }));
      } catch (error) {
        console.error('Error al obtener encuestas:', error);
      }
    }
  }
};
</script>
