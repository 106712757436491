<template>
  <div>
    <v-row class="justify-end">
      <v-btn
        class="mb-5"
        v-if="rol && modulo == 'EscaleraCrecimiento' && depto == 'ALL'"
        color="cyan"
        dark
        :loading="loading"
        @click="nuevoNivel"
      >
        Nuevo Nivel
      </v-btn>
    </v-row>
    <div class="scroll-container-niveles">
      <div v-for="item in niveles" :key="item.id" class="scroll-items">
        <div v-for="item2 in nivelesReverse" :key="item2.id" class="item-card">
          <v-card
            class="pb-1"
            v-if="item.nivel == item2.nivel && item2.id_area"
            elevation="3"
            :style="
              nivelesReverse.length > 2 ? 'min-width:200px' : 'min-width:100px'
            "
          >
            <div class="color-bar"></div>
            <p class="my-1 px-2 text-center">
              Nivel {{ item.RowNum }}
              <v-tooltip
                v-if="rol && modulo == 'EscaleraCrecimiento'"
                top
                color="pink lighten-2"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="pink"
                    text
                    @click="confirmItem(item)"
                    v-bind="attrs"
                    v-on="on"
                    >mdi-delete-empty-outline</v-icon
                  >
                </template>
                <span> Borrar nivel</span>
              </v-tooltip>

              <v-tooltip
                v-if="modulo == 'MiPersona'"
                top
                dark
                color="primary lighten-1"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="item.EnElPuesto > 0"
                    color="primary"
                    large
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-emoticon
                  </v-icon>
                </template>
                <span> Usted está aquí </span>
              </v-tooltip>
            </p>
            <v-divider></v-divider>
            <div
              class="mx-5 px-3 mb-3 pb-2 scroll-card"
              @click="openListaPuestos(item)"
              style="cursor: pointer"
            >
              <div v-if="item.puestos" align="left">
                <li
                  v-for="(i, index) in item.puestos"
                  :key="index"
                  style="font-size: 0.8em"
                >
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="rounded-lg"
                        :color="i.color"
                        :dark="i.dark == 1 ? true : false"
                        x-small
                        v-bind="attrs"
                        v-on="on"
                      >
                        [{{ i.id_puesto }}] {{ i.puesto }}
                        {{ i.numPuestosReal }} /
                        {{ i.numPuestosPlan }}
                      </v-btn>
                    </template>
                    <span>
                      Número de puestos plan: {{ i.numPuestosPlan }} <br
                    /></span>
                    <span> Número de puestos real: {{ i.numPuestosReal }}</span>
                  </v-tooltip>
                </li>
              </div>
              <div v-else>Vacio</div>
            </div>
          </v-card>

          <v-card
            v-else-if="item.RowNum == item2.RowNum + 1"
            elevation="0"
            class="mx-5"
            color="blue-grey lighten-5"
          >
            <v-tooltip
              v-if="rol && modulo == 'EscaleraCrecimiento' && depto == 'ALL'"
              top
              color="blue lighten-2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="blue-grey lighten-5"
                  dark
                  block
                  elevation="0"
                  @click="confirmNivelBetween(item)"
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon
                    v-if="
                      $vuetify.breakpoint.name == 'xs' ||
                      $vuetify.breakpoint.name == 'sm'
                    "
                    color="primary"
                    >mdi-arrow-up</v-icon
                  >
                  <v-icon v-else color="primary">mdi-arrow-up-right</v-icon>
                </v-btn>
              </template>
              <span> Añadir nivel</span>
            </v-tooltip>
          </v-card>
          <v-card
            color="blue-grey lighten-4"
            class="py-15"
            v-else-if="item.nivel != item2.nivel && item2.id_area"
            elevation="0"
          >
          </v-card>
        </div>
      </div>
    </div>

    <lista-perfil-puestos
      v-if="openDetalles"
      :dialog="openDetalles"
      :item="item"
      :depto="depto"
      @cierraDialog="close"
    >
    </lista-perfil-puestos>
    <ConfirmDialogReclutamientoVue
      v-if="showConfirm"
      :showConfirm="showConfirm"
      :question="txtQuestion"
      @cierraConfirm="deleteItem"
    >
    </ConfirmDialogReclutamientoVue>
    <ConfirmDialogReclutamientoVue
      v-if="showConfirmAdd"
      :showConfirm="showConfirmAdd"
      :question="txtQuestion"
      @cierraConfirm="addNivelBetween"
    >
    </ConfirmDialogReclutamientoVue>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </div>
</template>

<script>
// import OrganizationChart from "vue-organization-chart";
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import ListaPerfilPuestos from "../componentsPerfilPuestos/ListaPerfilPuestos.vue";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
// import "vue-organization-chart/dist/orgchart.css";
import ConfirmDialogReclutamientoVue from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
export default {
  name: "organigrama-escaleras-crecimiento",
  props: ["niveles", "presupuesto", "presupuestoReal", "depto", "modulo"],
  components: {
    ListaPerfilPuestos,
    SnackBarErrorIncidencias,
    ConfirmDialogReclutamientoVue,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraBarra: false,

      item: [],
      openDetalles: false,

      medidaCol: 12,
      loading: false,
      maxNivel: 0,
      nivelesReverse: [],
      showConfirm: false,
      showConfirmAdd: false,
      txtQuestion: "",
      params: {},
      rol: false,
    };
  },
  created() {},
  mounted() {
    console.log(this.modulo);
    console.log(this.depto);
    this.revertirNiveles();
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.getRoles());
      }, 1500);
    });
  },
  watch: {
    niveles: function (newVal, oldVal) {
      // Lógica que se ejecuta cuando 'niveles' cambia
      console.log(newVal);
      console.log(oldVal);
      setTimeout(() => {
        this.revertirNiveles();
      }, 500);
    },
  },
  computed: {
    medidasCols() {
      if (
        this.$vuetify.breakpoint.name == "xs" ||
        this.$vuetify.breakpoint.name == "sm"
      ) {
        return 12;
      } else {
        if (this.niveles.length > 6) {
          return 1;
        } else if (this.niveles.length == 6 || this.niveles.length == 5) {
          return 2;
        } else if (this.niveles.length == 4) {
          return 3;
        } else if (this.niveles.length == 3) {
          return 4;
        } else if (this.niveles.length < 3) {
          return 6;
        }
      }
      return 12;
    },
  },
  methods: {
    getRoles() {
      let roles = "'AdmonEscalera','Champion'";
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-roles/${roles}`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.rol = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    revertirNiveles() {
      this.nivelesReverse = [...this.niveles].reverse();
      this.maxNivel = this.niveles.length;
    },

    openListaPuestos(item) {
      if (this.rol && this.modulo != 'MiPersona') {
        this.openDetalles = true;
        this.item = item;
      }
    },

    close(response) {
      this.openDetalles = false;
      if (response > 0) {
        this.$emit("cierraOrganigrama", "OK");
      }
    },

    nuevoNivel() {
      this.nivelesReverse = [];
      this.$emit("crearNivel", this.maxNivel); // Emite un evento personalizado
    },

    confirmItem(item) {
      this.params = {
        id: item,
        correo: localStorage.getItem("correo"),
      };
      this.showConfirm = true;
      this.txtQuestion =
        "¿Desea remover todo el nivel? \n Incluyendo los puestos registrados en ellas";
    },

    deleteItem(respuesta) {
      if (respuesta) {
        const headers = { Authorization: localStorage.getItem("token") };
        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/delete-puestos-por-niveles`,
              this.params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(this.$emit("cierraOrganigrama", "OK"));
                  }, 1500);
                });
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.showConfirm = false;
            });
        });
      } else {
        this.params = {};
      }
      this.showConfirm = false;
    },

    confirmNivelBetween(item) {
      this.params = {
        id: item,
        correo: localStorage.getItem("correo"),
      };
      this.showConfirmAdd = true;
      this.txtQuestion =
        "¿Estas seguro de añadir un nuevo nivel? \n " +
        "Los niveles serán reorganizados junto con sus puestos. ";
    },

    addNivelBetween(respuesta) {
      if (respuesta) {
        this.verLoadingBar = true;
        const headers = { Authorization: localStorage.getItem("token") };

        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/set-escalera-crecimiento-entre-niveles`,
              this.params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
                return new Promise((resolve) => {
                  setTimeout(() => {
                    resolve(this.$emit("cierraOrganigrama", "OK"));
                  }, 1500);
                });
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.loading = false;
              this.showConfirmAdd = false;
              this.verLoadingBar = false;
            });
        });
      } else {
        this.showConfirmAdd = false;
      }
    },
  },
};
</script>
<style scoped>
.scroll-container-niveles {
  display: flex;
  flex-wrap: nowrap; /* Mantiene todo en una fila */
  overflow-x: auto; /* Habilita el scroll horizontal */
}

.scroll-items {
  /* display: flex; */
  flex-direction: column; /* Coloca las tarjetas en columna dentro de cada iteración */
  margin-right: 16px; /* Espaciado entre iteraciones */
}

.scroll-container-niveles::-webkit-scrollbar {
  height: 8px;
}

.scroll-container-niveles::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Color del scrollbar */
  border-radius: 10px;
}

.item-card {
  flex: 0 0 auto;
  margin-bottom: 16px;
}

.scroll-card {
  height: 70px;
  /* width: 150px; */
  overflow-y: scroll;
  overflow-x: scroll;
  background-color: #f4f4f4;
  border-radius: 10px !important;
  overflow-y: auto;
}

.color-bar {
  width: 4px; /* Ancho de la barra de color */
  background-color: #42a5f5; /* Color de la barra */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
}

.white-divider {
  background-color: white; /* Cambia "white" al color que desees */
}
</style>