<template>
  <v-card
    class="rounded-lg elevation-0"
    color="blue-grey lighten-5"
    width="100%"
  >
    <v-toolbar color="primary" dense flat class="overline font-weight-bold" dark
      >Evidencias del evento reportado folio: {{ solicitud.id }}</v-toolbar
    >
    <v-card-text class="pt-5">
      <v-row
        v-if="loadingData"
        class="fill-height"
        align-content="center"
        justify="center"
      >
        <v-col class="subtitle-2 text-center" cols="12">
          Cargando evidencias de {{ solicitud.id }}...
        </v-col>
        <v-col cols="6" class="center-item-in-div">
          <v-progress-linear
            color="primary"
            class="mb-3"
            indeterminate
            rounded
            :height="5"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <div v-else class="d-flex flex-wrap justify-start">
        <v-btn
          v-for="(evidencia, index) in evidencias"
          :key="index"
          color="blue accent-4"
          class="rounded-lg ma-2"
          depressed
          dark
          @click="
            loadingEvidence = true;
            viewFile(evidencia.id, evidencia.nombre);
          "
        >
          {{ evidencia.nombre }}
        </v-btn>
      </div>

      <div v-if="imagen != null || loadingEvidence" class="center-item-in-div">
        <div v-if="loadingEvidence">
          <v-progress-circular
            :size="70"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-img
          v-else
          :src="imagen"
          contain
          max-height="400"
          max-width="400"
        ></v-img>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  props: ["solicitud"],
  data() {
    return {
      loadingData: true,
      evidencias: [],
      imagen: null,
      loadingEvidence: false,
    };
  },
  async mounted() {
    this.evidencias = await this.getEvidencias();
  },
  methods: {
    getEvidencias() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/actas-administrativas/evidencias/${this.solicitud.id}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.datos);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    async viewFile(idEvidencia, nombreArchivo) {
      try {
        const response = await axios.get(
          `${this.$store.getters.getHostNode}/api/actas-administrativas/ver-evidencia?id=${idEvidencia}&idSolicitud=${this.solicitud.id}&filename=${nombreArchivo}`,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        const { metadata, data: base64File } = response.data;

        const iv = new Uint8Array(JSON.parse(metadata.iv));

        const key = await this.importKey(JSON.parse(metadata.key));
        const encryptedData = Uint8Array.from(atob(base64File), (c) =>
          c.charCodeAt(0)
        ).buffer;
        const decryptedArrayBuffer = await crypto.subtle.decrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          key,
          encryptedData
        );
        const decryptedBlob = new Blob([new Uint8Array(decryptedArrayBuffer)], {
          type: "image/jpeg",
        });
        const decryptedUrl = URL.createObjectURL(decryptedBlob);
        // window.open(decryptedUrl);
        this.imagen = decryptedUrl;
      } catch (error) {
        alert("Primero carga un archivo: " + error);
      } finally {
        this.loadingEvidence = false;
      }
    },
    async importKey(jwk) {
      const key = await crypto.subtle.importKey(
        "jwk",
        jwk,
        {
          name: "AES-GCM",
        },
        true,
        ["encrypt", "decrypt"]
      );
      return key;
    },
  },
};
</script>
