import axios from 'axios'
import VueJsonToCsv from 'vue-json-to-csv'
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import { formatDate } from '../../../../../store/Funciones/funciones'
import MuestraDetalleIncidencia from './components/MuestraDetalleIncidencia.vue'
export default {
    name: 'reporte-incidencias-fechas',
    components: { SnackBarErrorIncidencias, VueJsonToCsv, MuestraDetalleIncidencia },
    props: [],
    data() {
        return {
            //warning
            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,

            //Detalles 
            estatusNuevo: '',
            DetalleNombre: '',
            DetalleFecha: '',
            DetalleNumeroPersonal: '',
            DetalleEstatusDesc: '',
            DetalleEstatusActual: '',
            validaPassDialog: false,

            //dialog filtro
            textFechaDescansar: "", //v-date-picker
            fechaDescansar: false, //v-menu
            textFechaDescansarS: "",
            loadingBotonGuardar: false,
            fechaTrabajar: false,
            textFechaTrabajar: "",
            textFechaTrabajarS: "",

            //filtro
            formatoFecha1: '', //v-text-field
            formatoFecha2: '',
            dialogFiltro: false,
            fechaInicio: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            fechaFin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            idIncidenciaDetalle: 0,
            muestraDetalle: false,
            emplidFilterValue: null,
            authFilterValue: null,
            statusFilterValue: null,
            typeFilterValue: null,
            statusFilterItems: [
                { text: 'Mostrar todos', value: null },
                { text: 'Abierta', value: 'Abierta' },
                { text: 'Autorizado', value: 'Autorizado' },
                { text: 'Rechazado', value: 'Rechazado' },
                { text: 'Cancelado', value: 'Cancelado' },
                { text: 'Pagado', value: 'Pagado' },
                { text: 'ProcesoPago', value: 'ProcesoPago' },
                { text: 'Aut. Dir.Area', value: 'Aut. Dir.Area' },
                { text: 'Aut. DH', value: 'Aut. DH' },
                { text: 'Aut. DH', value: 'Aut. DH' },
            ],
            typesFilterItems: [
                { text: 'Mostrar todos', value: null },
                { text: 'Vacaciones', value: 1 },
                { text: 'Salida anticipada', value: 2 },
                { text: 'Tiempo por tiempo', value: 3 },
                { text: 'Permiso especial', value: 4 },
                { text: 'Llegada tarde', value: 5 },
                { text: 'Flex time', value: 6 },
                { text: 'Home office', value: 7 },
                { text: 'Tiempo extra', value: 8 },
                { text: 'Teletrabajo', value: 11 },
            ],

            //lista de incidencias 
            passAutoriza: '',
            idIncidencia: '',
            cargando: true,
            dialogAutorizaIncidencia: false,
            detalleIncidencia: {},
            loadingDetalle: false,
            buscar: '',
            headers1: [
                { text: 'Tipo ', align: 'start', sortable: false, value: 'TipoIncidencia', filter: this.filterByType },
                { text: 'Estatus', value: 'EstatusDesc', filter: this.filterByStatus },
                { text: 'Fecha Inicio', value: 'FechaInicio' },
                { text: 'Fecha Final', value: 'FechaFinal' },
                { text: 'N.P.', value: 'NumeroPersonal', filter: this.filterByEmplid },
                { text: 'Empleado/Departamento ', value: 'Nombre' },
                { text: 'Fecha / Hora Autorización ', value: 'FechaAutoriza' },
                { text: 'Usuario Autoriza', value: 'UsuarioAutoriza', filter: this.filterByEAuthvalue },
                { text: 'Acciones', value: 'actions' },
            ],
            headers_report_to_export: {
                Id: { title: "ID" },
                NumeroPersonal: { title: "Emplid" },
                Nombre: { title: "Nombre" },
                IncDescripcion: { title: "Tipo incidencia" },
                EstatusDesc: { title: "Estatus" },
                FechaInicio: { title: "Fecha inicio" },
                FechaFinal: { title: "Fecha final" },
                FechaAutoriza: { title: "Fecha Autorización" },
                UsuarioAutoriza: { title: "Usuario Autoriza" },
                nombreResp: { title: "Responsable Colaborador" },
            },
            colorsTema: [{
                    color: 'yellow lighten-3',
                    tema: false
                },
                {
                    color: 'green',
                    tema: true
                },
                {
                    color: 'red',
                    tema: true
                },
                {
                    color: 'red darken-4',
                    tema: true
                },
                {
                    color: 'green darken-4',
                    tema: true
                },
                {
                    color: 'grey darken-1',
                    tema: true
                },
                {
                    color: 'yellow accent-4',
                    tema: false
                },
                {
                    color: 'blue',
                    tema: true
                },
                {
                    color: 'blue darken-4',
                    tema: true
                },
                {
                    color: 'blue darken-2',
                    tema: true
                },
                {
                    color: 'blue-grey lighten-3',
                    tema: true
                },
            ],
            TiposIncidencias: [{
                    idTipo: 1,
                    desc: "Vacaciones",
                    semanal: true,
                    icono: "mdi-beach"
                },
                {
                    idTipo: 3,
                    desc: "Tiempo por tiempo",
                    semanal: true,
                    icono: "mdi-clipboard-text-clock"
                },
                {
                    idTipo: 2,
                    desc: "Salida anticipada",
                    semanal: true,
                    icono: "mdi-door-open"
                },
                {
                    idTipo: 5,
                    desc: "Llegada tarde",
                    semanal: true,
                    icono: "mdi-timer-alert-outline"
                },
                {
                    idTipo: 4,
                    desc: "Permiso especial",
                    semanal: true,
                    icono: "mdi-account-child-circle"
                },
                {
                    idTipo: 6,
                    desc: "Flex time",
                    semanal: false,
                    icono: "mdi-arrow-decision"
                },
                {
                    idTipo: 7,
                    desc: "Home Office",
                    semanal: false,
                    icono: "mdi-home"
                },
                {
                    idTipo: 11,
                    desc: "Teletrabajo",
                    semanal: false,
                    icono: "mdi-shield-home"
                },
                {
                    idTipo: 12,
                    desc: "Viernes Corto",
                    semanal: false,
                    icono: "mdi-alpha-v-circle "
                }
            ],
            windowSize: {
                x: 0,
                y: 0,
            },
            incidencias: [],
            OpcionesAutorizacion: [
                { id: 2, mensaje: 'Autorizar' },
                { id: 3, mensaje: 'Rechazado' },
                { id: 4, mensaje: 'Cancelado' },
            ],
            estatusIncidencias: [],

            loading: false,
            // variables para buscador de colaborador

            colaboradores: [],
            colaboradoresNombre: [],
            loadingColaboradores: true,
            nombre: '',
            //
            selectAut: false,
            selectVac: false,
            selectedItems: [],
            dialogConfirmar: false,
            isUpdating: false,
            isLoading: false, // Estado de carga
        }
    },
    computed: {
        itemsPerPage() {
            return this.selectAut ? -1 : 10;
        }
    },
    mounted() {
        this.consultarIncidencias();
        this.getStatusIncedence();
        this.getColaboradores();
        //  window.document.title = "DH:URREA - Reporte incidencias.";
    },
    methods: {
        openvalidaPass(estatusEdit) {
            this.estatusNuevo = estatusEdit;
            this.validaPassDialog = true;
        },
        validarPass() {
            if (this.passAutoriza) {
                let params = {
                    Opcion: 3,
                    token: localStorage.getItem("token"),
                    passAutoriza: this.passAutoriza
                };
                axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params)
                    .then((response) => {
                        if (response.data.status == 'OK') {
                            if (response.data.sonIguales > 0) {
                                this.editarAutorizacion(this.estatusNuevo);
                            } else {
                                this.textoBarra = 'Sus credenciales no son las correctas';
                                this.colorBarra = 'error';
                                this.muestraBarra = true;
                            }
                        }

                        if (response.data.status == 'error') {
                            this.textoBarra = 'Ingrese las credenciales necesarias para continuar';
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        }
                    })
            } else {
                this.textoBarra = 'Ingrese las credenciales necesarias para continuar';
                this.colorBarra = 'primary';
                this.muestraBarra = true;
            }

        },
        sendFechas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
                fechaInicio: this.fechaInicio + 'T00:00:00.000',
                fechaFin: this.fechaFin + 'T23:59:59.000',
                buscarNP: this.numeroEmpleado
            };
            this.cargando = true;
            this.incidencias = [];
            this.dialogAutorizaIncidencia = false;
            this.loading = true;
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params)
                .then((response) => {
                    if (response.data.status == 'OK') {
                        this.incidencias = response.data.data;
                    }

                    if (response.data.status == 'vacio') {
                        this.textoBarra = 'No se encontraron registros';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                    this.cargando = false;
                    this.loading = false;
                    this.dialogFiltro = false;
                })
        },
        funcionFormatoFecha1() {
            this.formatoFecha1 = this.formatDate(this.textFechaDescansar);
            this.fechaInicio = this.textFechaDescansar;
            this.fechaDescansar = false;
        },
        funcionFormatoFecha2() {
            this.formatoFecha2 = this.formatDate(this.textFechaTrabajar);
            this.fechaFin = this.textFechaTrabajar;
            this.fechaTrabajar = false;
        },
        openDialogAutorizacion(item) {
            this.idIncidencia = item.Id;
            this.DetalleNombre = item.Nombre;
            this.DetalleFecha = this.formatDate(item.FechaInicio);
            this.DetalleNumeroPersonal = item.NumeroPersonal;
            this.DetalleEstatusDesc = item.EstatusDesc;
            this.DetalleEstatusActual = item.EstatusActual;
            this.dialogAutorizaIncidencia = true;
        },
        editarAutorizacion(estatusEdit) {

            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                id_incidencia: this.idIncidencia,
                nuevoEstatus: estatusEdit,
            };
            this.cargando = true;
            this.incidencias = [];
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params).then((response) => {
                if (response.data.status == 'OK') {
                    this.dialogAutorizaIncidencia = false;
                    this.consultarIncidencias();
                } else {
                    this.cargando = false;
                }
            })
        },
        onResize() {
            this.windowSize = {
                x: window.innerWidth,
                y: window.innerHeight
            }
        },
        async getStatusIncedence() {
            let paramsEstatusInc = {
                Opcion: 4,
                token: localStorage.getItem('token')
            };
            //(paramsEstatusInc)
            this.textoCargando += 'estatus...'
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        this.deleteToken()
                    } else {
                        var data = response.data.estatusIncidencias;
                        this.estatusIncidencias = data;
                    }

                })
                .catch(function(e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
        consultarIncidencias() {
            let hoy = new Date();
            let diaEnMilisegundosInicio = (24 * 60 * 60 * 1000) * 14;
            this.fechaInicio = new Date(hoy.getTime() - diaEnMilisegundosInicio).toISOString().substr(0, 10);
            this.textFechaDescansar = new Date(hoy.getTime() - diaEnMilisegundosInicio).toISOString().substr(0, 10);

            let diaEnMilisegundosFin = (24 * 60 * 60 * 1000);
            this.fechafin = new Date(hoy.getTime() - diaEnMilisegundosFin).toISOString().substr(0, 10);
            this.textFechaTrabajar = new Date(hoy.getTime() - diaEnMilisegundosFin).toISOString().substr(0, 10);
            this.formatoFecha1 = this.formatDate(this.fechaInicio);
            this.formatoFecha2 = this.formatDate(this.fechaFin);
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
                fechaInicio: this.fechaInicio + 'T00:00:00.000',
                fechaFin: this.fechaFin + 'T23:59:59.000'
            };
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params)
                .then((response) => {
                    if (response.data.status == 'OK') {
                        this.incidencias = response.data.data;
                    }
                    if (response.data.status == 'vacio') {
                        this.textoBarra = 'No se encontraron registros';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                    this.cargando = false;
                })
        },
        formatDate(date) {
            if (!date) return null;

            let [year, month, day] = date.split("-");
            switch (month) {
                case "01":
                    month = "ENE";
                    break;
                case "02":
                    month = "FEB";
                    break;
                case "03":
                    month = "MAR";
                    break;
                case "04":
                    month = "ABR";
                    break;
                case "05":
                    month = "MAY";
                    break;
                case "06":
                    month = "JUN";
                    break;
                case "07":
                    month = "JUL";
                    break;
                case "08":
                    month = "AGO";
                    break;
                case "09":
                    month = "SEP";
                    break;
                case "10":
                    month = "OCT";
                    break;
                case "11":
                    month = "NOV";
                    break;
                case "12":
                    month = "DIC";
                    break;
            }
            return `${day}/${month}/${year}`;
        },

        fixFormatDate(date) {
            return formatDate(date)
        },

        //funciones para obtener colaboradores
        getColaboradores() {
            let params = {
                Opcion: 11,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                var data = response.data.colaboradores;
                this.colaboradores = data;
                // this.colaboradores.map((elemento) => {
                //     this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
                //     if (elemento["EMPLID"] == response.data.miFolio) {
                //         this.miFolio = response.data.miFolio;
                //     }
                // });
                this.loadingColaboradores = false;
            });
        },
        buscarNumeroEmpleado() {
            if (this.nombre) {

                let resultdo = this.colaboradores.filter((dato) => dato['nombreMostrar'] == this.nombre);
                this.numeroEmpleado = resultdo[0]['EMPLID'];

            }


        },
        getDetalleIncidencia(id) {
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token"),
                id: id
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                this.detalleIncidencia = response.data.detalle;
                this.loadingDetalle = false;
            });
        },
        filterByEmplid(value) {
            if (!this.emplidFilterValue) {
                return true;
            }
            return value == this.emplidFilterValue;
        },
        filterByEAuthvalue(value) {
            if (!this.authFilterValue) {
                return true;
            }
            return value == this.authFilterValue;
        },
        filterByStatus(value) {
            if (!this.statusFilterValue) {
                return true;
            }
            return value == this.statusFilterValue;
        },
        filterByType(value) {
            if (!this.typeFilterValue) {
                return true;
            }
            return value == this.typeFilterValue;
        },
        //
        logTypeFilter() {
            this.selectVac = this.typeFilterValue !== null;
        },
        logStatusFilter() {
            this.selectAut = this.statusFilterValue === 'Autorizado';
        },
        confirmarSendIncidencias() {
            this.dialogConfirmar = true;
        },
        cancelAuthorization() {
            this.dialogConfirmar = false;
        },
        async sendIncidencias() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                items: this.selectedItems
            };
            this.isUpdating = true;
            try {
                const response = await axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/Reportes/ws-IncidenciasPorFechas.php`, params);
                if (response.data.status == 'OK') {
                    this.incidencias = [];
                    this.selectedItems = [];
                    await this.consultarIncidencias();
                    this.cancelAuthorization();
                    this.textoBarra = 'Registros actualizados';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.selectAut = false;
                } else if (response.data.status == 'vacio') {
                    this.textoBarra = 'No se encontraron registros';
                    this.colorBarra = 'primary';
                    this.muestraBarra = true;
                }
            } catch (error) {
                console.error("Error en la petición:", error);
                this.textoBarra = 'Ocurrió un error al realizar la petición';
                this.colorBarra = 'red';
                this.muestraBarra = true;
            } finally {
                // Desactiva el indicador de carga
                this.isUpdating = false;
            }
        },
    }
}