var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"900","content-class":"fix-border-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"blue accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill mr-1",attrs:{"small":"","outlined":"","color":"blue accent-4","disabled":_vm.padrino.ahijados <= 0},on:{"click":function($event){_vm.seleccionado = _vm.padrino.id}}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("mdi-account-eye-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Ver ahijados")])])]}}])},[[_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{staticClass:"rounded-xl rounded-b-0 overline font-weight-bold",attrs:{"color":"primary","flat":"","dark":""}},[_vm._v(" Ahijados de "+_vm._s(_vm.padrino.EMP_NOMEMP)+" ")]),(_vm.showAlert)?_c('div',{staticClass:"primary px-4"},[_c('v-alert',{staticClass:"mb-0",attrs:{"dense":"","border":"left","type":_vm.alertType,"dismissible":""},model:{value:(_vm.showAlert),callback:function ($$v) {_vm.showAlert=$$v},expression:"showAlert"}},[_vm._v(" "+_vm._s(_vm.alertMessage)+" ")])],1):_vm._e(),_c('v-card-text',{staticClass:"pa-0"},[_c('v-data-table',{attrs:{"items":_vm.ahijados,"headers":_vm.headers,"search":_vm.search,"loading":_vm.loadingEmpleados,"dense":"","no-data-text":"Sin ahijados registrados","loading-text":"Cargando información...","footer-props":{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"rounded-0 pt-3",attrs:{"color":"primary","flat":""}},[_c('div',{staticStyle:{"display":"flex","justify-content":"space-between","align-items":"start","width":"100%"}},[_c('v-text-field',{staticClass:"rounded-lg",staticStyle:{"max-width":"650px"},attrs:{"placeholder":"Buscar...","prepend-inner-icon":"mdi-magnify","solo-inverted":"","dark":"","flat":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill overline",attrs:{"small":"","dark":"","outlined":"","fab":""},on:{"click":_vm.updateTable}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-refresh")])],1)]}}])},[_c('span',[_vm._v("Actualizar tabla")])])],1)],1)])]},proxy:true},{key:"item.fecha_asignacion",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.setFormatDate(item.fecha_asignacion))+" ")]}},{key:"item.dias_restante",fn:function(ref){
          var item = ref.item;
return [_c('label',{staticClass:"font-italic font-weight-bold"},[_vm._v(_vm._s(item.dias_restante))])]}},{key:"item.acciones",fn:function(ref){
          var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":"","color":"pink darken-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill",attrs:{"x-small":"","outlined":"","color":"pink darken-2"},on:{"click":function($event){_vm.nombreSeleccion = item.EMP_NOMEMP;
                    _vm.ahijadoAux = item;
                    _vm.showConfirm = true;}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus-thick")])],1)]}}],null,true)},[_c('span',[_vm._v("Quitar a "+_vm._s(item.emplid))])])]}}])})],1)],1)],_c('ConfirmDialog',{attrs:{"showConfirm":_vm.showConfirm,"question":'¿Realmente deseas quitar a ' + _vm.nombreSeleccion + '?'},on:{"cierraConfirm":_vm.cierraConfirm}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }