var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"650","content-class":"fix-border-dialog"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"bottom":"","color":"blue accent-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltipOn = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill overline mr-1",attrs:{"small":"","dark":"","outlined":"","fab":""}},'v-btn',Object.assign({}, attrs, tooltipAttrs),false),Object.assign({}, on, tooltipOn)),[_c('v-icon',[_vm._v("mdi-account-plus-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Agregar padrino")])])]}},{key:"default",fn:function(dialog){return [_c('v-card',{staticClass:"rounded-xl"},[_c('v-toolbar',{staticClass:"rounded-xl rounded-b-0 overline font-weight-bold",attrs:{"color":"primary","flat":"","dark":""}},[_vm._v(" crear padrino o madrina ")]),_c('v-card-text',{staticClass:"pt-5"},[_c('v-form',{ref:"form"},[_c('label',{staticClass:"overline"},[_vm._v("Colaborador(a)")]),_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","placeholder":"Busca por nombre o número de empleado...","items":_vm.empleados,"loading":_vm.loadingEmpleados,"item-text":"nombreBusqueda","item-value":function (empleado) { return empleado; },"rules":_vm.rules,"required":""},on:{"change":function($event){_vm.departamento = _vm.empleadoSeleccionado.departamento; _vm.antiguedad = _vm.empleadoSeleccionado.antiguedad}},model:{value:(_vm.empleadoSeleccionado),callback:function ($$v) {_vm.empleadoSeleccionado=$$v},expression:"empleadoSeleccionado"}}),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"8"}},[_c('label',{staticClass:"overline"},[_vm._v("Departamento")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"dense":"","disabled":"","outlined":""},model:{value:(_vm.departamento),callback:function ($$v) {_vm.departamento=$$v},expression:"departamento"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('label',{staticClass:"overline"},[_vm._v("antigüedad (años)")]),_c('v-text-field',{staticClass:"rounded-lg",attrs:{"dense":"","disabled":"","outlined":""},model:{value:(_vm.antiguedad),callback:function ($$v) {_vm.antiguedad=$$v},expression:"antiguedad"}})],1)],1),(_vm.empleadoSeleccionado != null)?_c('div',[(
                _vm.empleadoSeleccionado.antiguedad < 1
              )?_c('v-alert',{staticClass:"rounded-lg",attrs:{"dense":"","outlined":"","border":"left","type":"warning"}},[_c('strong',[_vm._v("¡ADVERTENCIA!")]),_vm._v(" La antigüedad del colaborador seleccionado "),_c('strong',[_vm._v("es menor")]),_vm._v(" a un año "),_c('strong',{staticClass:"overline font-weight-bold"},[_vm._v("esto puede presentar un riesgo para la seguridad del ahijado(a)")]),_vm._v(". ")]):_vm._e()],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',{staticClass:"pa-0"},[_c('v-btn',{attrs:{"text":"","block":"","x-large":"","color":"teal darken-2 rounded-xl rounded-t-0"},on:{"click":function($event){if (_vm.$refs.form.validate()) {
              _vm.loadingSave = true;
              _vm.validaInformacion();
              dialog.value = false;
            }}}},[_vm._v("Guardar")])],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }