import encuestasPrincipal from "./components/encuestasPrincipal.vue"
export default {
  name: 'administracion-de-encuestas',
  components: {encuestasPrincipal},
  props: [],
  data() {
    return {
    }
  },
  computed: {

  },
  mounted() {

  },
  methods: {

  }
}


