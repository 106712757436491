<template>
  <v-container>
    <v-card
      width="100%"
      min-height="200px"
      color="blue-grey lighten-5"
      class="rounded-xl elevation-0"
    >
      <div class="center-item-in-div">
        <p
          class="subtitle-1 blue-grey--text text--darken-2 text-uppercase font-weight-bold"
        >
          Selecciona los criterios para generar el reporte
        </p>
      </div>

      <v-card-text>
        <v-form>
          <v-row>
            <v-col>
              <label class="overline">fechas</label>
              <v-text-field
                v-model="dateRangeText"
                @click="dialogFechas = true"
                prepend-inner-icon="mdi-calendar"
                outlined
                readonly
                class="rounded-lg"
                dense
                flat
                clearable
                placeholder="Selecciona el rango de fechas..."
              ></v-text-field>
            </v-col>
            <v-col>
              <label class="overline">áreas </label>
              <v-combobox
                v-model="areasSeleccionadas"
                :loading="loadingAreas"
                :items="areas"
                multiple
                item-text="Area"
                item-value="UR_AREA_DEPT"
                prepend-inner-icon="mdi-domain"
                outlined
                class="rounded-lg"
                dense
                small-chips
                flat
                clearable
                placeholder="Selecciona el área..."
                @change="updateDeptos"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div
                style="display: flex; justify-content: space-between; align-items: center;"
              >
                <label class="overline">
                  departamentos
                </label>
                <v-btn
                  x-small
                  outlined
                  color="primary"
                  class="rounded-pill"
                  :disabled="areasSeleccionadas.length === 0"
                  @click="deptosSeleccionados = departamentos"
                >
                  seleccionar todos
                </v-btn>
              </div>

              <v-combobox
                v-model="deptosSeleccionados"
                :items="departamentos"
                item-text="DESCR"
                item-value="DEPTID"
                :loading="loadingDeptos"
                :disabled="areasSeleccionadas.length === 0"
                prepend-inner-icon="mdi-office-building"
                outlined
                class="rounded-lg"
                dense
                flat
                clearable
                multiple
                small-chips
                placeholder="Selecciona el departamento..."
              >
                <template v-slot:selection="{ attrs, item, parent, selected }">
                  <v-chip
                    v-if="item === Object(item)"
                    v-bind="attrs"
                    :color="`${item.color} lighten-3`"
                    :input-value="selected"
                    label
                    class="rounded-pill"
                    small
                  >
                    <span class="pr-2">
                      {{ item.DESCR }}
                    </span>
                    <v-icon right small @click="parent.selectItem(item)">
                      $delete
                    </v-icon>
                  </v-chip>
                </template>
              </v-combobox>
            </v-col>
            <v-col>
              <div
                style="display: flex; justify-content: space-between; align-items: center;"
              >
                <label class="overline">
                  grupos de trabajo
                </label>
                <v-btn
                  x-small
                  outlined
                  color="primary"
                  class="rounded-pill"
                  :disabled="loadingGruposDeTrabajo"
                  @click="gruposSeleccionados = gruposTrabajo"
                >
                  seleccionar todos
                </v-btn>
              </div>

              <v-combobox
                v-model="gruposSeleccionados"
                outlined
                :loading="loadingGruposDeTrabajo"
                :items="gruposTrabajo"
                item-text="grupo_Trabajo"
                class="rounded-lg"
                prepend-inner-icon="mdi-account-group"
                dense
                flat
                clearable
                multiple
                small-chips
                placeholder="Selecciona el grupo de trabajo..."
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label class="overline"
                >tipos de pago {{ tiposSeleccionados }}</label
              >
              <v-select
                v-model="tiposSeleccionados"
                outlined
                :loading="loadingTiposDePago"
                :items="TiposPago"
                item-text="TipoPago"
                item-value="COMP_FREQUENCY"
                prepend-inner-icon="mdi-account-cash"
                class="rounded-lg"
                dense
                flat
                clearable
                small-chips
                placeholder="Selecciona el tipo de pago..."
              ></v-select>
            </v-col>
            <v-col>
              <label class="overline">turnos</label>
              <v-combobox
                v-model="turnosSeleccionados"
                outlined
                :loading="loadingTurnos"
                :items="turnos"
                item-text="nombreTurno"
                prepend-inner-icon="mdi-account-clock"
                class="rounded-lg"
                dense
                flat
                clearable
                multiple
                small-chips
                placeholder="Selecciona el turno..."
              ></v-combobox>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions class="center-item-in-div">
        <v-btn
          x-large
          color="primary"
          depressed
          class="rounded-lg"
          :disabled="!(dates.length === 2)"
          :loading="loadingReporte"
          @click="
            loadingReporte = true;
            validaFiltros();
          "
        >
          <v-icon left>mdi-chart-timeline</v-icon>
          generar reporte
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-dialog v-model="dialogFechas" max-width="350px">
      <v-card class="rounded-lg">
        <v-card-title class="primary white--text subtitle-2 py-1">
          <v-row dense>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">
                Inicio
              </p>
              <p
                v-if="dates.length >= 1"
                class="mb-0"
                style="font-size:1.05rem"
              >
                {{ fixFormatDate(dates[0]) }}
              </p>
            </v-col>
            <v-divider></v-divider>
            <v-col dense cols="6">
              <p class="mb-0 font-weight-bold blue--text text--lighten-4">
                Fin
              </p>
              <p
                v-if="dates.length == 2"
                class="mb-0"
                style="font-size:1.05rem"
              >
                {{ fixFormatDate(dates[1]) }}
              </p>
            </v-col>
          </v-row>
        </v-card-title>
        <div class="text-center">
          <v-date-picker
            v-model="dates"
            max-width="350px"
            v-on:change="cierraDialog()"
            no-title
            range
            elevation="0"
            locale="es-mx"
          >
            <template #title>
              <div class="v-picker__title">
                <div class="v-date-picker-header__value">Hola</div>
              </div>
            </template>
          </v-date-picker>
        </div>
      </v-card>
    </v-dialog>

    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />

    <MuestraReporteDeAusentismosGenerado
      :muestraReporte="muestraReporteGenerado"
      :reporte="reporteAsistencia"
      @cierraReporte="muestraReporteGenerado = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import {
  formatDate,
  deleteToken,
} from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MuestraReporteDeAusentismosGenerado from "./MuestraReporteDeAusentismosGenerado.vue";

export default {
  data() {
    return {
      dates: [],
      areas: [],
      departamentos: [],
      gruposTrabajo: [],
      TiposPago: [],
      turnos: [],
      areasSeleccionadas: [],
      deptosSeleccionados: [],
      gruposSeleccionados: [],
      tiposSeleccionados: null,
      turnosSeleccionados: [],
      reporteAsistencia: [],
      dialogFechas: false,
      loadingAreas: true,
      loadingDeptos: false,
      loadingGruposDeTrabajo: true,
      loadingTiposDePago: true,
      loadingTurnos: true,
      loadingReporte: false,
      muestraReporteGenerado: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
    };
  },
  components: { SnackBar, MuestraReporteDeAusentismosGenerado },
  computed: {
    dateRangeText() {
      let inicio = "";
      let fin = "";
      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates[1] != null) {
        fin = formatDate(this.dates[1]);
      }

      if (this.dates.length == 0) {
        return "";
      }
      return "Del " + inicio + " al " + fin; //this.dates.join(' al ')
    },
  },
  async mounted() {
    this.areas = await this.getAreas();
    this.gruposTrabajo = await this.getGruposDeTrabajo();
    this.TiposPago = await this.getTiposDePago();
    this.turnos = await this.getTurnosActivos();
  },
  methods: {
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async updateDeptos() {
      this.loadingDeptos = true;
      this.departamentos = await this.getDepartamentosPorAreas();
    },
    getAreas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/ausentismos/areas`, {
            headers,
          })
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingAreas = false;
          });
      });
    },
    getDepartamentosPorAreas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const idsAreas = this.areasSeleccionadas.map((area) => area.UR_AREA_DEPT);

      return new Promise((resolve) => {
        axios
          .get(
            `${
              this.$store.getters.getHostNode
            }/api/ausentismos/departamentos-por-area?id=${idsAreas.join(",")}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingDeptos = false;
          });
      });
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    async cierraDialog() {
      if (this.dates.length == 2) {
        if (this.dates[0] > this.dates[1]) {
          this.dates.pop();
          this.showSnackBar(
            "La fecha final debe ser mayor a la fecha de inicio."
          );
        } else {
          this.dialogFechas = false;
        }
      }
    },
    getGruposDeTrabajo() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/ausentismos/grupos-de-trabajo`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingGruposDeTrabajo = false;
          });
      });
    },
    getTiposDePago() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/ausentismos/tipos-de-pago`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingTiposDePago = false;
          });
      });
    },
    getTurnosActivos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/ausentismos/turnos-activos`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingTurnos = false;
          });
      });
    },
    async validaFiltros() {
      let ruta = `${this.$store.getters.getHostNode}/api/ausentismos/genera-reporte-ausentismos?dt=${this.dates[0]},${this.dates[1]}`;

      if (this.areasSeleccionadas.length > 0) {
        const idsAreas = this.areasSeleccionadas.map(
          (area) => area.UR_AREA_DEPT
        );
        ruta += `&ar=${idsAreas}`;
      }

      if (this.deptosSeleccionados.length > 0) {
        const idsDeptos = this.deptosSeleccionados.map(
          (departamento) => departamento.DEPTID
        );
        ruta += `&dp=${idsDeptos}`;
      }

      if (this.gruposSeleccionados.length > 0) {
        const idsGruposDeTrabajo = this.gruposSeleccionados.map(
          (grupoTrabajo) => grupoTrabajo.grupo_Trabajo
        );
        ruta += `&wg=${idsGruposDeTrabajo}`;
      }

      if (![null, undefined, ""].includes(this.tiposSeleccionados)) {
        ruta += `&pt=${this.tiposSeleccionados}`;
      }

      if (this.turnosSeleccionados.length > 0) {
        const idsTurnos = this.turnosSeleccionados.map((turno) => turno.id);
        ruta += `&tn=${idsTurnos}`;
      }

      await this.getReporte(ruta)
        .then((reporte) => {
          this.reporteAsistencia = reporte;
          this.muestraReporteGenerado = true;
        })
        .catch((err) => {
          this.showSnackBar(err)
        })
        ;
    },

    getReporte(ruta) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((resolve) => {
        axios
          .get(ruta, { headers })
          .then((response) => {
            if (response.data.status === "OK") {
              resolve(response.data.data);
            } else {
              resolve([]);
            }
          })
          .catch((error) => {
            if (error.response && error.response.status === 401) {
              deleteToken();
            }
            resolve([]);
          })
          .finally(() => {
            this.loadingReporte = false;
          });
      });
    },
  },
};
</script>
