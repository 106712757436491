<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="600"
    content-class="fix-border-dialog"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top color="pink darken-2">
        <template v-slot:activator="{ on: tooltipOn, attrs: tooltipAttrs }">
          <v-btn
            small
            outlined
            class="rounded-pill"
            color="pink darken-2"
            v-bind="{ ...attrs, ...tooltipAttrs }"
            v-on="{ ...on, ...tooltipOn }"
          >
            <v-icon>mdi-alert-box</v-icon>
          </v-btn>
        </template>
        <span>Reportar a {{ empleado.id }}</span>
      </v-tooltip>
    </template>
    <template v-slot:default="dialog">
      <v-card class="rounded-xl">
        <v-toolbar
          color="primary"
          flat
          class="rounded-xl rounded-b-0 overline font-weight-bold"
          dark
        >
          Reportar falta administrativa de {{ empleado.title }}
        </v-toolbar>
        <v-card-text class="pt-5">
          <v-form ref="form">
            <label class="overline">Descripción del evento a reportar</label>
            <v-textarea
              v-model="comentario"
              class="rounded-lg"
              placeholder="Describe de manera breve el evento que vas a reportar..."
              outlined
              dense
              :rows="4"
              :rules="descRules"
              :counter="500"
              required
            ></v-textarea>
            <label class="overline">Fecha del evento a reportar</label>
            <v-dialog
              ref="dialogFecha"
              v-model="modalDate"
              :return-value.sync="fechaEvento"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaEvento"
                  placeholder="Selecciona la fecha del evento..."
                  prepend-icon="mdi-calendar"
                  dense
                  outlined
                  class="rounded-lg"
                  required
                  :rules="rules"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaEvento"
                scrollable
                @input="
                  $refs.dialogFecha.save(fechaEvento);
                  modalDate = false;
                "
              >
              </v-date-picker>
            </v-dialog>
            <label class="overline">Evidencia del evento a reportar</label>
            <v-file-input
              v-model="files"
              dense
              placeholder="Si tienes evidencia adjúntala..."
              accept="image/*"
              outlined
              multiple
              small-chips
              show-size
              class="rounded-lg"
            ></v-file-input>
            <div v-if="empleadoSeleccionado != null">
              <v-alert
                v-if="
                  empleadoSeleccionado.departamento !== padrino.departamento
                "
                dense
                outlined
                border="left"
                type="warning"
                class="rounded-lg"
              >
                <strong>¡ADVERTENCIA!</strong> El departamento del ahijado(a) es
                <strong>diferente</strong> al del padrino o madrina seleccionada
                <strong class="overline font-weight-bold"
                  >esto puede presentar un riesgo para la seguridad del
                  ahijado(a)</strong
                >.
              </v-alert>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="pa-0">
          <v-btn
            text
            block
            x-large
            color="teal darken-2 rounded-xl rounded-t-0 font-weight-bold"
            @click="
              dialog.value = false;
              validaFormulario();
            "
          >
            <v-icon left>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";

export default {
  props: ["empleado"],
  data() {
    return {
      modalDate: false,
      empleados: [],
      rules: [(v) => !!v || "Este campo es obligatorio."],
      descRules: [
        (v) => !!v || "Este campo es requerido",
        (v) => (v && v.length >= 20) || "Debes agregar al menos 20 caracteres.",
        (v) => (v && v.length <= 501) || "No debes agregar más 500 caracteres.",
      ],
      loadingEmpleados: true,
      loadingSave: false,
      seleccionado: null,
      empleadoSeleccionado: null,
      departamento: null,
      fechaEvento: null,
      comentario: null,
      files: [],
    };
  },
  methods: {
    async validaFormulario() {
      if (this.$refs.form.validate()) {
        const responseApi = await this.setSolicitud();
        if (responseApi.status === "OK") {
          if (this.files.length > 0) {
            this.files.forEach(async (file) => {
              if (await this.submitFile(file, responseApi.datos.folio)) {
                alert(
                  "El archivo: " +
                    file.name +
                    " a sido cargado en la carpeta " +
                    responseApi.datos.folio
                );
              } else {
                alert(
                  "El archivo: " +
                    file.name +
                    " no se ha podido cargar en la carpeta " +
                    responseApi.datos.folio
                );
              }
            });
            // alert(JSON.stringify(responseApi, null, 2));
            // this.files.map((archivo) => {});
            // this.$refs.form.reset();
            // this.$refs.form.resetValidation();
            // this.$emit("cierraRegistroSolicitud", {
            //   status: true,
            //   message: responseApi.datos.message,
            // });
          }
        } else {
          this.$emit("cierraRegistroSolicitud", {
            status: false,
            message: responseApi.message,
          });
        }
      }
    },
    async setSolicitud() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      // Agrega campos individuales de datos no relacionados con archivos
      const params = {
        empleado: this.empleado.id,
        comentario: this.comentario,
        fecha: this.fechaEvento,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/actas-administrativas/solicitud`,
            params,
            { headers }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.loadingSave = false;
          });
      });
    },

    async submitFile(file, idSolicitud) {
      if (!file) {
        console.error("No hay archivo seleccionado");
        return;
      }

      try {
        const fileArrayBuffer = await this.readFileAsArrayBuffer(file);
        const { encryptData, iv, key } = await this.encrypt(fileArrayBuffer);
        this.iv = iv;
        this.encryptionKey = key;
        this.encryptedData = encryptData;
        const encryptedFile = new Blob([encryptData], {
          type: "application/octet-stream",
        });
        const formData = new FormData();
        formData.append("encryptedFile", encryptedFile, `${this.fileName}`);
        formData.append("iv", JSON.stringify(Array.from(iv)));
        formData.append("key", JSON.stringify(await this.exportKey(key)));
        formData.append("id", idSolicitud);
        formData.append("name", file.name.slice(0, file.name.lastIndexOf('.')));
        const response = await axios.post(
          `${this.$store.getters.getHostNode}/api/actas-administrativas/cargar-archivo`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        return response.data.status ? true : false;
      } catch (error) {
        console.error("Error al cifrar y enviar el archivo:", error);
        return false;
      }
    },
    readFileAsArrayBuffer(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = () => {
          reject(reader.error);
        };
        reader.readAsArrayBuffer(file);
      });
    },
    async exportKey(key) {
      const exported = await crypto.subtle.exportKey("jwk", key);
      return exported;
    },
    async encrypt(data) {
      try {
        const key = await crypto.subtle.generateKey(
          {
            name: "AES-GCM",
            length: 256,
          },
          true,
          ["encrypt", "decrypt"]
        );

        const iv = crypto.getRandomValues(new Uint8Array(12));
        const encryptData = await crypto.subtle.encrypt(
          {
            name: "AES-GCM",
            iv: iv,
          },
          key,
          data
        );

        return { encryptData, iv, key };
      } catch (error) {
        console.error("Error al cifrar los datos:", error);
        throw error;
      }
    },
  },
};
</script>
