<template>
  <v-container>
    <v-card class="rounded-xl" elevation="0" color="blue-grey lighten-5">
      <v-data-table
        :headers="headers"
        :items="padrinos"
        dense
        class="elevation-0 rounded-lg"
        style="background-color:transparent;"
        no-data-text="Sin padrinos registrados"
        loading-text="Cargando información..."
        :loading="loadingData"
        :footer-props="{
          'items-per-page-text': 'Elementos por página',
          pageText: '{0}-{1} de {2}',
        }"
      >
        <template v-slot:top>
          <v-toolbar color="primary" flat class="rounded-xl rounded-b-0 pt-3">
            <div
              style="display: flex; justify-content: space-between; align-items: start; width: 100%;"
            >
              <v-text-field
                placeholder="Buscar..."
                prepend-inner-icon="mdi-magnify"
                class="rounded-lg"
                solo-inverted
                dark
                flat
                dense
                style="max-width: 650px;"
              ></v-text-field>
              <div>
                <AgregaPadrino @cierraRegistro="cierraRegistroAhijado"/>
                <v-btn
                  small
                  class="rounded-pill overline"
                  dark
                  outlined
                  fab
                  @click="updateTable"
                >
                  <v-icon>mdi-refresh</v-icon>
                </v-btn>
              </div>
            </div>
          </v-toolbar>
        </template>
        <template v-slot:item.fecha_asignacion="{ item }">
          {{ setFormatDate(item.fecha_asignacion) }}
        </template>
        <template v-slot:item.estatus="{ item }">
          <v-chip small depressed :color="item.estatus ? 'teal' : 'pink'" dark>
            {{ item.estatus ? "Habilitado" : "Inhabilitado" }}
          </v-chip>
        </template>
        <template v-slot:item.ahijados="{ item }">
          <label class="font-italic font-weight-bold">{{
            item.ahijados
          }}</label>
        </template>
        <template v-slot:item.acciones="{ item }">
          <VerAhijados
            :padrino="item"
          />
          <AgregaAhijado
            :padrino="item"
            :seleccionado="seleccionado"
            @click="seleccionado = item.id"
            @cierraRegistro="cierraRegistroAhijado"
          />
        </template>
      </v-data-table>
    </v-card>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :color="colorBarra"
      :texto="textoBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import AgregaAhijado from "./AgregaAhijado.vue";
import VerAhijados from "./VerAhijados.vue";
import AgregaPadrino from "./AgregaPadrino.vue";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  components: { AgregaAhijado, SnackBarErrorIncidencias, VerAhijados, AgregaPadrino },
  data() {
    return {
      headers: [
        {
          text: "id",
          value: "id",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "emplid",
          value: "emplid",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "nombre",
          value: "EMP_NOMEMP",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "asignación",
          value: "fecha_asignacion",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "depto.",
          value: "departamento",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "estatus",
          value: "estatus",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "# ahijados",
          value: "ahijados",
          align: "center",
          class: "primary overline font-weight-bold white--text",
        },
        {
          text: "acciones",
          value: "acciones",
          align: "center",
          sortable: false,
          class: "primary overline font-weight-bold white--text",
        },
      ],
      padrinos: [],
      loadingData: true,
      seleccionado: null,
      muestraBarra: false,
      colorBarra: "",
      textoBarra: "",
    };
  },
  async mounted() {
    this.padrinos = await this.getPadrinos();
  },
  methods: {
    showSnackBar(texto, color = "pink darken-2") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    async updateTable() {
      this.loadingData = true;
      this.padrinos = await this.getPadrinos();
    },
    getPadrinos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/padrinos-urrea/padrinos`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingData = false;
          });
      });
    },
    setFormatDate(date) {
      let fecha = new Date(date);

      let diaUTC = fecha.getUTCDate();
      let mesUTC = fecha.getUTCMonth() + 1;
      let anioUTC = fecha.getUTCFullYear();

      diaUTC = diaUTC < 10 ? "0" + diaUTC : diaUTC;
      mesUTC = mesUTC < 10 ? "0" + mesUTC : mesUTC;

      return diaUTC + "/" + mesUTC + "/" + anioUTC;
    },
    cierraRegistroAhijado(respuesta) {
      const { message, status } = respuesta;
      const color = status ? "teal darken-2" : "pink darken-2";

      this.showSnackBar(message, color);

      if (status) {
        this.updateTable();
      }
    },
  },
};
</script>
