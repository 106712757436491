var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"justify-end"},[(_vm.rol && _vm.modulo == 'EscaleraCrecimiento' && _vm.depto == 'ALL')?_c('v-btn',{staticClass:"mb-5",attrs:{"color":"cyan","dark":"","loading":_vm.loading},on:{"click":_vm.nuevoNivel}},[_vm._v(" Nuevo Nivel ")]):_vm._e()],1),_c('div',{staticClass:"scroll-container-niveles"},_vm._l((_vm.niveles),function(item){return _c('div',{key:item.id,staticClass:"scroll-items"},_vm._l((_vm.nivelesReverse),function(item2){return _c('div',{key:item2.id,staticClass:"item-card"},[(item.nivel == item2.nivel && item2.id_area)?_c('v-card',{staticClass:"pb-1",style:(_vm.nivelesReverse.length > 2 ? 'min-width:200px' : 'min-width:100px'),attrs:{"elevation":"3"}},[_c('div',{staticClass:"color-bar"}),_c('p',{staticClass:"my-1 px-2 text-center"},[_vm._v(" Nivel "+_vm._s(item.RowNum)+" "),(_vm.rol && _vm.modulo == 'EscaleraCrecimiento')?_c('v-tooltip',{attrs:{"top":"","color":"pink lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){return _vm.confirmItem(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-delete-empty-outline")])]}}],null,true)},[_c('span',[_vm._v(" Borrar nivel")])]):_vm._e(),(_vm.modulo == 'MiPersona')?_c('v-tooltip',{attrs:{"top":"","dark":"","color":"primary lighten-1"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(item.EnElPuesto > 0)?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"primary","large":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-emoticon ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(" Usted está aquí ")])]):_vm._e()],1),_c('v-divider'),_c('div',{staticClass:"mx-5 px-3 mb-3 pb-2 scroll-card",staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.openListaPuestos(item)}}},[(item.puestos)?_c('div',{attrs:{"align":"left"}},_vm._l((item.puestos),function(i,index){return _c('li',{key:index,staticStyle:{"font-size":"0.8em"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-lg",attrs:{"color":i.color,"dark":i.dark == 1 ? true : false,"x-small":""}},'v-btn',attrs,false),on),[_vm._v(" ["+_vm._s(i.id_puesto)+"] "+_vm._s(i.puesto)+" "+_vm._s(i.numPuestosReal)+" / "+_vm._s(i.numPuestosPlan)+" ")])]}}],null,true)},[_c('span',[_vm._v(" Número de puestos plan: "+_vm._s(i.numPuestosPlan)+" "),_c('br')]),_c('span',[_vm._v(" Número de puestos real: "+_vm._s(i.numPuestosReal))])])],1)}),0):_c('div',[_vm._v("Vacio")])])],1):(item.RowNum == item2.RowNum + 1)?_c('v-card',{staticClass:"mx-5",attrs:{"elevation":"0","color":"blue-grey lighten-5"}},[(_vm.rol && _vm.modulo == 'EscaleraCrecimiento' && _vm.depto == 'ALL')?_c('v-tooltip',{attrs:{"top":"","color":"blue lighten-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"blue-grey lighten-5","dark":"","block":"","elevation":"0"},on:{"click":function($event){return _vm.confirmNivelBetween(item)}}},'v-btn',attrs,false),on),[(
                    _vm.$vuetify.breakpoint.name == 'xs' ||
                    _vm.$vuetify.breakpoint.name == 'sm'
                  )?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-up")]):_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-arrow-up-right")])],1)]}}],null,true)},[_c('span',[_vm._v(" Añadir nivel")])]):_vm._e()],1):(item.nivel != item2.nivel && item2.id_area)?_c('v-card',{staticClass:"py-15",attrs:{"color":"blue-grey lighten-4","elevation":"0"}}):_vm._e()],1)}),0)}),0),(_vm.openDetalles)?_c('lista-perfil-puestos',{attrs:{"dialog":_vm.openDetalles,"item":_vm.item,"depto":_vm.depto},on:{"cierraDialog":_vm.close}}):_vm._e(),(_vm.showConfirm)?_c('ConfirmDialogReclutamientoVue',{attrs:{"showConfirm":_vm.showConfirm,"question":_vm.txtQuestion},on:{"cierraConfirm":_vm.deleteItem}}):_vm._e(),(_vm.showConfirmAdd)?_c('ConfirmDialogReclutamientoVue',{attrs:{"showConfirm":_vm.showConfirmAdd,"question":_vm.txtQuestion},on:{"cierraConfirm":_vm.addNivelBetween}}):_vm._e(),(_vm.muestraBarra)?_c('SnackBarErrorIncidencias',{attrs:{"muestraBarra":_vm.muestraBarra,"texto":_vm.textoBarra,"color":_vm.colorBarra},on:{"cierraBarra":function($event){_vm.muestraBarra = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }