<template>
  <v-dialog
    v-model="dialog"
    max-width="350"
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card>
      <v-card-title class="text-subtitle-2 primary white--text">
        <p class="truncate-text ma-0">
          {{ item.depto }}
        </p>
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraDialog', 'Cancel')"
          >mdi-close</v-icon
        >
      </v-card-title>
      <v-card-text class="pt-5 pb-0 d-flex justify-center">
        <div v-show="loadingData" class="text-center">
          <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
            Cargando contenido...
          </h2>
          <v-progress-circular
            color="primary"
            indeterminate
            :size="70"
            :width="7"
          ></v-progress-circular>
        </div>
        <div v-show="!loadingData">
          <v-card class="px-3 cursor card-deptos" :color="color" :dark="dark">
            <p class="d-flex ma-0">
              <v-text-field
                v-model="color"
                label="Selecciona un color"
                readonly
                @click="dialogPicker = true"
              ></v-text-field>
              <v-icon>mdi-palette</v-icon>
            </p>
            <!-- Dialog con Color Picker -->
            <v-dialog v-model="dialogPicker" width="300">
              <v-card>
                <v-card-title class="headline"
                  >Selecciona un color</v-card-title
                >
                <v-card-text>
                  <v-color-picker
                    v-model="colorForm"
                    flat
                    hide-canvas
                  ></v-color-picker>
                </v-card-text>
                <v-card-actions>
                  <v-btn
                    text
                    color="primary"
                    @click="
                      dialogPicker = false;
                      color = '';
                    "
                    >Cancelar</v-btn
                  >
                  <v-btn
                    text
                    color="primary"
                    @click="
                      dialogPicker = false;
                      color = colorForm;
                    "
                    >Seleccionar</v-btn
                  >
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-card>
          <p class="ma-0">
            <v-switch v-model="dark" label="¿Es legible?"></v-switch>
          </p>
        </div>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn
          class="rounded-lg elevation-0"
          color="pink darken-1"
          :loading="loading"
          @click="color = ''"
          dark
          small
        >
          <v-icon left>mdi-broom</v-icon> Limpiar
        </v-btn>
        <v-btn
          v-if="color"
          class="rounded-lg elevation-0"
          color="teal darken-1"
          :loading="loading"
          @click="guardar"
          dark
          small
        >
          <v-icon left>mdi-check</v-icon> Guardar
        </v-btn>
        <v-btn class="rounded-lg elevation-0" v-else disabled>
          <v-icon left>mdi-check</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-admon-deptos",
  components: {
    SnackBarErrorIncidencias,
  },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    color: "",
    colorForm: "42A5F5",
    dialogPicker: false,
    loadingData: false,
    loading: false,
    dark: false,
  }),
  created() {},
  computed: {},
  mounted() {
    console.log(this.item);
    this.color = this.item.color;
    this.dark = this.item.dark == 1 ? true : false;
  },

  methods: {
    guardar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        color: this.color,
        dark: this.dark,
        departamento: this.item.id,
      };
      this.loading = false;
      console.log(params);
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-color-departamento`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(
                    this.$emit("cierraDialog", {
                      status: "OK",
                      color: this.color,
                      dark: this.dark,
                      departamento: this.item.id,
                    })
                  );
                }, 1500);
              });
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
  },
};
</script>
