<template>
  <v-container fluid>
    <v-card v-if="loading">
      <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
        Cargando contenido...
      </h2>
      <div class="mx-auto" align="center">
        <v-progress-circular
          color="primary"
          indeterminate
          :size="70"
          :width="7"
        ></v-progress-circular>
      </div>
    </v-card>
    <v-card v-else>
      <v-card-text>
        <v-autocomplete
          v-model="area"
          :items="areas"
          label="Selecciona un area"
          hide-selected
          outlined
          dense
          item-value="id"
          item-text="Area"
          color="cyan"
          clearable
          v-on:change="getDepartamentos(2)"
        >
        </v-autocomplete>
        <!-- <v-autocomplete
          v-model="depto"
          :items="departamentos"
          :loading="loadingInfo"
          label="Selecciona un departamento"
          hide-selected
          outlined
          dense
          item-value="id"
          item-text="depto"
          color="cyan"
          clearable
        >
        </v-autocomplete> -->
      </v-card-text>
      <div class="px-5 d-flex">
        <v-card
          class="mr-3 mb-3 px-3 cursor card-deptos-escalera"
          width="150"
          @click="handleConsultar('ALL')"
        >
          <p class="ma-0 d-flex justify-end">
            <v-icon small>mdi-palette</v-icon>
          </p>
          <v-divider></v-divider>
          <p class="d-flex align-center ma-0 text-uppercase">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <p class="truncate-text ma-0" v-bind="attrs" v-on="on">
                  Todos los departamentos
                </p>
              </template>
              <span>Todos los departamentos</span>
            </v-tooltip>
          </p>
        </v-card>
        <div class="scroll-container-escalera">
          <p v-for="(item, index) in departamentos" :key="index">
            <v-card
              class="mr-3 mb-3 px-3 cursor card-deptos-escalera"
              width="150"
              :color="item.color"
              :dark="item.dark == 1 ? true : false"
            >
              <p class="ma-0 d-flex justify-end">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="openDialogColor(item)"
                      v-bind="attrs"
                      v-on="on"
                      small
                      >mdi-palette</v-icon
                    >
                  </template>
                  <span>Agregar color</span>
                </v-tooltip>
              </p>
              <v-divider></v-divider>
              <p
                @click="handleConsultar(item)"
                height="100%"
                class="d-flex align-center all-screen"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <p class="truncate-text ma-0" v-bind="attrs" v-on="on">
                      [{{ item.id }}]
                      {{ item.depto }}
                    </p>
                  </template>
                  <span>{{ item.depto }} {{ item.color }}</span>
                </v-tooltip>
              </p>
            </v-card>
          </p>
        </div>
      </div>
      <v-card-actions>
        <v-btn
          v-if="area && depto"
          class="rounded-lg elevation-0"
          color="teal darken-1"
          :loading="loading"
          dark
          block
          small
        >
          <v-icon left>mdi-stairs</v-icon> Consultar
        </v-btn>
        <v-btn class="rounded-lg elevation-0" v-else disabled block>
          <v-icon left>mdi-stairs</v-icon> Consultar
        </v-btn>
      </v-card-actions>
    </v-card>
    <v-card
      class="mt-5"
      v-if="validar && area && depto"
      color="blue-grey lighten-4"
    >
      <familias-escaleras-crecimiento
        ref="familiasComponent"
        :item="area"
        :depto="depto"
        :modulo="'EscaleraCrecimiento'"
      ></familias-escaleras-crecimiento>
    </v-card>
    <form-admon-deptos
      v-if="dialogColor"
      :dialog="dialogColor"
      :item="item"
      @cierraDialog="close"
    ></form-admon-deptos>
  </v-container>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import FamiliasEscalerasCrecimiento from "./FamiliasEscalerasCrecimiento.vue";
import FormAdmonDeptos from "./FormAdmonDeptos.vue";
export default {
  name: "areas-escaleras-crecimiento",
  props: [],
  components: {
    FamiliasEscalerasCrecimiento,
    FormAdmonDeptos,
  },
  data() {
    return {
      textoBarra: "",
      colorBarra: "",
      muestraMensaje: false,

      tab: null,
      areas: [],
      loading: true,
      moduloError: false,
      area: "",
      depto: "",
      departamentos: [],
      estatus: "",
      loadingInfo: false,
      validar: false,
      dialogColor: false,
    };
  },
  created() {},
  mounted() {
    this.getAreas();
    this.getAreaDepartamento();
  },
  methods: {
    getAreaDepartamento() {
      const headers = { Authorization: localStorage.getItem("token") };

      return new Promise((res) => {
        axios
          .get(
            `${
              this.$store.getters.getHostNode
            }/api/get-area-departamento/${localStorage.getItem("folio")}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.area = response.data.data.DEPTID;
              this.depto = response.data.data.EMP_KEYDEP;
              this.getDepartamentos(1);
            } else if (
              response.data.status == "ERROR" &&
              !response.data.status
            ) {
              this.estatus = response.data.status;
              this.moduloError = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },
    getAreas() {
      const headers = { Authorization: localStorage.getItem("token") };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-areas`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.areas = response.data.data;
              this.estatus = response.data.status;
            } else if (
              response.data.status == "ERROR" &&
              !response.data.status
            ) {
              this.estatus = response.data.status;
              this.moduloError = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
            this.loading = false;
          });
      });
    },
    getDepartamentos(opcion) {
      if (opcion == 2) {
        this.depto = "";
      }
      this.loadingInfo = true;
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-departamentos-zentric/${this.area}`,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.departamentos = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingInfo = false;
          });
      });
    },
    handleConsultar(item) {
      this.depto = item;
      if (item != "ALL") {
        this.depto = item.id;
      }
      this.validar = true;
      // Verifica si el componente ya está montado
      this.$nextTick(() => {
        this.$refs.familiasComponent.getNiveles();
      });
    },
    openDialogColor(item) {
      this.dialogColor = true;
      this.item = item;
    },
    close(response) {
      if (response != "Cancel") {
        this.departamentos.forEach((element) => {
          if (element.id == response.departamento) {
            element.color = response.color;
            element.dark = response.dark;
          }
        });
      }
      this.dialogColor = false;
    },
  },
};
</script>
<style scoped>
.scroll-container-escalera {
  display: flex;
  overflow-x: auto; /* Habilita el scroll horizontal */
  white-space: nowrap; /* Evita que las tarjetas se vayan a la siguiente línea */
}

.scroll-container-escalera::-webkit-scrollbar {
  height: 8px;
}

.scroll-container-escalera::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Color del scrollbar */
  border-radius: 10px;
}

.cursor {
  cursor: pointer;
}

.card-deptos-escalera {
  min-height: 5.5em;
  font-size: 0.7em;
}

.truncate-text {
  white-space: nowrap; /* Evita el salto de línea */
  overflow: hidden; /* Oculta el contenido que se desborda */
  text-overflow: ellipsis; /* Añade "..." al final del texto recortado */
  cursor: pointer;
}

.all-screen {
  max-width: 100%;
}
</style>