<template>
    <v-container >   
        <v-card>
      <v-toolbar color="primary" dark flat>
        <v-tabs v-model="tabs" slider-color="info" grow>
          <v-tab :key="1">
            <v-icon left>mdi-folder-plus</v-icon> Creador de encuestas
          </v-tab>
          <v-tab :key="2">
            <v-icon left>mdi-security</v-icon> Administrador de encuestas
          </v-tab>
        </v-tabs>
      </v-toolbar>
  
      <v-tabs-items v-model="tabs">
        <v-tab-item>
          <creadorEncuestas />
        </v-tab-item>
        <v-tab-item>
          <administradorEncuestas />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
</v-container>

  </template>
  
  <script>
  import creadorEncuestas from './creadorEncuestas.vue';
  import administradorEncuestas from './administradorEncuestas.vue';
  
  export default {
    components: {
      creadorEncuestas,
      administradorEncuestas
    },
    data() {
      return {
        tabs: 0
      };
    }
  };
  </script>
  