<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    transition="dialog-bottom-transition"
    persistent
  >
    <v-card class="pa-5">
      <v-card-title class="text-subtitle-2 primary white--text">
        Cuadro base
        <v-spacer></v-spacer>
        <v-icon small dark @click="$emit('cierraForm', 'OK')">mdi-close</v-icon>
      </v-card-title>
      <v-card-text class="pt-5">
        <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col
              class="py-0 overline d-flex align-center"
              cols="12"
              xl="4"
              lg="4"
              md="4"
              sm="12"
              xs="12"
            >
              <v-autocomplete
                v-model="form.depto"
                :items="departamentos"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Selecciona un departamento --"
                hide-selected
                outlined
                dense
                small-chips
                item-value="DEPTID"
                item-text="descripcionMostrar"
                background-color="grey lighten-2"
                color="cyan"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col
              class="py-0 overline"
              cols="12"
              xl="8"
              lg="8"
              md="8"
              sm="12"
              xs="12"
            >
              <v-combobox
                v-model="form.puesto"
                :items="puestos"
                :loading="loadingFormPuestos"
                :rules="comboBoxPlan"
                label="-- Seleccione los puestos --"
                hide-selected
                outlined
                dense
                small-chips
                item-value="JOBCODE"
                item-text="DESCR"
                multiple
                background-color="grey lighten-2"
                color="cyan"
                clearable
              >
              </v-combobox>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="py-0" cols="12" xl="4" lg="4" md="4" sm="12" xs="12">
              <v-autocomplete
                v-model="form.turno"
                :items="turnos"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Selecciona turno --"
                hide-selected
                outlined
                dense
                item-value="Id"
                item-text="Descripcion"
                background-color="grey lighten-2"
                color="cyan"
                class="overline"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-autocomplete
                v-model="form.tipo"
                :items="tiposOperaciones"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Selecciona tipo --"
                hide-selected
                outlined
                dense
                item-value="id"
                item-text="name"
                background-color="grey lighten-2"
                color="cyan"
                class="overline"
              >
              </v-autocomplete>
            </v-col>
            <v-col class="py-0" cols="12" xl="3" lg="3" md="3" sm="12" xs="12">
              <v-autocomplete
                v-model="form.planta"
                :items="plantas"
                :loading="loadingForm"
                :rules="comboBoxPlan"
                label="-- Selecciona planta --"
                hide-selected
                outlined
                dense
                item-value="id"
                item-text="descripcion"
                background-color="grey lighten-2"
                color="cyan"
                class="overline"
              >
              </v-autocomplete>
            </v-col>

            <v-col
              class="py-0 d-flex align-center overline"
              cols="12"
              xl="2"
              lg="2"
              md="2"
              sm="12"
              xs="12"
            >
              <v-text-field
                v-model="form.plandept"
                type="number"
                min="1"
                label="Plan"
                :rules="reglaPlan"
                outlined
                dense
                small-chips
                background-color="grey lighten-2"
                color="cyan"
                clearable
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row
            class="mx-10 pa-5 scrollable-row light-gray-row justify-space-around"
          >
            <v-col
              cols="12"
              xl="12"
              lg="12"
              md="12"
              sm="12"
              xs="12"
              class="overline py-0 d-flex justify-center"
              >Lista de puestos:
              <label v-if="form.puesto">{{ form.puesto.length }}</label>
              <v-spacer></v-spacer>
              <v-divider vertical></v-divider>
              <v-spacer></v-spacer>
              <br />
              <label v-if="form.plandept && form.puesto"
                >Cuentas con {{ form.plandept - this.ocupados }} ocupantes para
                distribuir entre los puestos seleccionados</label
              >
              <label v-else-if="!form.plandept && form.puesto"
                >Ingresa el numero de plan para su cuadro base</label
              >
              <label v-else-if="form.plandept && !form.puesto"
                >Selecciona los puestos que vaya a contener su cuadro
                base</label
              >
              <label v-else-if="!form.plandept && !form.puesto"
                >Selecciona los puestos que vaya a contener su cuadro
                base</label
              >
            </v-col>
            <v-col
              v-for="(item, index) in form.puesto"
              :key="item.JOBCODE"
              class="py-0"
              cols="12"
              xl="3"
              lg="4"
              md="4"
              sm="6"
              xs="12"
            >
              <v-card elevation="2" class="rounded-lg mb-5">
                <v-card-title class="text-subtitle-2 primary white--text">
                  {{ item.DESCR }}
                  <v-spacer></v-spacer>
                  <v-tooltip top color="blue lighten-4">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="white"
                        @click="removerPuesto(index)"
                        v-bind="attrs"
                        v-on="on"
                        >mdi-delete-empty-outline</v-icon
                      >
                    </template>
                    <label class="text-uppercase font-weight-bold ehite--text"
                      >Remover</label
                    >
                  </v-tooltip>
                </v-card-title>
                <div class="pa-5">
                  <v-text-field
                    v-if="form.plandept"
                    v-model="item.numPuestos"
                    v-on:change="contador"
                    type="number"
                    min="1"
                    label="¿Cuantos necesita?"
                    :rules="reglaPlan"
                    hide-selected
                    outlined
                    dense
                    background-color="grey lighten-2"
                    color="cyan"
                    clearable
                  >
                  </v-text-field>
                  <div v-else class="overline">Ingrese plan para continuar</div>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
        <!-- {{ form.puesto }} -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <div>
          <v-btn
            v-if="
              form.depto &&
              form.puesto &&
              form.turno &&
              form.tipo &&
              form.planta &&
              form.plandept
            "
            class="rounded-lg elevation-0"
            color="teal darken-1"
            :loading="loading"
            @click="validar"
            dark
            small
          >
            <v-icon left>mdi-send-variant-outline</v-icon> Guardar
          </v-btn>
          <v-btn class="rounded-lg elevation-0" v-else disabled>
            <v-icon left>mdi-send-variant-outline</v-icon> Guardar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-dialog>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../store/Funciones/funciones";
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
export default {
  name: "form-cuadro-base",
  components: { SnackBarErrorIncidencias },
  props: ["dialog", "item"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    departamentos: [],
    plantas: [],
    loading: false,
    loadingForm: true,
    puestos: [],
    loadingFormPuestos: true,
    ocupados: 0,
    valid: true,
    form: {
      depto: "",
      puesto: "",
      turno: "",
      tipo: "",
      planta: "",
      plandept: "",
    },
    turnos: [],
    comboBoxPlan: [
      (value) => !!value || "Este campo es requerido", // Validación de campo requerido
    ],
    reglaPlan: [
      (value) => /^\d+$/.test(value) || "Ingrese solo números", // Validación de solo números
    ],
    tiposOperaciones: [
      { id: "o", Descripcion: "Operativo" },
      { id: "a", Descripcion: "Administrativo" },
    ],
  }),
  created() {},
  computed: {},
  mounted() {
    this.getDepartamentos();
    this.getTurnos();
    this.getPlantas();
    this.getPuestos();
    this.tipoAccion();
    this.getTipoColaborador();
  },

  methods: {
    getCuadroBasePuestosPorDepto() {
      let turno = this.item.turno;
      let depto = this.item.depto;
      let planta = this.item.planta;
      let tipo = this.item.tipo;
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-cuadro-base-puestos/${turno}/${depto}/${planta}/${tipo}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.form.puesto = response.data.data;
              if (this.form.puesto) {
                this.contador();
              }
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getDepartamentos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-departamentos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.departamentos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getTurnos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-turnos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.turnos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getPlantas() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-plantas`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.plantas = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },

    getPuestos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-puestos`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.puestos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingFormPuestos = false;
          });
      });
    },

    contador() {
      let ocupados = 0;
      this.form.puesto.map((Element) => {
        if (Element.numPuestos) {
          ocupados += parseFloat(Element.numPuestos);
        }
      });
      this.ocupados = ocupados;
      if (parseFloat(this.ocupados) > parseFloat(this.form.plandept)) {
        this.textoBarra = "Evita sobrepasar el plan asignado ";
        this.colorBarra = "error";
        this.muestraBarra = true;
      } else {
        this.textoBarra =
          this.ocupados + " de " + this.form.plandept + " seleccionados.";
        this.colorBarra = "primary";
        this.muestraBarra = true;
      }
    },

    validar() {
      let ocupados = 0;
      let camposCompletados = 0;
      this.form.puesto.map((Element) => {
        if (Element.numPuestos) {
          camposCompletados++;
          ocupados += parseFloat(Element.numPuestos);
        }
      });
      if (camposCompletados == this.form.puesto.length) {
        if (parseFloat(ocupados) > parseFloat(this.form.plandept)) {
          this.textoBarra = "Evita sobrepasar el plan asignado ";
          this.colorBarra = "error";
          this.muestraBarra = true;
        } else if (parseFloat(ocupados) < parseFloat(this.form.plandept)) {
          this.textoBarra = "Completa el plan indicado";
          this.colorBarra = "error";
          this.muestraBarra = true;
        } else {
          if (this.item == "Nuevo") {
            this.guardar();
          } else {
            this.editar();
          }
        }
      } else {
        this.textoBarra =
          "No se ha completado la asignación de puestos solicitados ";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardar() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };
      const params = {
        correo: localStorage.getItem("correo"),
        form: this.form,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-cuadro-base`,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status == true) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.foto = null;
                  this.vistapreviaimg = "";
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == false) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              // deleteToken();
              console.log("deleteToken");
            }
          });
      });
    },

    editar() {
      const headers = {
        authorization: localStorage.getItem("token"),
      };
      const params = {
        correo: localStorage.getItem("correo"),
        form: this.form,
        puestosPorValidar:this.item.puesto,
        action: "editar",
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-cuadro-base`,
            params,
            {
              headers: headers,
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.data.status == true) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.clean();
              return new Promise((resolve) => {
                setTimeout(() => {
                  this.foto = null;
                  this.vistapreviaimg = "";
                  resolve(this.$emit("cierraForm", "OK"));
                }, 1500);
              });
            } else if (response.data.status == false) {
              res(response.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "error";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              // deleteToken();
              console.log("deleteToken");
            }
          });
      });
    },

    clean() {
      this.form.depto = "";
      this.form.puesto = "";
      this.form.turno = "";
      this.form.tipo = "";
      this.form.planta = "";
      this.form.plandept = "";
    },

    tipoAccion() {
      if (this.item != "Nuevo") {
        this.getCuadroBasePuestosPorDepto();
        // this.form = Object.assign({}, this.item);
        this.form.depto = this.item.depto;
        this.form.turno = this.item.turno;
        this.form.tipo = this.item.tipo;
        this.form.planta = this.item.planta;
        this.form.plandept = this.item.plandept;
        // if (this.form.puesto) {
        //   this.contador();
        // }
      }
    },

    removerPuesto(index) {
      this.form.puesto.splice(index, 1);
      this.contador();
      this.textoBarra = "Puesto removido ";
      this.colorBarra = "primary";
      this.muestraBarra = true;
    },

    getTipoColaborador() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-grupos-zentric`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.tiposOperaciones = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
  },
};
</script>
<style scoped>
.scrollable-row {
  max-height: 280px; /* Altura máxima de la fila */
  overflow-y: auto; /* Agrega scroll vertical si el contenido excede la altura máxima */
}
.scrollable-row::-webkit-scrollbar {
  width: 10px; /* Ancho de la barra de desplazamiento */
  background-color: red; /* Color de fondo de la barra de desplazamiento */
}

.scrollable-row::-webkit-scrollbar-thumb {
  background-color: pink; /* Color del "pulgar" de la barra de desplazamiento */
}
.scrollable-row {
  scrollbar-color: pink rgb(9, 163, 140); /* Color del "pulgar" y de fondo de la barra de desplazamiento en Firefox */
}
.light-gray-row {
  background-color: #f0f0f0; /* Gris claro casi blanco */
}
</style>